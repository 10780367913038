section[name=ArchiveVideo] {
    .cuepoints{
        position: relative;
        height: 3px;
        width: 81%;
        margin-left: 7%;
        margin-right: 15%;
        z-index: 1;
        bottom: -18px;
        .cue-item{
            height: 100%;
            bottom: 55px;
            background-color: rgba(91, 91, 91, 0);
            z-index: 2;
            display: inline-block;
            position: absolute;
            background-repeat: no-repeat; 
            .icon-cue {
                cursor: pointer;
               svg {
                   background: RGB(255, 227, 174);
                   font-size: 33px;
                   padding:5px;
                   border-radius: 50%;
               }
            }
        }
    }
}
