.global-property {
    .global-continuer {
        padding: 14px;
        .v-small-uploader{
            .drag-drop,.drag-drop-summery{
                display: none !important;
            }
        }
        .custom-switch{
            .Mui-checked{color: black !important; /* Checked state */}
              .MuiSwitch-track{background-color: black !important;}
        }
        .topic-styles{
            display: flex;
            justify-content:end ;
            flex-direction: column;
        }
        .row-radio-group{
            flex-wrap: nowrap !important;
        }
        .tradetutor{
            display: flex;
            // justify-content: space-between;
            padding: 8px 0 10px 0 !important;
            row-gap: 10px;
            column-gap:8px;
            .ck-checkbox-root {
                margin-right: 2px !important;
            }
        }
        .crs-structure{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            max-height: 100px;
            padding-top: 10px;
            .label{
                margin-bottom: 0 !important;
            }
        }
        hr{ margin: 16px 0; }
        .font-family{
            display: flex;
            align-items: end;
            .MuiFormControl-root{
                width: 100%;
            }
        }
        .label {
            padding: 0;
            margin-bottom: 4px !important;
        }
        .font-size-label{
            // padding: 6px 10px 10px 10px;
            margin-bottom: 4px;
            display: flex;
            flex-wrap: nowrap !important;
            align-items: center;
            .font-info{
                padding: 0px;
                // margin-left: -13px;
                &:hover{
                    background-color: transparent !important;
                }
            }
        }
        .attestation{
            &.d-flex{
                padding: 12px;
            }
            .ck-checkbox-root{
                margin: 0;
            }
        }
        .padding {
            padding: 16px;
        }
        // .padding {
            padding: 16px;
            .Mui-checked{
                color: var(--primary-color-1) !important;
            }
            .font-control{
                // .MuiSelect-outlined
                .MuiInputLabel-root{
                    background: transparent !important;
                    font-weight: bold;
                }
            }
            @media (max-width: 1600px) {
                padding: 4px;
                font-size: 12px;
            }
        // }
        .topic-font-global{
            .MuiInputLabel-root{
                background-color: transparent !important;
                color: #000;
                font-weight: bold;
            } 
        }
        .ins-box {
            padding: 16px 0;
            .MuiFormControlLabel-label {
                padding-right: 16px;
            }
        }
        .MuiInputLabel-filled{
            white-space: nowrap;
        }
        .crs{
            padding-left: 0;
        }
        .global-font-box{
            .MuiFilledInput-root{
                width: 100%;
            }
        }
        .global-font,.tradetutor{
            padding-bottom: 2px;
            align-items: center;
            .label{
                margin-bottom: 0px !important;
            }
            .MuiButtonBase-root{
                // padding-right: 5px;
                svg{
                    font-size: 20px;
                    path{color: #000;}
                }
            }
            .MuiFormControlLabel-label{
                font-weight:bold;
                font-size: 14px;
            }
        }
    }
}
.select-option-list {
    display: block !important;
    padding: 8px !important;
}
.MuiTooltip-popper{
    .MuiTooltip-tooltipPlacementTop{
        font-size: 0.8rem;
    }
}
.pre-test-modal{
    .MuiDialog-container .MuiDialog-paper{
        width: 80%;
        height: 80%;
        min-width: 80%;
        background-color: #f5f5f5;
    }
    .pre-header{
        display: flex;
        align-items: end;
        justify-content: end
     }
    .pre-test-container{
        .quiz-inner-continuer{
            font-size: 24px;
        }
        .Mui-checked{
            color: #13984b !important;
        }
        .pre-footer{
            position: absolute;
            bottom: 16px;
            right: 16px;
            column-gap:16px;
        }
     }
}