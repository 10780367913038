@import '../../../theme/mixins.scss';
.videxplo-container {
    padding: 16px;
    position: relative;
    .popup-hide{
        .vjs-tech{
            pointer-events: none !important;
        }
    }
    .custom-progress-div img,.vjs-big-play-button {
        // display: none;
    }
    .audio-container,.rhap_container {
        visibility: hidden;
        max-height: 0px;
    }

    .overlay-title{
        color: var(--color-blue-1);
        font-size: 25px;
        font-weight: bold;
        display: flex;
    }

    .stepper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 16px 0;
        .end-step{
            .step-circle{
                background-color: #555;
                visibility: visible !important;
            }
        }
        .step {
            position: relative;
            text-align: center;

            &:not(:last-child) {
                width: 100%;
            }
            .step-circle {
                width: 40px;
                height: 40px;
                border: 2px solid var(--bs-gray-500); // Change color as needed
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                font-weight: bold;
                color: var(--bs-gray-500); // Change color as needed
                z-index: 1;
                transition: background-color 0.4s ease, color 0.4s ease, transform 0.4s ease;
                visibility: hidden;

                &.active {
                    background-color: #007bff; // Change color as needed
                    color: #fff;
                    visibility: hidden;
                    transform: scale(1.1);
                }
            }


            &.visible-action{
                .step-circle {
                    visibility: visible;
                    background-color: var(--primary-color-1);
                    color: #fff;
                    cursor: pointer;
                }
            }
            &.active-action{
                .step-circle {
                    background-color: var(--primary-color-2) !important;
                    visibility: visible;
                   
                }
            }
            &.active-bar {
                &:after{
                    background-color: var(--primary-color-1) !important; 
                }
            }
            .step-label {
                margin-top: 8px;
                font-size: 14px;
                color: #555; // Change color as needed
            }
            &:not(:nth-last-child(-n+2))::after {
                background:linear-gradient(90deg, var(--bs-gray-500) 0%, var(--primary-color-1) 100%); // Bar between steps in active state
            }
            &:not(:last-child):after {
                content: '';
                position: absolute;
                top: 20px; 
                right: -38px; 
                width: 100%;
                height: 6px;
                border-radius: 2px;
                background-color: var(--bs-gray-500); // Change color as needed
                background-size: 200% 100%;
                background-position: 100% 0; 
                z-index: 0;
                transition: background-color 0.4s ease, color 0.4s ease;
            }
        }
    }

    .popup-hide{
        .vjs-control-bar{
            display: none;
        }
    }
    .video-area{
        position: relative;
        .instruction-text{
            display: none;
        }
    }

    .overlay-render-vid-hot{
        z-index: 999;
        position: absolute;
        bottom: 2%;
        left:18px;
        height: 100%;
        overflow: hidden;
        // transform: translate(-50%, -50%);
        overflow: hidden !important;
        display: flex ;
        width: 97%;
        align-items: end;
        min-width: 90%;
        min-height: 27%;
        max-width: 90vw;
        max-height: 60vh;
        border-radius: 10px;
        overflow: auto;
        opacity: 0.9;
        animation: slideUp 0.8s ease-out forwards;
        @include for-ipad-pro-devices{
            transform: translate(0, 0) !important;
        }
        // .overlay-render-vid-hot {
        .result-summary{
            padding: 0 20px !important;
            column-gap: 5px;
            display: flex;
            flex-wrap: wrap;
            gap: 18px;
            padding-bottom: 25px !important;
            .hotspot-container{
                display: flex;
                flex-direction: column;
                width: 350px;
                height: 250px;
                overflow: hidden;
                cursor: pointer;
                box-shadow: var(--box-shadow-2);
                padding: 20px; 
                background-color: var(--bs-gray-100);
                transform: translateY(3px);
                transition: all 0.8s ease-out;
                row-gap: 3px;
                .img-container{
                    height: 100%;
                    width: 100%;
                    position: relative;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                    .selected-item{
                        position: absolute;
                        top: -18px;
                        right: -18px;
                        background-color: var(--bs-gray-100) !important;
                        border-radius: 15px;
                        svg{  font-size: 30px;}
                    }
                }
                &:hover{
                    background-color: var(--border-color);
                    transform: translateY(0);
                    
                }
            }
        }
            .close-btn {
                position: absolute;
                right: 0;
                top: 0;
                margin: 5px;
                svg{font-size: 30px;}
            }
            .add-on-overlay-vid-hot{
                width: 100%;
                max-height:99%;
                overflow-y: auto;
                box-shadow: var(--box-shadow-5);
                border-radius: 10px;
                .icon-cnt svg{
                    font-size: 30px;
                }
                .head-item{
                    position: sticky;
                    top: 0;
                    z-index: 2;
                    background: #fff;
                }
            }
            .info-cnt{
                padding: 20px;
                .common-mui-btn{
                    align-items: baseline;
                    padding: 16px;
                }
             svg{ font-size: 26px;}
             p{font-size: 24px;}
            }
            .content-item {
                min-height: 10vh;
            }
            .btn-container{
                display: flex;
                column-gap: 16px;
                justify-content: end;
                padding: 16px;
                position: sticky;
                bottom: 0;
                background: #fff;
            }
        // }
    }

}

:root {
    --slide-transform-end: translate(0, -28%);
  }
  
  @media (min-width: 2560px) {
    :root {
      --slide-transform-end: translate(0, 0);
    }
  }
    @keyframes slideUp {
        0% {
            transform: translate(0, 90%); // Start 20% below the bottom
            opacity: 0;
        }
        100% {
            transform:  var(--slide-transform-end); // End at the actual position
            opacity: 1;
        }
    }


/* Define pulsing and rotating keyframes */
@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes pulse-stop {
    0% {
        transform: scale(1.1); /* Maintain the current state smoothly */
    }
    100% {
        transform: scale(1); /* Return to default */
    }
}

/* Create classes to apply animations */
.icon-pulse {
    animation: pulse 1.5s infinite ease-in-out;
    margin-bottom: 6px;
   svg{
    color: var(--color-blue-1) !important;
    font-size: 25px;
   }
   &.has_payed {
    animation: pulse-stop 1.5s ease-in-out; 
    }
}
._tablet,._labtop,._mobile {    :root {
    --slide-transform-end: translate(0, 0);
  } }