.quiz-main-container {
    padding: 25px 35px;
    background: #ffff;
    border-radius: 5px;
    min-height:250px;
    .answer-select-div {
        margin: 15px 0
    }
    .quiz-heading {
        text-align: center;
        color:var(--primary-color-1);
        font-weight: bold;
    }
    .head-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left:17px;
        button.quiz-next-btn {
            margin-right :10px;
            background:var(--primary-color-1);
            color: #ffffff;
            &.Mui-disabled {
                background: #c8c8c8;
            }
        }
    }
}

.attempt-message {
    text-align: center;
}