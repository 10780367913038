.custom-select-container {
    position: relative;
    width: 230px;
    color: #000;

 /* Change checkbox color when checked */
  input[type="checkbox"]:checked {
    accent-color: #000;
  }

    .custom-select-label {
      margin-bottom: 8px;
      font-weight: 600;
    }
    
    .custom-select-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 14px;
      cursor: pointer;
      background-color: transparent;
    }
    
    .custom-select-selected {
      flex: 1;
    }
    
    .custom-select-arrow {
      margin-left: 8px;
    }
    
    .custom-select-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: white;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      max-height: 200px;
      overflow-y: auto;
    }
    
    .custom-select-option {
      padding: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    
    .custom-select-option.selected {
      background-color: #e0e0e0;
    }
    
    .custom-select-option:hover {
      background-color: #f0f0f0;
    }
    
    .custom-select-option input {
      margin-right: 8px;
    }
    
  }
  
 