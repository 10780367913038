@import "../../../theme/mixins.scss";
.author-info-container {
    padding:0 16px;
    .author-id {
        font-weight: 600;
        span {
            font-weight: normal;
        }
    }
   

    .author-list {
        p {
            margin-bottom: 5px;
        }
        .topic-title {
            color: var(--color-gray-3);
            font-family:var(--font-roboto-regular);
            font-size: 12px;
        }
    
        .author-name, .created-date {
            color: var(--color-gray-1);
            font-family:var(--font-roboto-regular);
        }
    }

    .text-input {
        margin-top: 20px;
        .comment-text-area {
            height: 100px;
            width: 100%;
            resize: none;
            outline: none;
            border-radius: 3px;
            box-shadow: var(--box-shadow-2);
            border: 0
        }
    }

    .action-info {
        text-align: right;
        button {
            margin: 5px 10px;
        }
        .submit-btn {
            color: #ffffff;
            background: #000000;
        }
    }

    .author-comment-section {
        margin-top: 25px;
        padding: 10px 0;
        position: relative;
        border-bottom: 1px solid #ddd;
        &:last-of-type{
            border-bottom:none !important
        }
        .icons-holder{
            list-style-type: none;
            position: absolute;
            padding-left: 0;
            top: 17px;
            // right: 8px;
            @include switch-direction-rtl(right,8px);
            background: #fff;
            width: 60px;
            display: flex;
            justify-content: space-between;
            box-shadow: var(--box-shadow-4);
            padding: 9px 2px;
            border-radius: 5px;
            li{
                cursor: pointer;
            }
        }
        .profile-badge {
            width: 11%;
            .profile-latter{
                display: flex;
                width: 30px;
                height: 30px;
                background: var(--bs-gray-400);
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                font-weight: bold;
            }
        }
        .profile-name {
            
                font-family: var(--global-font-family-bold);
                margin-bottom: 0;
                font-size: 13px;
             
        }
        .modified-date{font-size:  12px;}
        .profile-type {
            p {
                color: var(--color-gray-3)
            }
        }
        .profile-settings-icon {
            cursor: pointer;
        }
    }

    .comment-text {
        // margin-top: 10px;
         margin-left:50px;
        .tag-text {
            color: rgb(8, 8, 248)
        }
        .reply-btn {
            font-weight: 600;
            cursor: pointer;
            margin-left: 10px;
        }
    }
    .comment-edit{
        display: flex;
        align-items: center;
        position: relative;
        .status-icon{
            white-space: nowrap;
            position: absolute;
            top: 0;
            right: 24px;
        }
        .save-icon{
            margin-left: 10px;
            cursor: pointer;
        }
        .edit-icon{
            margin-left: 10px;
            cursor: pointer;
        }
        .delete-icon{
            cursor: pointer;
        }
        .MuiTypography-body1{
            width: 80% !important;
            font-family:var(--font-roboto-regular);
        }
        .MuiFormControl-root{
            width: 80% !important;
            font-family:var(--font-roboto-regular);
        }
        .MuiOutlinedInput-multiline{padding: 0px!important;}
        .MuiOutlinedInput-input {
            padding: 12.5px 14px;
            background: white;
            margin-top: -3px;
            font-family:var(--font-roboto-regular);
        }
        label.MuiFormLabel-root {
            background: #f5f5f5;
            margin-top: -3px;
        }
    }
}

