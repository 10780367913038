@import "../../../theme/mixins.scss";
.highlighter {
    border:   2px solid  var(--primary-color-1, #78d370);
    border-radius: 11px;
    padding: 10px 1px;
    pointer-events: none;
    user-select: none;
    border-style: dashed;
  
    transition: border 300ms;
  
    &.active {
      border-width: 8px;
      overflow: auto;
    }
   .lrn-list{
    position: absolute;
    // right:-19px;
    @include switch-direction-rtl(right,-19px);
    bottom: -50px;
    z-index: 99999999;
   }   
  .label {
    padding: 8px;
    background: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: absolute;
    left: -2px;
    top: -2px;
    pointer-events: all;
    width: 100.3%;
    border: 2px solid var(--primary-color-1);
    border-bottom-style: dashed;
    z-index: 99999999;
   }
   .heilighted-bottom{
     position: absolute;
     right:-19px;
     bottom: -50px;
     z-index: 99999999;
   }
  }


  .overlayBorder {
    border:   1px solid  var(--color-green-5, #78d370);
    border-radius: 11px;
    padding: 10px 1px;
    pointer-events: none;
    user-select: none;
  }

  