@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
  }
  @mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
  }

  @mixin for-mobile-landscape-up {
    @media (min-width: 768px) {
       @content;
    }
  }

  @mixin for-mobile-medium-large {
    @media (max-width: 475px) {
       @content;
    }
  }

  @mixin for-desktop-medium {
    @media (max-width: 1600px) {
      @content;
   }
  }

  @mixin for-desktop-up {
    @media (max-width: 1460px) {
      @content;
   }
  }

  @mixin for-mobile-large {
    @media (max-width: 600px) {
       @content;
    }
  }

  @mixin for-mobile-small-devices {
    @media (max-width: 425px) {
       @content;
    }
  }
  @mixin for-laptop-up {
    @media (max-width: 991px) {
      @content;
    }
  }

  @mixin for-ipad-pro-devices {
    @media (max-width: 1025px) {
       @content;
    }
  }

  @mixin for-tablet-up {
    @media (max-width: 768px) {
      @content;
    }
  }

  @mixin for-laptop-medium-up {
    @media (max-width: 1200px) {
      @content;
    }
  }

// mixin to switch direction based on the root variable --direction
// use : @include switch-direction-rtl(property-value, value-ltr);

@mixin switch-direction-rtl($property-value, $value-ltr) {
  // find the property based on the --direction root variable
  // if --direction is ltr, use the original property-value, else switch left and right
  $property: if(var(--direction) == rtl, if($property-value == left, right, left), $property-value);

  // set the value to the provided $value-ltr
  $value: $value-ltr;

  // apply the property and value to the scSS
  #{$property}: $value;
}

  
@mixin fadeIn($duration: 1s, $delay: 0s) {
  @keyframes fadeInAnimation {
      0% {
        opacity: 0;
        transform: translateY(8px);
    }
    25% {
        opacity: 0.1;
        transform: translateY(5px);
    }
    50% {
        opacity: 0.3;
        transform: translateY(2px);
    }
    75% {
        opacity: 0.5;
        transform: translateY(0.5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }
  animation: fadeInAnimation $duration ease-in-out $delay;
  opacity: 1 !important;
}

@mixin shake($duration: 0.5s, $distance: 5px) {
  @keyframes shakeAnimation {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-$distance);
    }
    50% {
      transform: translateX($distance);
    }
    75% {
      transform: translateX(-$distance / 2);
    }
    100% {
      transform: translateX(0);
    }
  }

  animation: shakeAnimation $duration ease-in-out;
}


  



// .header-title {  
//     font-size: 2rem;  
  
//     @include for-phone-only {    
//        font-size: 1rem; 
//     }
//  }

// // When we compile our project, our @include will translate into:
// @media (max-width: 599px) {
//    font-size: 1rem;
// }