:root {
    // --catch-bg-image: url('../../../assets/img/seaView.jpg');
}

.game-container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    .game-screen{
        color: #000;
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
        text-align: center;
        line-height: var(--global-line-height);
        margin: 20px;
    }
    // .option,.object {
    //     max-height: 8px;
    // }
    .game-area {
        position: relative;
        width: 1000px;
        height: 600px;
        border: 5px solid  transparent;
        overflow: hidden;
        background-color: var(--border-color);

        .question{
            z-index: 2;
            bottom: -1%;
            color: #fff !important;
            text-align: center;
            position: absolute;
            width: 100%;
        }

        .road {
            position: absolute;
            width: 100%;
            height: 100%;
            background:var(--catch-bg-image);
            background-size: cover;
        }
        @keyframes wobble {
            0%, 100% {
              transform: rotate(0deg);
            }
            50% {
              transform: rotate(3deg);
            }
          }

        .goat {
            position: absolute;
            animation: wobble 2s infinite ease-in-out; // Wobb
            transition: top 0.2s ease, left 0.2s ease; 
            width: 50px;
            height: 80px;
            bottom: 20px;
            left: 125px;
            display: flex;

            img {
                transform: scale(2);
            }
        }

        .object {
            position: absolute;
            min-width: 70px;
            padding:8px;
            height: auto;
            overflow-x: auto;
            top: -50px;
            max-width: 200px;
            border-radius: 10px !important;
            p{
                margin: 0 !important;
            }
        }

        .leaf,
        .bike {
            display: flex;
            border-radius: 10%;
            background-color: var(--bs-red);
            justify-content: center;
            align-items: center;
            min-height: 50px;
            text-align: center;
            color: #fff;
        }

        .score {
            font-size: 20px;
            margin-top: 20px;
            color: white;
            background-color: #333;
            padding: 5px 10px;
        }
    }
}