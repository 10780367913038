@import "../../../theme/mixins.scss";
.instruction-text {
    display: flex;
    position: relative;
    justify-content: end;
    &.visible{
        .instruct-panel{
            visibility: visible !important;
            padding-right:35px;
            color: red;
        }
    }
    @mixin topicStatus {
        position: absolute;
        left: 98%;
        margin: 5px 0;
        bottom: 13px;
    }
    .topic-complete {
        @include topicStatus;
        color: var(--primary-color-1);
    }
    .status-completed {
        @include topicStatus;
        background: var(--primary-color-1);
        color: #ffffff;
        border-radius: 50%;
        padding: 2px;
        font-size: 18px;
    }
}