.save-template{
    min-height: 400px;
    padding: 0 8px;

    .template-list{
        .template-title{
          font-weight: bold;
        }
        .comp-list-icon{
            width: 22px;
            height: 22px;
            margin-right: 10px;
            border-radius: 50%;
            overflow: hidden;
            display: inline-flex;
            border: 1px solid var(--color-gray-3);
            align-items: center;
            justify-content: center;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .lrn{
            padding: 12px 0 12px 16px;
            background-color: var(--bs-body-bg);
            font-weight: 500;
            color: #000;
            transition: background-color 0.3s ease, font-weight 0.3s ease;
            &:hover{
                background-color: var(--border-color);
                font-weight: bold;
            }
            .pre-text{ color: #000;}
            cursor: pointer;
            svg{font-size: 20px;}
            &.lrn-hide{
                background-color: var(--bs-gray-300) !important;
                opacity: 0.7;
            }
            &.lrn-show{
                .lrn-icon svg{
                  color: var(--bs-success);
                }
            }
        }
        max-height: 700px;
        overflow-y: auto;
    }
    .tp-action-info{
        margin-top: 10px;
        text-align: right;
        .cancel-btn{
            margin-right: 10px;
        }
        .disabled-btn{
            background-color:grey;
            color:#000;
        }
        .submit-btn{
            background-color:var(--primary-color-1);
            color:#fff;
        }
    }
}