@import "../../../theme/mixins.scss";
.print-certificate-container {
    font-family:'Roboto', sans-serif;
    padding: 10px 0;
    @include for-laptop-up{ 
      overflow-y: auto;
    }
    .print-certificate-btn{
        position: relative;
    }
    .text-right {
        text-align: right;
        margin-bottom: 15px;
        .print-btn {
            color: #ffffff;
            background: var(--primary-color-1);
        }
    }
    .print-vertificate-main {
        .background-img-container {
            position: relative;
           text-align: center;
           .bg-image {
               width: 600px;
               max-width: 600px;
               min-width: 500px;
               border-top: 1px solid var(--bs-gray-500);
           }
        }
        .print-content {
            position: absolute;
            top: 45px;
            margin: 0 35%;
            min-height: 70%;
            max-height: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include for-laptop-up{ 
                margin: 0 23% !important;
            }
            @include for-ipad-pro-devices{
                margin: 0 23% !important;
            }
            @include for-desktop-up {
                margin: 0 30% !important;
            }
            @media (min-width: 2000px){
                margin: 0 39% !important;
            }
            .print-certificate-logo {
                // padding: 20px  0;
                padding-top: 20px;
                padding-bottom: 40px;
                img {
                    // width: 100%;
                    // height: 100%;
                    max-width: 300px;
                    // min-width: 290px
                }
            }
            .print-certificate-heading {
                padding: 20px  0;
                // margin-top: 98px;
                font-size: 26px;
                font-weight: 500;
                // color: #ffffff;
                text-transform: uppercase;
                @include for-laptop-up{
                    font-size: 18px !important;
                }
            }
            .print-certificate-course-person {
                // margin-top: 70px;
                padding: 20px  0;
                .certifies-that{
                     color: #000;
                    // font-style: italic;
                    font-size: 14px;
                    margin:0;
                }
                .user-name {
                    color: #363435;
                    font-size: 28px;
                    font-weight: 500;
                    // margin-top: -5px;
                    @include for-laptop-up{
                        font-size: 18px !important;
                    }
                }
            }
            .print-certificate-course-name {
                // margin-top: 80px;
                .heading-course-name-text {
                     color: #000;
                    // font-style: italic;
                    font-size: 14px;
                    margin: 0;
                    margin-bottom:3px !important;
                }
                .course-name {
                    color: #363435;
                    font-size: 22px;
                    font-weight: 600;
                    @include for-laptop-up{
                        font-size: 18px !important;
                    }
                }
            }
            .print-certificate-course-info {
                display: none !important;
                position: absolute;
                // left: -30px;
                @include switch-direction-rtl(left,-30px);
                height: 70vh;
                display: flex;
                align-items: end;
                top: 1%;
                justify-content: space-between;
                width: 100%;
                font-weight: 500;
                @include for-laptop-up{
                    // right: 0px !important;
                    @include switch-direction-rtl(right,0 !important);
                }
                .course-time-spent {
                     color: #000;
                    // font-style: italic;
                    font-size: 12px;
                    max-width: 185px;
                    span {
                        font-style: normal;
                        color: #000 !important;
                    }
                }
                .course-completion-date {
                    position: relative;
                    // left: 67px;
                    @include switch-direction-rtl(left,67px);
                     color: #000;
                    // font-style: italic;
                    font-size: 12px;
                    @include for-laptop-up{
                        // left: 20px !important;
                        @include switch-direction-rtl(left,20px !important);
                    }
                    span {
                        font-style: normal;
                        color: #fff !important;
                    }
                }
            }
        }
    }

    .print-certificate-course-info_outer{
        display: flex;
        justify-content: space-evenly;
        padding: 0 30%;
        margin-top: -55px !important;
        width: 100%;
        @include for-desktop-up {
            padding: 0 15% !important;
        }
        @include for-ipad-pro-devices {
            padding: 0 3% !important;
        }
  
        @media (min-width: 2000px){
            padding: 0 30% !important;
        }
        @include for-mobile-large{ 
            width: 100vw !important;
            padding: 0px !important;
            margin-left: 50px;
            span{
                min-width:200px;
            }
        }
        .course-time-spent{
            max-width: 185px;
        }
        .course-completion-date{
            max-width: 180px;
        }
    }

    .social-share-container .inner-container{
        width: 600px;
    }
    .footer-cnt{
        column-gap: 10px;
        .cnt-t-d{
            white-space: nowrap;
            color: #000 !important;
        }
    }

}

@media only  print {
    .print-vertificate-main {
        margin-top: 20px;
        .background-img-container {
            position: relative;
            text-align: center;
            overflow: hidden;
           .bg-image {
               width: 94%;
               border-top: 1px solid var(--bs-gray-500);
           }
        }
        .print-content {
            position: absolute ;
            top: 30px;
            margin: 0 20%;
            min-height: 70%;
            max-height: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-family: 'Roboto', sans-serif;
            .print-certificate-logo {
                // margin-top: 8px;
                padding: 20px  0;
                img {
                    max-width: 350px;
                }
            }
            .print-certificate-heading {
                // margin-top: 70px;
                padding: 20px  0;
                font-size: 24px;
                // color: #ffffff;
                text-transform: uppercase;
                font-weight: 400;
            }
            .cnt-t-d{
                white-space: nowrap;
                color: #000 !important;
            }
            .print-certificate-course-person {
                // margin-top: 80px;
                padding: 20px  0;
                .certifies-that{
                     color: #000;
                    // font-style: italic;
                    font-size: 16px;
                    margin: 0;
                    font-weight: 400;
                }
                .user-name {
                    color: #363435;
                    margin-top: -7px;
                    font-size: 32px;
                    font-weight: 400;
                }
            }
            .print-certificate-course-name {
                // margin-top: 65px;
                .heading-course-name-text {
                     color: #000;
                    // font-style: italic;
                    font-size: 16px;
                    // font-weight: 500;
                    margin:0;
                    margin-bottom:3px !important;
                }
                .course-name {
                    color: #000000;
                    font-size: 22px;
                    font-weight: 400;
                }
            }
            .print-certificate-course-info{
                position: absolute;
                // left: -65px;
                @include switch-direction-rtl(left,-65px);
                height: 85vh;
                display: flex;
                align-items: end;
                justify-content: space-between;
                width: 100%;
               .course-time-spent {
                     color: #000;
                     max-width: 185px;
                    // font-style: italic;
                    span {
                        font-style: normal;
                        color: #000;
                    }
                }
                .course-completion-date {
                     color: #000;
                    position: relative;
                    // left: 130px;
                    @include switch-direction-rtl(left,130px);
                    span {
                        font-style: normal;
                        white-space:nowrap;
                        color: #000;
                    }
                }
            }
        }
        // .print-certificate-course-info {
        //     position: absolute;
        //     top: 10px;
        //     // bottom: 150px;
        //     // right: 125px;
        //     .course-time-spent {
        //          color: #000;
        //         // font-style: italic;
        //         span {
        //             font-style: normal;
        //             color: #888888
        //         }
        //     }
        //     .course-completion-date {
        //          color: #000;
        //         position: relative;
        //         left: 235px;
        //         // font-style: italic;
        //         span {
        //             font-style: normal;
        //             // color: #888888
        //             color: #000
        //         }
        //     }
        // }
        .print-certificate-course-info_outer{
            display: none;
        }
    }
}