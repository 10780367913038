.template-panel {
  margin: 10px;

  .apply-temp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
    .tp-btn-holders{
      padding-right: 16px;
      .assign-btn{
        &:hover{
          background-color: var(--color-green-4) !important;
          color: #fff;
        }
      }
    }
  }

  .template-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 100%;
    margin:15px 0;

    // gap: 5px;
    .preview {
      padding-top: 10px;

      .preview-lrn {
        margin: 30px;
      }

      .instruction-text,
      .forward-backward-btns,
      .add-video-sub {
        display: none;
      }

      .lrn-name {
        font-weight: bold;
        color: var(--color-green-4);
        padding-left: 16px;
      }
    }

    .courselist {
      list-style: none !important;
      height: 75vh;
      overflow-y: auto;
      background: #fff;
      padding-left: 0px;

      .template {
        padding: 12px;
        padding-left: 16px;
        box-shadow: 1px 1px #ddd;
        cursor: pointer;

        &.selected {
          background-color: var(--color-green-4) !important;
          color: #fff;
          font-weight: bold;
        }

        &:hover {
          font-weight: bold;
          background: var(--bs-gray-100);
        }
      }
    }

    .add-video-sub {
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: row;
      grid-column-gap: 30px;
      grid-column-gap: 30px;
      column-gap: 30px;
      width: 100%;
      background-color: #fff;
      justify-content: end;
      padding-bottom: 20px !important;
      padding: 16px;
      margin-left: 10px;
    }

    .clone-header-box{
      display: flex;
      justify-content: space-between;
      padding: 16px 30px;
      .clone-lrn-name{
        margin: 0;
        font-size: 18px;
        font-weight: bold;
      }
    }
    hr{
      border: 0;
      height: 1px;
      background: linear-gradient(to right, #ccc, #888, #ccc);
      margin: 20px 0;
    }

  }

}