@import '../../theme/mixins.scss';
section[name=Carousels] {
position: relative;
.carousel-root {
    .carousel.carousel-slider {
        width: 100% !important;
        .slide {
            img {
                // height: 425px;
                object-fit: cover;
            }
            .slide_info{
                position:absolute;
                bottom:40px;
                width:100%;
                z-index: 100;
                .title{
                    color:#fff;
                }
                .description{
                    margin-top: 8px;
                    color:#fff;
                    font-size: var(--global-font-size);
                    line-height:var(--global-line-height);
                }
            }
        }
        .carousel-container {
            position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
            .image-wrapper {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 100%;
                min-height: 100%;
              }
              
              .arrow-icons {
                position: absolute;
                width: 100%;
                height: 100%; /* Match the height of the image-wrapper */
                display: flex;
                justify-content: space-between;
                align-items: center; /* Center the arrows vertically */
                pointer-events: none; /* Ensure clicks pass through container */
              }
              
              .icon {
                pointer-events: auto; /* Allow clicks on arrows */
                cursor: pointer;
                color: var(--primary-color-2); /* Example styling for Chevron icons */
                font-size: 4rem;
                font-weight: 600;
              }
              
              .arrow-prev {
                left: 10px; /* Adjust as needed */
              }
              
              .arrow-next {
                right: 10px; /* Adjust as needed */
              }
          }
        .control-arrow {
            opacity: 0  ;
            z-index: 99999999;
            &.control-next {
                &:before {
                    background: transparent;
                    border-left: 22px solid #fff
                }
            }
            &.control-prev {
                &:before {
                    border-right: 22px solid #fff
                }
            }
            &:before {
                margin: 0 65px;
                border-top: 22px solid transparent;
                border-bottom: 22px solid transparent;
            }
            &:hover {
                background: none;
            }
        }
        .control-dots {
            li.dot {
                width: 10px;
                height: 10px;
            }
        }
    }
}


}
@include for-tablet-up {
    section[name=Carousels] {
        margin: 25px 0;
    }
}

.slide_info-bottom{
    background-color:var(--bs-gray-100);
    text-align: center;
    padding: 25px 25px 8px 25px;
    .description{
        font-size: var(--global-font-size);
        line-height:var(--global-line-height);
    }
}