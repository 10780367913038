section[name=TopicDescription] {
    min-height: 50px;
    margin: 25px 5%;
    .snippet-div {
        .text-content{
            font-size: var(--global-font-size);
            line-height:var(--global-line-height);
        }
        .snippet-div-main {
            margin: 0;
        }
        .snippet-head-tag {
            font-size: 25px;
            font-family: var(--global-font-family-bold)
        }
        .snippet-description {
            margin-top: 15px;
            font-size: 14px;
            line-height:var( --global-line-height);
        }
    }
}
