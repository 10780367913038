@font-face {
  font-family: 'WorkSansRegular';
  src: url('../assets/fonts/WorkSans-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'WorkSansMedium';
  src: url('../assets/fonts/WorkSans-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'WorkSansSemiBold';
  src: url('../assets/fonts/WorkSans-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'WorkSansBold';
  src: url('../assets/fonts/WorkSans-Bold.woff2')  format('woff2');
}
@font-face {
  font-family: 'WorkSansVariable';
  src: url('../assets/fonts/WorkSans-VariableFont_wght.woff2')  format('woff2');
}
@font-face {
  font-family: 'WorkSansLight';
  src: url('../assets/fonts/WorkSans-Light.woff2')  format('woff2');
}

@font-face {
  font-family: 'RobotoLight';
  src: url('../assets/fonts/Roboto-Light.woff2')  format('woff2');
}

@font-face {
  font-family: 'RobotoRegular';
  src: url('../assets/fonts/Roboto-Regular.woff2')  format('woff2');
}

@font-face {
  font-family: 'RobotoMedium';
  src: url('../assets/fonts/Roboto-Medium.woff2')  format('woff2');
}

@font-face {
  font-family: 'RobotoBold';
  src: url('../assets/fonts/Roboto-Bold.woff2')  format('woff2');
}

@font-face {
  font-family: 'GilroyThin';
  src: url('../assets/fonts/Gilroy/Gilroy-Thin.woff2')  format('woff2');
}

@font-face {
  font-family: 'GilroyRegular';
  src: url('../assets/fonts/Gilroy/Gilroy-Regular.woff2')  format('woff2');
}

@font-face {
  font-family: 'GilroyMedium';
  src: url('../assets/fonts/Gilroy/Gilroy-Medium.woff2')  format('woff2');
}

@font-face {
  font-family: 'GilroyBold';
  src: url('../assets/fonts/Gilroy/Gilroy-Bold.woff2')  format('woff2');
}

@font-face {
  font-family: 'BitterBold';
  src: url('../assets/fonts/Bitter/static/Bitter-Bold.ttf')  format('truetype');
}

@font-face {
  font-family: 'BitterRegular';
  src: url('../assets/fonts/Bitter/static/Bitter-Regular.ttf')  format('truetype');
}
@font-face {
  font-family: 'BitterThin';
  src: url('../assets/fonts/Bitter/static/Bitter-Regular.ttf')  format('truetype');
}

:root {
  --font-work-sans-regular: 'WorkSansRegular', serif;
  --font-work-sans-medium: 'WorkSansMedium', serif;
  --font-work-sans-semi-bold: 'WorkSansSemiBold', serif;
  --font-work-sans-bold: 'WorkSansBold', serif;
  --font-work-sans-variable: 'WorkSansVariable', serif;
  --font-work-sans-light: 'WorkSansLight', serif;
  --font-roboto-regular: 'RobotoRegular';
  --font-roboto-medium: 'RobotoMedium';
  --font-roboto-bold: 'RobotoBold';
  --font-roboto-lite: 'RobotoLight';
  /* --global-font-family */
  --global-font-family:'RobotoLight';
  --global-font-family-medium: 'RobotoMedium';
  --global-font-family-bold: 'RobotoBold';
  --font-gilroy-thin:'GilroyThin';
  --font-gilroy-regular:'GilroyRegular';
  --font-gilroy-medium:'GilroyMedium';
  --font-gilroy-bold:'GilroyBold';
  --font-bitter-bold:'BitterBold';
  --font-bitter-regular:'BitterRegular';
  --font-bitter-thin:'BitterThin';
}