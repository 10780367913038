.sweet-alert{
    color: #000 !important;
    .btn-primary{
        padding: 5px 24px;
        border: none !important;
        background-color: var(--primary-color-1) !important;
        color: #fff !important;
        transition: all .5s ease-in-out;
        font-weight: bold;
        box-shadow: 0 0 0 0.14rem  var(--primary-color-1) !important;
        &:hover {
            background-color: transparent !important;
            color: #000 !important;
        }
    }
    .btn-link{
        padding: 5px 9px;
        transition: all .5s ease-in-out;
        font-weight: bold;
        border: none !important;
        color: #000 !important;
        border-radius: var(--primary-radius-1);
        &:hover{
            box-shadow:0 0 0 0.14rem rgba(0, 0, 0, 0.25);
        }
    }

}

.danger-popup{
    .btn-primary{
        background-color: var(--bs-danger) !important;
        box-shadow: 0 0 0 0.14rem  var(--bs-danger)  !important;
        &:hover{
            background-color: #ff4960 !important;
            color: #fff !important;
        }
    }
}