@import '../../../theme/mixins.scss';
$width:100%;
$color:#5ea65b;
$text-color:#fff;
$border-btm:#ddd;

.video-controls{
    width: 100%;
    height:40px;
    display: flex;
    align-items: center;
    border-bottom:1px solid $border-btm;
    .progress-bar{
      width: 75%;
      height: 100%;
      position: relative;
      float: left;
      cursor: pointer;
      border-radius: 0px;
      margin-bottom: 0;
      background: #000;
      border-right: 1px solid $text-color;
    }
  }

  .vjs-volume-panel-horizontal{
    font-size: 13px;
    top:3px;
    width: 3em;
  }
  .vjs-picture-in-picture-control  {
    font-size: 12px;
    top:5px;
    width: 3em;
    display: none !important;
  }

  .vjs-text-track-display {
    &> div {
      &> div {
        @include for-big-desktop-up {
          font-size: 22px !important;
        }
        @include for-desktop-medium{
          font-size: 18px !important;
        }
        @include for-mobile-large{
          font-size: 14px !important;
        }

      }
    }
  }

  .vjs-has-started ~ .vjs-video-text-version, .vjs-has-started ~ .cuepoints{
    display: flex;
  }

  .vjs-has-started.vjs-user-inactive.vjs-playing ~ .vjs-video-text-version , .vjs-has-started.vjs-user-inactive.vjs-playing ~ .cuepoints{
    opacity: 0;
    visibility: visible;
    transition: visibility 0.1s, opacity 0.1s;
  }

  // .vjs-video-text-version {
  //   display: none;
  //   position: absolute;
  //   top: 608px;
  //   right: 126px;
  //   cursor: pointer;
  //   .video-text-icon {
  //     color: #ffffff;
  //   }
  // }

  
  .arc-text-version{
    display: none;
    position: absolute;
    // bottom:53px;
    // right:91px;
    bottom: 10px !important;
    right: 86px !important;
    @include for-phone-only {
      right: 29px !important;
    }

    cursor: pointer;
    .video-text-icon {
      color: #ffffff;
    }
  }

  .cuepoints {
    display: none;
  }
  
  .vjs-subs-caps-button {
    .vjs-icon-placeholder::before {
      margin-top: 3px;
      font-size: 22px;
    }
  }
  .vjs-progress-control {
    pointer-events: none;
    &.access-enabled {
      pointer-events: initial;
    }
  }
  
  /* Prevent CC settings menu item from displaying */
  .vjs-texttrack-settings {
    display: none;
  }

  .vjs-fullscreen  .vjs-playback-rate {
    display: none;
  }

  

  div.vjs-playback-rate {
    // right: 0;
    @include switch-direction-rtl(right,0);
    visibility: hidden;
    opacity: 0;
  }
  .progress-bar span {
    height:31px;
    position:absolute;
    top:0;
    display:block;
    color: $text-color;
    z-index: 1;
    padding: 9px 0 0 10px;
   }
   .progress-bar .current{z-index: 3;}
   .progress-bar .duration{z-index: 3;right:20px;}
   .time-bar{
    z-index:10;
    /* width:50%; */
    background-color:$color;
   }
  .controls-area{
    height: 100%;
    width:25%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000;
    .v-icon{
      padding:15px;
      height:20px;
      width:20px;
      cursor: pointer;
    }
  }

  [name="ArchiveVideo"] {
    .forward-backward-btns {
      display: none
    }
  
    &:hover {
      .forward-backward-btns {
        display: block;
      }
    }
  }
  
  @mixin btnsAbs {
    position: absolute;
    z-index: 100;
    top: 50%;
    width: 30px !important;
    cursor: pointer;
  }
  
  .backward {
    @include btnsAbs;
    left: 25%;
  }
  
  .forward {
    @include btnsAbs;
    right: 25%;
  }

  ._mobile{
    .arc-text-version{
      right: 29px !important;
    }
  }

  .vjs-play-progress{
    display: flex !important;
    justify-content: flex-end !important;
    .add-video-sub{
      font-family: var(--global-font-family);
      font-size: 24px;
      min-height: 55px;
      font-weight: bold;
      margin: -60px -32px 24px -16px;
      padding: 3px 10px;
      background-color:var(--bs-danger);
      border-radius: 0.3em;
      white-space: nowrap;
      cursor: pointer;
      pointer-events: auto !important;
      z-index: 99;
    }
  .vjs-time-tooltip{
    visibility:hidden !important;
  }
}
.vjs-mouse-display,.vjs-time-tooltip{
  min-height: 20px !important;
  top: -13px !important;
}
.current-time {
  display: block;
  font-size:14px;
  text-align: center;
  padding-top: 5px;
}