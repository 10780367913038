.issue-panel{
    padding: 16px;
    li{
     list-style: none;
     padding: 8px 0;
     &.issue_section .inner-container{
      font-weight: bold;
      background-color: var(--bs-gray-600) !important;
      color: #fff;
      cursor: pointer;
      .icons{
        background-color: var(--bs-gray-800) !important;
      }
     }
    }
    .inner-container{
        display: flex;
        align-items: center;
        width: 100%;
        background-color: var(--bs-gray-300);
        border-radius: 3px;
        .icons{
            min-height: 100%;
            background-color: #6c757d9c !important;
            min-width: 30px;
            margin-right: 6px;
            padding: 12px 6px;
            color: #fff;
            border-radius: 3px !important;
        }
    }
    .validation-btn{
        display: flex;
        width: 100%;
        justify-content: center;
    }
}