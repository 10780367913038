@import '../../../theme/mixins.scss';
.overlay-context-text {
    width: 100%;
    height: 85%;
    position: absolute;
    // left: 0;
    @include switch-direction-rtl(left,0);
    top: 60px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 101;
}
.add-on-context-text-overlay{
    position: absolute;
    bottom: 40px;
    min-height: 475px;
    width:100%;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    padding: 30px;
    padding-bottom:0;
    opacity: 0.95;
    background: #ffffff;
    // display: none;
    @media (max-width: 900px) {min-height: auto !important; }

    .ce-logo {
        margin-bottom: 30px;
        img {
            width: 200px;
        }
    }
    .head-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .close-btn{
            cursor: pointer;
        }
        .close-btn{
            position: absolute;
            top: 10px;
            // right: 5px;
            @include switch-direction-rtl(right,5px);
            svg{font-size: 35px;}
        }
        .overlay-title {
            font-weight: 600;
            margin: 0;
            font-size: var(--global-font-size);
            line-height:var(--global-line-height);
        }
    }
    .content-text-item{
        height:360px;
        overflow-x: hidden;
        overflow-y:auto;
        padding: 10px 12px 0px 0px;
        margin-top: 20px;
        @media (max-width: 900px) { height:239px; }
        @media (max-width: 600px) {height:120px; }
        &::-webkit-scrollbar {
            width: 3px;
          }
          
        /* Track */
        &::-webkit-scrollbar-track {
        background: transparent ; 
        }
           
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: var(--scrollbar-primary-1); 
        }
    }
}

._tablet{
    .add-on-context-text-overlay,.add-on-overlay{
        min-height: auto !important; 
        .content-text-item{
            height:239px !important;
        }
        .content-item{
            height:150px !important;
        }
    }

}
._mobile{
    .add-on-context-text-overlay,.add-on-overlay{
        min-height: auto !important; 
        .content-text-item{
            height:120px !important;
        }
    }
    .add-on-overlay{
        bottom: 33px !important;
    .content-item{
        height:100px !important;
    }
  }
}