.menu-list-container {
    .MuiPaper-rounded {
        margin-top: 15px;
        .comp-icon {
            padding-right: 15px;
            width: 35px;
        }
    }
    .MuiMenuItem-root, .MuiButton-root{
        font-family: var(--font-roboto-regular);
    }
}