.profile-container {
    .main-content-profile {
        margin: 0 15px;
        h5 {
            margin-bottom: 20px;
            margin-top:20px;
            cursor: pointer;
            display: inline-block;
            color: #000000;
            svg {
                font-size: 30px;
            }
        }
        .profile-card {
            box-shadow: var(--box-shadow-3);
            background: #ffff;
            padding: 15px;
            padding: 20px;
            border-radius: 10px;

            .input-main{
                margin: 30px 0 40px 0;
                .MuiInputLabel-outlined {
                    background: #ffff;
                }
            }

            .name-input {
                .name-placeholder {
                    font-size: 13px;
                    color: #a5a5a5;
                }
                .name-text {
                    font-weight: bold;
                }
            }
            .text-area {
                .MuiOutlinedInput-root {
                    height: 100px;
                }
            }

            .icon {
                .link-icon {
                    svg {
                        color: #007AB9;
                        font-size: 50px;
                        border-radius: 50%;
                    }
                }
                .facebook-icon {
                    svg {
                        color: #3B5998;
                        font-size: 50px;
                        border-radius: 50%;
                    }
                }
            }
        }

        .btn-group {
            float: right;
            margin-top:20px;
            button {
                border: 0;
                background: white;
                border-radius: 3px;
                margin: 10px 20px;
                padding: 10px;
                width: 130px;
                height: 45px;
                &.btn-cancel {
                    color: var(--primary-color-1);
                    border: 1px solid var(--primary-color-1);
                }
                &.btn-save {
                    background: var(--primary-color-1);
                    color: #ffff;
                    margin-right: 0;

                }
            }
        }
    }
}