@import '../../../theme/mixins.scss';
section.Image.selection {
    .custom-card {
        padding: 20px;
    }
}
.img-selection{
    width: 100%;
    margin: auto;
    padding: 25px 35px;
    background: #ffff;
    border-radius: 5px;
    box-shadow: 0 2px 16px 4px RGB(40 44 63 / 5%);
    .text-content,.response-main{
        font-size: var(--global-font-size);
        line-height:var(--global-line-height);
    }
    h3{}
    
        h4{
            margin-left: 0;
            @include for-tablet-up {
                margin-left:0
            }
            // color: var(--primary-color-1);
            font-weight: bold;
        }
        .list-items{
            display:flex;
            justify-content:space-between;
            flex-wrap: nowrap;
            margin: 0 3%;
            list-style-type: none;
            @include for-tablet-up {
                flex-wrap: wrap;
            }
            li{
                width: 17%;
                background-position: center;
                background-size: cover;
                height:130px;
                margin:30px;
                cursor: pointer;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                display: flex;
                justify-content: center;
                p{
                    position: absolute;
                    bottom: -58px;
                    max-height: 40px;
                    overflow: auto;
                }
                @include for-tablet-up { 
                    flex: 0 0 40%;
                    max-width: 40%;
                    margin-left:0;
                }
                @include for-mobile-small-devices {
                    flex: 0 0 35%;
                    max-width: 35%;
                    margin:15px;
                    height: 80px;
                }
                .overlay{
                    svg.check-icon {
                        font-size: 45px !important;
                        fill: var(--color-white);
                        padding: 5px;
                        background: var(--primary-color-1);
                        border-radius: 30px;
                    }
                    svg.clear-icon {
                        font-size: 45px !important;
                        fill: var(--color-white);
                        padding: 5px;
                        background: #f05d24;
                        border-radius: 30px;
                    }
                    display: flex;
                    align-items: center;
                    background-color: hsla(201, 100%, 6%, 0.6);
                    justify-content: center;
                    height: 100%;
                }
            }
        }
        .response-main {
            margin-top:8px;
            text-align: center;
            svg {
                fill: var(--primary-color-1)
            }
        }
        .s-content{
            text-align: center;
            margin: 0 7%;
        }
}