@import '../../../theme/mixins.scss';
.list-div {
    .list-div-head {
        .list-description {
            font-size: 14px;
            line-height: 34px;
        }
        .list-ul {
            list-style-type: none;
            margin-block-start: 0;
            padding-inline-start: 0;
            margin-top: 10px;
            .list-ol-li {
                margin-bottom: 15px;
                @include for-tablet-up {
                    font-size: 12px;
                    line-height:var(--global-line-height);
                    &:before {
                        width: 20px !important;
                        height: 20px !important;
                    }
                 }
                &:before {
                    content: attr(data-index);
                    margin-right: 10px;
                    text-align: center;
                    background: var(--primary-color-1);
                    width: 32px;
                    height: 32px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10px;
                    border-radius: 50%;
                    color: var(--color-white);
                }
            }
        }
    }
}