.skill-certificate {
    margin: 30px;
    font-family: var(--global-font-family-medium);
    border: 1px solid  var(--bs-gray-100);
    box-shadow: var(--box-shadow-1);
     
    .brand-logo {
        margin:0 16px;
    }

    .image-details {
        padding: 20px;
        flex-direction: column;
        min-width: 400px;
        .sub-logo{
            width: 250px;
            height: 150px;
            margin-bottom: 16px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .skill-certificate-details {
        position: relative;
        .inner-skill-certificate-details{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }
        // .brand-logo{visibility: hidden;}
        width: 100%;
        padding: 20px;
        .crs-h{
            font-size: 18px;
            margin: 0;
            color: var(--bs-gray-500);
        }
        .lrn-name{
            font-size: 34px;
            color: var(--primary-color-1);
            font-weight: bold;
        }
        .details{
            font-size: 14px;
            font-weight: bold;
            color: var(--primary-color-1);
            text-transform: uppercase;
            .crs-h{
                font-size: 14px;
                padding-right: 4px;
                font-weight: normal;
            }
        }
        .footer{
            color: var(--primary-color-1);
            display: flex;
            justify-content: space-between;
            width: 70%;
        }
    }
}

@media print {
   :root{
    --primary-color-1 :#052269;
    --bs-gray-500:#adb5bd;
   }
   .hr-line{
    color: #052269 !important;
    display: block !important;
    height: 1px !important;
    width: 100% !important;
   }
    //  @page {
    //     size: landscape;
    //     margin: 1cm;
    //     zoom:80%;
    //    }
    .skill-certificate,.skill-certificate-table,.skill-cer-td{
        size: landscape;
        page-break-inside: avoid !important;
        page-break-after: avoid;
    }
      table{
        max-height: 500px;
        page-break-inside: avoid !important;
      }

}