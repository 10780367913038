* {
  box-sizing: border-box;
}

html {
  font-size: 14px;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  scroll-behavior: smooth;
}

body {
  font-family: var( --font-work-sans-semi-bold);
  color: var(--color-black);
  letter-spacing: 0.35px;
  line-height: 1.25;
   font-weight: 300;
  margin: 0 auto;
}

a {
  color: var(--color-bright-blue-0);
  text-decoration: none !important
}

a:hover {
  text-decoration: underline;
}

ul {
  /* list-style: none; */
}

h1,
h2,
h3,
h4,
h5,

button {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.modalName {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.modalName input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #b9b9b9;
  box-sizing: border-box;
  border-radius: 4px;
}

.modalName button {
  background-color: var(--primary-color-1);
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 70%;
}
.modalName img{
  padding: 10px 0px;
}
.modalName button:hover {
  background-color: #45a049;
}
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.modalName-btn-holder{
  align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}
.modalName-img-holder{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}
strong{
  font-weight: 700;
}