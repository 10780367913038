.bg-custom-text-position{
    min-height: 500px;
    width: 100%;
    border-radius: 3px;
    display: flex;
    .text-content{color: #fff;font-size: var(--global-font-size); line-height:var(--global-line-height);}
    .snippet-div-main{
        height: 100%;
         overflow-y: auto;
         overflow-x: hidden !important;
         display: flex;
         .snippet-description{width: 100%;}
    }
    .hidden-text{
        visibility: hidden;
        padding: 8px
    }
}

.backgroundColor{
    width: 100%;
    height: 100%;
    position: absolute;

}