.over-lay-color-panel{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    align-items: center;
    ul{
        display: flex;
        li{
            width:40px;
            height: 40px;
            border-radius: 20px;
            margin-right: 10px;
            border:1px solid var(--border-color);
            cursor: pointer;
        }
    }
}
._desktop, .undefined{
    .vjs-tech{object-fit:cover;}
}

////Cue point Add Ui check////
// .add-on-overlay{
//     .ck-editor-container{
//         height:250px;
//         .rdw-editor-wrapper{
//             height:100%;
//         }
//     }
//     .preview-cue-btn{
//         position: absolute;
//         top: 20px;
//         right: 150px;
//         background-color: #000;
//         color: #fff;
//     }
//     .save-cue-btn{
//         position: absolute;
//         top: 20px;
//         right: 66px;
//         background-color: #000;
//         color: #fff;
//     }
// }