/* src/ScrollToTopButton.scss */
.scroll-to-top-btn {
    position: fixed !important;
    right: 20px;
    bottom: 20px;
    width: 35px;
    height: 50px;
    border: none;
    border-radius: 15px;
    background-color: var(--secondary-color-2);
    font-size: 24px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: translateY(-10%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 1);
    svg{
        font-size: 30px;
    }

    &.visible {
        opacity: 1;
        transform: translateY(-50%);
    }

    .bottom-right {
        right: 20px;
        bottom: 20px;
    }

    .top-right {
        right: 20px;
        top: 20px;
    }

    .top-left {
        left: 20px;
        top: 20px;
    }
}
