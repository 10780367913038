@import '../../../theme/mixins.scss';

.trade-tutor-container {
    .ce-image-main {
        img {
            width: 100%;
            padding: 0 1rem;
            cursor: pointer;
        }
    }

    .trade-tutor-row {
        padding: 0 2rem;
        margin-bottom: 20px;
    }

    .image-vignette-tag {
        width: 100%;
    }

    .ce-notes-list-images {
        padding: 0 2.4rem 1rem 0;
    }

    .ce-notes-image-list {
        // max-height: 650px; 
        height: calc( 100vh - 78px);
        overflow: hidden auto;
        .image-tag {
            width: 100%;
            cursor: pointer;
            &.active {
                border: 3px solid #000000;
                padding: 2px;
            }
        }

        &::-webkit-scrollbar {
            width: 8px;
          }
          
        /* Track */
        &::-webkit-scrollbar-track {
        background: #f1f1f1; 
        }
           
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: #c5c5c5; 
        }
    }

    .vignette-container {
        display: none;
        max-height: 95vh;
        overflow-y: auto;
        &.vignette-show {
            display: flex;
        }
        img{ width: 100%;}
    }

    .ce-container {
        display: none;
        &.ce-show {
            display: flex;
        }
    }


    .updates-container {
        display: none;
        &.update-show {
            display: block;
        }
        label {
            display: block;
            text-align: right;
            padding-right: 40px;
            position: sticky;
            top:0;
            background: #ffffff;
        }

        .date-added-updates {
            color: #cccc;
            margin-top: 10px;
            font-family: var(--global-font-medium);
        }
        .content-update-list {
            padding: 0 1rem;
            margin: 2.5rem 0;
            .title-header {
                color:var(--primary-color-1)
            }
            .inner-content {
                margin-top: 15px;
                font-family: var(--global-font-medium);
                p {
                    overflow: hidden;
                    text-overflow: ellipsis
                }
            }
        }
    }

    .overlay-block-content {
        position: relative;
        bottom: 58px;
        font-size: 12px;
        z-index: 9;
        padding: 15px;
        background: rgba(0, 0, 0, 0.7);
        width: 100%;
        color: white;
        @include for-desktop-up {
            font-size: 10px;
        }
    }
}

.no-supplimentarie {
    margin: 2rem;
    text-align: center;
}