@import "../../../theme/mixins.scss";
header.MuiAppBar-root {
  background: #fff;
  box-shadow: -2px 5px 12px -5px RGBA(0 0 0/30%);
  .MuiToolbar-gutters {
    box-shadow: -2px 5px 12px -5px RGBA(0 0 0/30%);
    min-height: 70px;
    z-index: 200;
    background: #fff;
    padding-left: 45px;
    @include for-tablet-up {
      padding-left: 18px;
      .left-menu {
        margin-right: 0 !important;
      }
    }
    .small-device-menu {
      display: none;
      position: relative;
      justify-content: flex-end;
      width: 100%;
      align-self: center;
      box-sizing: content-box;
      padding-right: 2vw;
      @include for-laptop-medium-up {
        display: flex !important;
      }
      .power-icon {
        margin-left: 5px;
        font-size: 25px;
        color: #000000;
      }
      .right-menu {
        margin-right: 0 !important;
        padding-top: 12px;
      }
      .small-device-menu {
        position: absolute;
        top: 53px;
        // right: -5px;
        @include switch-direction-rtl(right,-5px);
        background: #fff;
        min-width: 179px;
        display: flex;
        white-space: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 3px;
        min-height: 70px;
        max-width: 120px;
        .MuiButton-text {
          color: var(--bs-gray-700) !important;
          cursor: pointer;
          padding: 16px;
          width: 100%;
          font-family: var(--global-font-family-medium) !important;
          font-weight: bold;
          @include for-laptop-medium-up {
            font-size: 12px !important;
          }
        }
      }
    }
    button {
      width: 24px;
      height: 18px;
      @include for-laptop-medium-up {
        width: 45px;
      }
      svg {
        color: #707070 !important;
        font-size: 26px;
      }
    }
    .close-course-btn {
      border-radius: 0.4rem;
      padding: 0 15px;
      cursor: pointer;
      .power-icon {
        font-size: 25px;
      }
    }
    .img-container {
      margin-left: 18px;
      margin-bottom: 7px;
      &.edit-icon{
        min-width: 150px;
      }
      // min-width: 200px;
      @include for-tablet-up {
        margin-left: 0 !important;
        margin-top: -2px !important;
      }
      img {
        min-height: 25px;
        max-width: 330px;
        @include for-tablet-up {
          height: 18px !important;
        }
      }
    }

    .top-bar-menu {
      padding-right: 15px;
      @include for-laptop-medium-up {
        display: none;
      }
      .search-icon {
        display: inline-block;
        @include  for-laptop-medium-up {
          box-shadow: none;
        }
        button {
          padding: 0 10px;
          border-radius: 0.4rem !important;
          &:hover {
            background: none;
          }
        }
        svg {
          color: #a8a8a8;
          font-size: 30px;
        }
      }
      .badge-ce-updates {
        .MuiBadge-badge {
          position: absolute;
          // right: 20px;
          @include switch-direction-rtl(right,20px);
          background: #ffffff;
          color: #ff0a0a;
          border: 1px solid #ff0a0a;
        }
      }
      button {
        &:hover {
          background: none;
        }
        width: initial;
        padding: 0 15px;
        height: initial;
        color: #000000;
        text-transform: capitalize;
        font-size: 14px;
      }
    }

    .show-print-arrow {
      display: block;
      @include for-ipad-pro-devices {
        display: none;
      }
      // right: 140px;
      @include switch-direction-rtl(right,140px);
      top: 40px;
    }

    .menu-list-popper {
      margin-top: 10px;
    }

    svg.arrow-drop-up {
      position: absolute;
      color: var(--color-gray-3);
      display: none;
      font-size: 30px;
      &.show-arrow {
        display: block;
        @include for-ipad-pro-devices {
          display: none;
        }
      }
    }

    .edit-tool-menu {
      padding-right: 15px;
      .header-certificate {
        
        padding-right: 0px !important;
        cursor: pointer;
        *{
          font-family: var(--global-font-family-medium) !important;
        }
      }
      @include for-laptop-medium-up {
        // &:nth-child(2){
        //   width: 50%;
        // }

        .profile-pic,.user-detail,.arrow-dowen,.header-certificate,.header-course-map{
          display: none;
        }
      }
      button {
        &:hover {
          background: none;
        }
        width: initial;
        padding: 0 15px;
        height: initial;
        color: #000000;
        text-transform: capitalize;
        font-size: 16px;
      }
      .template-icon {
        padding-left: 0px;
        border-radius: 0.4rem;
      }
      .publish-btn{
        font-size: var(--global-font-size);
        background-color: var(--primary-color-1) !important;
        color: #fff !important;
        padding: 5px 10px;
        margin-right: 24px;
        transition: all .5s ease-in-out;
        font-weight: bold;
        box-shadow: 0 0 0 0.14rem  var(--primary-color-1) !important;
        border-radius: 3px;
        svg{
          font-size: 23px !important;
          margin-left: 4px;
          path{
            color: #fff !important;
          }
        }
      }
      .device-icon,
      .component-icon,
      .account-icon,
      .header-home,.language-icon{
        @include for-laptop-medium-up {
          box-shadow: none !important;
        }
        &.language-icon{
          margin-right: 23px;
        }
        color: var(--primary-color-1);
        border-radius: 0.4rem;
        cursor: pointer;
        margin: 0 15px;
        &:hover {
          color: var(--primary-color-1);
        }
        svg {
          font-size: 23px;
        }
      }
      .account-icon {
        margin: 0;
      }
      .component-icon {
        svg {
          font-size: 27px;
        }
      }
    }
  }

.publish-info-msg{
    position: absolute;
    height: 100%;
    display: flex;
    width: 97%;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    z-index: -1;
    p{
      margin-bottom: 2px !important;
      color: var(--bs-danger);
      font-weight: bold;
    }
    @media (max-width: 1360px) {
      align-items: end;
      font-size: 14px;
     }
}
 .publish-info-popup{
  .info-details .msg{
    font-weight: bold;
    font-size: 18px;
  }
 }
}

.tree-header {
  svg.arrow-drop-up {
    top: 40px;
    &.Device {
      // right: 105px;
      @include switch-direction-rtl(right,105px);
    }
    &.Template {
      // right: 170px;
      @include switch-direction-rtl(right,170px);
    }
    &.Profile {
      // right: 52px;
      @include switch-direction-rtl(right,52px);
    }
  }
}
.linear-header {
  .search-icon {
    border-radius: 0.4rem !important;
    @include for-laptop-up {
      padding: 0 2px !important;
    }
  }
}
// publish Popover
.publish-popover {
  .MuiPopover-paper{
    min-width: 500px !important;
    min-height: 230px  !important;
    .popover{
      min-width: 500px !important;
      height: 100% !important;
      display: flex;
      padding: 20px;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
      border: none !important;
      p{
        font-size: 18px;
         margin: 0;
      }
      .popover-btn{
        display: flex;
        justify-content: end;
        width: 100%;
        margin-top: 16px;
        column-gap: 16px;
      }
    }
  }
}