@import "../../../theme/mixins.scss";
@mixin arrow($border) {
    border-color: transparent #fff transparent transparent;
    border-style: solid;
    border-width: $border;
    content: "";
    height: 0;
    position: absolute;
    top: 0;
    width: 0;
}

// @mixin fontSize() {
    // font-size: var(--fnt-s, var(--global-font-size));
    // line-height:var(--global-line-height);
// }
@mixin hrStyle(){
    hr{
        border: 0;
        height: 1px;
        background: linear-gradient(to right, #ccc, #888, #ccc);
        margin: 20px 0;
    }
}



.scenario-block {
    // .MuiButton-label{
     // font-size:calc(var(--fnt-s, var(--global-font-size))- 6px) !important
    // }
    height: 56rem;
    overflow-y: auto;
    position: relative;
    overflow: hidden;
    transition: opacity .39s;
    ::-webkit-scrollbar{
        width: 6px !important;
    }
    .inner-model-sc-content{
        overflow-y: auto !important;
        padding: 0 16px;
        height: 92%;

        @include for-desktop-medium{
            height: 82%;
        }
        @include for-mobile-large{
            height: 80%;
        }
        @include for-mobile-small-devices{
            height: 64%;
        }
        .print-vertificate-sc-main{
            //print preview styles
            li,p,span,div{
                font-size: 24px !important;
                font-weight: normal !important;
            }
        }
        h2{
            font-size: 24px !important;
            padding-left: 12px;
        }
        .header-section{
            position: absolute;
            z-index: 9999999;
           right:7%;
           top:1.7%;
           @include  for-laptop-up{
            right:20%;
           }
        }
        .disclaimer-title{
            padding: 5px 0px ;
        }
        .info,.disclaimer-text{
            color: #000;
            &.warning{
                color: var(--bs-danger);
            }
                p{
                    font-size: 20px !important;
                    display: flex !important;
                   @include for-mobile-large{
                     flex-wrap:wrap;
                    }
                    textarea,div{
                         width: 100%; 
                         margin-left: 6px;
                         border-color: var(--bs-gray-400);
                         min-width: 200px;
                        }
                }
            b{
                display: inline-block;
                min-width:260px;
                // text-align: end;
            }
        }
        .ck-check-box{
            padding: 8px;
            .Mui-checked{color: var(--primary-color-1) !important;}
            .MuiButtonBase-root{svg{font-size: 24px;}}
            .MuiFormControlLabel-label{
                font-size:20px !important;
                padding-left: 6px;
                @include  for-laptop-up {
                    font-size:12px;
                }
            }
            position: absolute;
            bottom: 60px;
            background-color: #fff;
            width: 95%;
            align-items: baseline;
        }
    }
    .audio-player{
        .audio-section{
            margin: 2% !important;
        }
        .audio-container{
            margin-left: 0!important;
        }
        .instruction-text,img{
            display: none !important;
        }
    }
    .final-text-acton{
        display: flex;
        gap: 16px;
        justify-content: center;
        .final-action{
            text-align: left;
            .pw-btn{                  
                font-size: 14px;
              }
            h2{font-size: 22px !important; padding-left: 16px;}
            .common-model-cnt{
                li,p,span,div{
                    font-size: 24px !important;
                    font-weight: normal !important;
                    z-index: 999;
                }
                h2{font-size: 22px !important; padding-left: 12px;}
            }
            .topic-name{ text-align: center;}
             ol{
                color: #000;
                list-style: auto !important;
                li{
                    &.last-dialogs{
                     list-style-type: none !important;
                     margin-left: -30px;
                    }
                    font-size: var(--global-font-size);
                    .answer{
                        padding-left: 8px;  
                    }
                    .feedback-box{
                        margin-left: -30px
                    }
                  @include hrStyle();
                }
             }
            .header-section{
                display: flex;
                align-items: end;
                justify-content: end;
                .pw-btn{
                    cursor: pointer;
                    svg{
                        margin: 0 3px;
                    }
                    padding:8px;
                    background:var(--primary-color-2);
                    border-radius:3px;
                    border: none;
                    min-width:80px
                  }
            }
    
        }
    }


    .scenario-block-background {
        height: 100%;
        position: absolute;
        width: 100%;

        .back_image {
            transition: opacity .39s;
            width: 100%;
            height: 100%;
            // background-position: center !important;
            // background-size: cover !important;
            // opacity: 0.9;
            -webkit-transition: background-image 0.1s ease-in-out;
           transition: background-image 0.1s ease-in-out;
        }
    }

    .background-color{
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .scenario-block-inner {
        height: 100%;
        margin: 0 auto;
        max-width: 120rem;
        position: relative;
        transition: opacity .39s;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
            span,p,li,div{
                font-size: var(--fixed-font-size) !important;
                font-weight: var(--fixed-font-weight) !important;
            }
        .scenario-block-character {
            // left: 0;
            @include switch-direction-rtl(left,0);
            position: absolute;
            top: 3rem;
            transition: opacity .3s linear .1s, visibility 0s;
            visibility: visible;
            width: 25%;
            z-index: 2;

            @include for-mobile-large (){
                display: none !important;
            }

            .scenario-block-character-inner {
                img {
                    display: block;
                    // left: 55%;
                    @include switch-direction-rtl(left,55%);
                    max-height: 135%;
                    position: relative;
                    transform: translateX(-50%);
                    height: 100%;
                    width: 20rem;
                }
            }
        }

        .scenario-block-text {
            // color:var(--t_c, #000);
            // padding-left: calc(10% + 6rem);
            @include for-mobile-large (){
                padding-left:1rem !important;
            }
            &.no-character{
                padding-left:0 !important;
            }

            .scenario-block-text-inner {  
                background-color: #fff;
                max-width: 78rem;
                padding: 5rem 3.5rem 3.5rem;
                text-align: center;
                width: 100%;
                min-width: 450px;
                border-radius: 2px;
                position: relative;
                z-index: 2;
                .scenario-head {
                    padding-bottom: 6px;
                    .scenario-head-h2 {
                        display: flex;
                        flex-direction: column;
                        // justify-content: center;
                        // font-size:var(--fnt-s, var(--global-font-size));
                        font-weight: bold;
                        max-height: 200px;
                        overflow-y: auto;
                    }
                }

                .scenario-description {
                    &.full-scroll{
                        max-height: 50rem !important;
                    }
                    padding: 6px 0;
                    max-height: 300px;
                    overflow-y: auto;
                    margin-bottom: 16px;
                    .scenario-description-text{
                        padding: 2px;
                        text-align: left;
                        // @include fontSize();
                    }
                }
            }
        }

        .scenario-block-dialogues {
            height: 100%;
            // left: 0;
            @include switch-direction-rtl(left,0);
            padding: 0;
            position: absolute;
            top: 0;
            width: 100%;

            .dialogues-inner {
                height: 100%;
                position: relative;
                width: 100%;

                .scenario-block-dialogues-top {
                    height: 100%;
                    // left: 0;
                    @include switch-direction-rtl(left,0);
                    padding-left: calc(20% + 2rem);
                    padding-right: 1.5rem;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    @include for-mobile-large (){
                        padding-left:0 !important;
                    }

                    .top-inner {
                        height: 100%;
                        position: relative;
                        width: 100%;

                        .scenario-block-dialogue-appear-don {
                            // display: flex;
                            // justify-content: end;
                            padding-right: 80px;
                            padding-top: 50px;
                            top: 6.8rem;
                            position: relative;

                            .dialogue-body {
                                justify-content: flex-end;
                                // left: auto;
                                @include switch-direction-rtl(left,auto);
                                padding-left: 0 !important;
                                margin: 5px 0;
                                // right: -0.5rem;
                                @include switch-direction-rtl(right,-0.5rem);
                                display: flex;
                                // left: 0;
                                @include switch-direction-rtl(left,0);
                                max-width: 50rem;
                                padding-left: 2rem;
                                position: absolute;
                                top: 6.8rem;
                                width: 100%;
                                // color:var(--t_c, #000);
                                transition-duration: .39s;
                                transition-property: top, opacity;
                                // @include fontSize();
                                .dialogue {
                                    padding: 1.2rem;
                                    // background-color:var(--dia_bg, #fff);
                                    // color:var(--t_c, #000); ;
                                    border-radius: 1.2rem;
                                    display: inline-block;
                                    // @include fontSize();
                                    letter-spacing: .02rem;
                                    transition-duration: .39s;
                                    transition-property: top, opacity;
                                    max-width: 40rem;
                                    z-index: 2;
                                    .response-text{
                                        max-height:85px;
                                        overflow-y: auto;
                                    }
                                    p {
                                        margin: 0;
                                    }
                                }

                                // &::after {
                                //     @include arrow(0 3rem 3rem 0);
                                //     transform: scaleX(-1);
                                //     right: -2rem;
                                // }

                            }
                        }

                        .scenario-block-dialogue {
                            display: flex;
                            // left: 0;
                            @include switch-direction-rtl(left,0);
                            max-width: 50rem;
                            padding-left: 2rem;
                            position: absolute;
                            top: 6.8rem;
                            transition-duration: .39s;
                            transition-property: top, opacity;
                            width: 100%;

                            .dialogue-body {
                                // background-color:var(--dia_bg, #fff);
                                // color:var(--t_c, #000);
                                border-radius: 1.2rem;
                                display: inline-block;
                                // @include fontSize();
                                transition-duration: 0.39s;
                                transition-property: top, opacity;
                                margin: 5px 0;
                                padding: 1.2rem;
                                position: absolute;
                                z-index: 2;
                                .dialogue-content{
                                    max-height:140px;
                                    overflow-y: auto;
                                }
                                p {
                                    margin: 0;
                                }

                                .text-content{
                                    max-height: 366px;
                                    overflow-y: auto;
                                }

                                // &::after {
                                //     @include arrow(0 2.5rem 2.5rem 0);
                                //     left: -1.5rem
                                // }
                            }
                        }
                    }

                }

                .scenario-block-dialogues-bottom {
                    bottom: 0;
                    height: 55%;
                    // left: 0;
                    @include switch-direction-rtl(left,0);
                    position: absolute;
                    width: 100%;

                    .bottom-inner {
                        bottom: 1.25rem;
                        display: flex;
                        justify-content: end;
                        // left: 0;
                        @include switch-direction-rtl(left,0);
                        position: absolute;
                        width: 100%;
                        padding-right: calc(25% + 1rem);
                        @include for-desktop-medium{
                            padding-right:0 !important;
                         }
                        .scenario-block-dialogues-responses {
                            .response {
                                margin-left: 50px !important;
                                display: flex;
                                justify-content: end;
                                position: relative;
                                transition-duration: 0.29s;
                                transition-property: top, opacity;

                                .response-text {
                                    border: none;
                                    cursor: pointer;
                                    // background-color:var(--dia_bg, #fff);
                                    // color:var(--t_c, #000); ;
                                    border-radius: 1.2rem;
                                    display: inline-block;
                                    // @include fontSize();
                                    padding: 6px 25px 10px 25px;
                                    margin: 6px;
                                    max-width: 40rem;
                                    z-index: 2;
                                    max-height: 85px;
                                    overflow-y: auto;
                                    p {
                                        margin: 0;
                                    }

                                    &.action {
                                        transform: none !important;
                                        text-transform: capitalize !important;
                                        .MuiSvgIcon-root{
                                           font-size: var(--fixed-font-size) !important; ;
                                        }
                                    }

                                    &:hover {
                                        background-color: #eee !important;
                                        color: #000 !important;
                                        transform: translateX(1rem);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .scenario-continue-btn {
        z-index: 2;
        cursor: pointer;
        // border-radius: 6px;
        // color:var(--btn_t_c, #fff);
        // background-color: var(--btn_bg, var(--primary-color-1))!important;
        min-width: 120px;
        // back button at left
        &.back-btn{
            position: absolute;
            // left: 10px;
            @include switch-direction-rtl(left,10px);
            bottom: 10px;
            z-index: 999;
        }
    }
    
    // transparent  css
    .transparent-block{
        z-index: 2;
        padding: calc(3% + 1rem) calc(9% + 2rem) calc(3% + 2rem);
        max-height: 56rem;
        overflow-y: auto;
        // height: 100%;
        display: flex;
        // align-items: center;
        flex-direction: column;
        row-gap: 14px;
        .transparent-inner{
             display: flex;
            //  justify-content: center;
             flex-direction: column;
             height: 100%;
            .heading{ text-align: center;}
            .rhap_current-time{ font-size: 18px !important; }
            .answer-op p,
            .answer-op span {
            display: inline;
            margin: 0; 
            }
            .option-cnt,.response-feedback{
                .option-ul{
                    padding-left: 0 !important;
                }
                span,p,li,div{
                    font-size: 24px !important;
                }
            }

            
            .option-cnt{
                padding-left:calc(3% + 1rem);
                .response {
                    display: flex;
                    justify-content: start;
                    position: relative;
                    transition-duration: 0.29s;
                    transition-property: top, opacity;
                    &.response-clicked{
                        .response-text{
                            background-color:var(--bs-gray-400) !important;
                            color:#000!important;
                            transform: translateX(1rem);
                        } 
                    }
                    .response-text {
                        cursor: pointer;
                        border-radius: 8px;
                        display: inline-block;
                        margin: 6px;
                        max-width: 97%;
                        p {margin: 0;}
                        &.action {
                            transform: none !important;
                            text-transform: capitalize !important;
                            .MuiSvgIcon-root{
                            font-size: var(--fixed-font-size) !important;
                            }
                        }


                        padding: 3px;
                        &:hover {
                            background-color: #eee !important;
                            color: #000 !important;
                            transform: translateX(1rem);
                        }
                    }
                }
            }

            .response-feedback{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .dialogue-body{
                    padding-bottom: 16px;
                }
                .action-btns{
                    display: flex;
                    column-gap: 10px;
                    max-height: 300px;
                    .MuiButton-text{
                        p,span{font-size: 18px !important;}
                    }
                }
            }

        }

    }
    // transparent-text
    .template-transparent{
        .scenario-description{
            max-height: 100% !important;
          }
        .scenario-block-text-inner{
            max-height:800px !important;
            overflow-y: auto;
        }
    }
}
@mixin font-Size($font) {
    font-size: $font !important;
    *{font-size: $font !important;}
  }

  @mixin insideScreenResponsive ($dislodge,$continue,$header){
    .dialogue-body,.response-text,.dialogue{  @include font-Size($dislodge); }
    .scenario-continue-btn,.scenario-description-text{  @include font-Size($continue); }
    .scenario-head-h2{  @include font-Size($header); }
  }

._tablet {
    @include insideScreenResponsive(0.9rem,1.1rem,1.6rem);
    .scenario-block-dialogues-bottom .bottom-inner{
       padding-right: 0 !important;
    }
 }
 ._mobile {
    .scenario-block-character{display: none !important}
    .scenario-block-dialogues-top{padding-left: 0 !important}
    .scenario-block-text{padding-left: 1rem !important}
    @include insideScreenResponsive(0.7rem,0.9rem,1.2rem);
    .scenario-block-dialogues-bottom .bottom-inner{
        padding-right: 0 !important;
     }
 }
 
 @media only  print {
      @page {
        margin: 12mm;
      }
    .print-vertificate-sc-main {
        *{font-family: 'Roboto', sans-serif !important;}
        .print-sc-content {
            zoom:80% !important;
            margin:20px;
            padding: 16px;
            .header-section{ display: none;}
            .topic-name{text-align: center;}
            // .disclaimer-title{text-align: center; padding: 6px;}
            .info,.disclaimer-text{
                font-size: 15pt;
                b{display: inline-block;min-width:300px;}
            };
            .topic-name,.disclaimer-title{color: #1E3C88; font-weight: 600; font-size: 18pt;}
            ol{

                *{
                    font-size: 24px !important;
                }
            .last-dialogs{
                list-style-type: none !important;
                margin-left: -30px;
                }
                li{ page-break-inside: avoid;}  
            list-style: auto !important;
            font-size: 18pt;
            .answer{padding-left: 8px;}
            .feedback-box{
                margin-left: -30px;
            }
            @include hrStyle();
            }
        }
    }
}