@import "../../../theme//mixins.scss";

@mixin abStyle($font,$color){
    font-size: $font!important;
    font-weight: bold !important;
    color: $color!important;
}
.ck-editor-container{
    position: relative;
    h1{ @include abStyle(36px,#070D59); }
    h2{ @include abStyle(30px,#1E3C88); }
    h3{ @include abStyle(24px,#5993D4); }
    h4{ @include abStyle(36px,#14005A); }
    h5{ @include abStyle(30px,#03337C); }
    h6{ @include abStyle(26px,#1D82AD);}
    .pickr{
        width: 40px !important;
    }
    .pcr-button{
        height: 1.5em !important;
        width: 1.5em !important;
        margin: 0 5px 5px 5px !important;
     }
     
    .rdw-fontfamily-wrapper{  display: none !important; }

    .tool-bar-handle{
        position: absolute;
        z-index: 9;
        bottom: 5px;
        // right: 15px;
        @include switch-direction-rtl(right,15px);
        cursor: pointer;
        font-weight: bold;
    }
    &.hide-tool{
        .DraftEditor-root{
            padding-bottom: 18px !important;
        }
        .rdw-editor-toolbar{
            display: none !important;
        }
    }
    // &.show-tool{
    //     bottom: -14px !important;
    // }
.DraftEditor-editorContainer{
    font-size: 20px;
    line-height: 1.5;
}
.richtxt-editor-wrapper-class{
    border:1px solid #d1d1d1;
    border-radius:4px;
    display: flex;
    flex-direction:column-reverse;
}
.rdw-dropdown-selectedtext{
    color:#000
}

.rdw-dropdown-selectedtext:hover{
    color:#000
}

.rdw-editor-toolbar{
    font-family:var(--font-roboto-regular);
    font-size: 14px;
}

// Bad Fix
.public-DraftStyleDefault-ol{
    .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR{
        margin-left: 0 !important;
        counter-reset: "ol0";
        .public-DraftStyleDefault-block{
        &:before{
        content: counter(ol0)".";
        counter-increment: "ol0";
        margin:0 10px;
        }
    }
    }
}

    
.public-DraftStyleDefault-ul{
    .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR{
        list-style: none;
        margin: 0 !important;
        padding: 0 !important;
        .public-DraftStyleDefault-block{
            &:before {
                content:"•";
                margin:0 10px;
              }
        }
    }
    }

/* Custom LineHeight Option */

.rdw-dropdown-carettoopen{right: 4px}

.rdw-fontsize-dropdown{
    min-width: 50px;
    .specing-icon{
    width: 22px;
    }
}
.rdw-lineheight-4{line-height:4}
.rdw-lineheight-3-5{line-height:3.5;}
.rdw-lineheight-3{line-height:3;}
.rdw-lineheight-2-5{line-height:2.5;}
.rdw-lineheight-2{line-height:2;}
.rdw-lineheight-1-5{line-height:1.5;}
.rdw-lineheight-1-2{line-height:1.2;}
.rdw-lineheight-1{line-height:1;}
.rdw-pdl-60px{padding-left:20px;}
.rdw-pdl-120px{padding-left:40px;}
.rdw-pdl-180px{padding-left:60px;}
.rdw-pdl-240px{padding-left:80px;}
.rdw-pdl-300px{padding-left:90px;}
.rdw-pdl-360px{padding-left:100px;}
.customoption-drop-dowen{
    justify-content: flex-start;
    white-space: nowrap;
}

.public-DraftStyleDefault-ul,.public-DraftStyleDefault-ol{
    
.rdw-list-style-type-square{
    div{
        &:before{
            content: "▪" !important;
            margin: 0 10px;
        }
    }
}

.rdw-list-style-type-circle{
    div{
        &:before{
            content: "⚬" !important;
            margin: 0 10px;
        }
    }
}

.rdw-list-style-type-disc{
    div{
        &:before{
            content: "•" !important;
            margin: 0 10px;
        }
    }
}

//hide-toolbar
.hide-toolbar{
    min-width: 150px;
    min-height: 20px;
}

.rdw-list-style-type-decimal{
    div{
        &:before{
            content: counter(ol0) "."  !important;
            counter-increment: ol0;
       }
    }
}
.rdw-list-style-type-lower-alpha{
    div{
        &:before{
            content: counter(ol0,lower-alpha) "."  !important;
            counter-increment: ol0;
       }
    }
}
.rdw-list-style-type-upper-alpha{
    div{
        &:before{
            content: counter(ol0, upper-alpha) "."  !important;
            counter-increment: ol0;
       }
    }
}
.rdw-list-style-type-lower-roman {
    div{
        &:before{
            content: counter(ol0, lower-roman) "."  !important;
            counter-increment: ol0;
       }
    }

}
.rdw-list-style-type-upper-roman{
    div{
        &:before{
            content: counter(ol0, upper-roman) "."  !important;
            counter-increment: ol0;
       }
    }
}
}

.line-height-dropdown{
    .rdw-dropdown-selectedtext p{
        margin: 0;
    }
    .rdw-dropdown-optionwrapper{
        max-height: 140px !important;
    }
}
.quote-symbol,.indent-icon{
    cursor: pointer;
    padding: 8px;
}
}
