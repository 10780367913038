@import '../../../theme/mixins.scss';
.list-div {
    .list-div-head {
        margin: 30px 0;
        .list-description {
            font-size: 14px;
            line-height: 34px;
            margin-top: 50px;
        }
        .list-ul {
            list-style-type: none;
            margin-block-start: 0;
            padding-inline-start: 0;
            margin-top: 10px;
            .list-un-li {
                margin-bottom: 15px;
                line-height: var(--global-line-height);
                @include for-tablet-up {
                   font-size: 12px;
                   &:before {
                       width: 12px !important;
                       height: 12px !important;
                   }
                }
                &:before {
                    content: '';
                    margin-right: 10px;
                    position: relative;
                    top: -4px;
                    text-align: center;
                    background: var(--primary-color-1);
                    width: 17px;
                    height: 17px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 15px;
                    padding-top: 0;
                    border-radius: 50%;
                    color: var(--color-white);
                }
            }
        }
    }
}