.flip-page-container {
    padding: 25px 35px;
    background: #ffff;
    border-radius: 5px;
    box-shadow: 0 2px 16px 4px RGB(40 44 63/5%);
    .main-page-container {
        margin: 0 auto;
        img {
            width: 100%;
            height: 100%;
          }
      }
}
  