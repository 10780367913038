.login-container {
    margin-top:20px;
    .welcome-text {
        color: #5284B8;
        text-align: center;
        margin-bottom: 15px;
        font-weight: 800;
    }
    .login-form {
        display: flex;
        align-items: center;
        justify-content: center;
        .form-main {
            background: #ffff;
            text-align: center;
            box-shadow: var(--box-shadow-3);
            width: 400px;
            height: 350px;
            border-radius: 10px;
            .form-tag {
                margin: 0 15%;
            }
            .input-main{
                margin: 30px 0 40px 0;
                .MuiInputLabel-outlined {
                    background: #ffff;
                }
            }
            .password-input {
                margin-bottom: 10px;
            }
            .checkbox-input {
                text-align: initial;
                .MuiFormControlLabel-label {
                    font-size: 12px;
                    font-weight: 300;
                    color: #a5a5a5;
                }
            }
            .version-text {
                color: #a5a5a5;
                font-weight: 600;
                font-size: 16px;
                margin-top: 20px;
            }
            .MuiInputLabel-formControl {
                color: #a5a5a5;
            }
            .submit-btn {
                margin-top: 20px;
                .login-btn {
                    display: block;
                    width: 100%;
                    border: none;
                    background: var(--primary-color-1);
                    color:#ffff;
                    border-radius: 3px;
                    height: 30px;
                    font-weight: 600;
                }
                .forgot-password {
                    text-align: initial;
                    margin-top: 5px;
                    font-weight: bold;
                }
            }
        }
    }
}