 .shar-certificate-container{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 700px;
 }
    .schar-certificate{
        margin: 30px;
        font-family: var(--global-font-family-medium);
        border: 1px solid  var(--bs-gray-100);
        box-shadow: var(--box-shadow-1);
        .certificate{
                position: relative;
                text-align: center;
                width: 800px;
                min-height:600px;
                // height: 800px;
                overflow: hidden;
                font-family: 'Great Vibes';
                background-image: url(../../../assets/img/schar_certificate.png);
                background-repeat: no-repeat;
               .crs-username-box{
                width: 600px;
                margin: auto;
                padding-top: 260px;
                text-align: center;
                .schar-crs-username{
                    text-transform: capitalize;
                    color:var(--primary-color-1);
                    font-family: "Great Vibes";
                    font-size: 1.5rem;
                    text-align: center;
                    font-weight: bold;
                   }
               }
               .crs-name-box{
                width: 92%;
                margin: auto;
                text-align: center;
                margin-top: 14.5%;
                height: 7.3rem;
                .schar-crs-name{
                    color:var(--primary-color-1);
                    font-weight: 600;
                    font-family: "Old Standard TT, sarif";
                    font-size: 1.5rem;
                }
               }
               .totaltime p{
                color: #000000;
                font-family: "Old Standard TT, sarif";
                font-size: 1rem;
                text-align: center;
                font-weight: 600;
               }
        }
     }

 @media only  print {
    .schar-certificate{
        box-shadow:none;
        margin: 10px !important;
        margin-top: 60px !important;
    }
    .schar-crs-name,.schar-crs-username{
        color: #13984b !important;
    }
 }