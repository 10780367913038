.upload-container {
    .asset {cursor: pointer;}
    .col-lg-6{
      @media (max-width: 1460px) { width: 100% !important;}
    }
    .common-position-select{
        margin-top: 7px;
        .MuiInputLabel-root{
            background-color: transparent !important;
        }
    }
    .copy-file-name{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 97%;
        display: flex;
        .name{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .file-name{
        text-align: left;
        margin-top: 15px;
        padding-left: 20px;
        font-size: 12px;
        font-weight: bold;
        .delete-icon{
            font-size:18px;
        }
        svg{
            cursor: pointer;
            font-size: 24px;
        }
    }
    .uploader-panel {
        // height: 50px;
        border: 3px dotted #ddd;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 5px 0;
        .upload-summery{
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            flex-direction: column;
            padding: 6px;
            .upload-icon{
                font-size: 30px;
                color: #939393;
                margin: 3px;
            }
            p{
                margin-bottom:2px;
            }
            .drag-drop{
                font-weight: bold;
                font-size: 12px;
            }
            .drag-drop-summery{
                font-size: 10px;
            }
        }
    }
}
.uploaded-files {
    margin: auto;
    margin-top: 10px;
    padding-left: 0;
    .loader-info {
        color:var(--primary-color-1);
        text-align: center;
    }
}