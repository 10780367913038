@mixin btnStyle($color){
    background: var($color) !important;
    filter: brightness(1);
    font-size: 11px; 
    &:hover{
        font-weight: bolder;
        filter:none !important;
    }
}

.simonwep-pickr {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    .pickr{
            width: 80px ;
            height: 100%;
            margin-top: 7px;
    }
    svg{
        position: absolute;
        font-size: 34px;
        color: #ffff;
        margin-top: -10px;
        @media (max-width: 1200px) {
            font-size: 23px !important;
        }
    }
}


.pcr-app {
    // top: var(--picker-top) !important;
    // left: var(--picker-left) !important;
    font-family:var(--font-roboto-regular) !important;
    .pcr-interaction {
        flex-direction: row-reverse !important;
        .pcr-type.active {
            color: #000 !important;
            background: #fff !important;
            font-weight: bold !important;
         }
        .pcr-save{ @include btnStyle(--color-black);   }
        .pcr-cancel{ @include btnStyle(--color-black); }
        input[aria-label="colorize-icon"]{
            font-size: 13px;
            font-weight: bold;
           }
           input[aria-label="save-text"]{
            font-size: 12px;
            font-weight: bold;
        }
    }
}

.pcr-app[data-theme='nano']{
    width: 19.25em !important;
   }

   .icon-picker{
    .pickr{
        .pcr-button{
            height: 3.8em !important;
            width: 100% !important;
        }
    }
}