@import '../../theme/mixins.scss';

.content-transition{
    .main-layout-container {
        margin-top: 60px;
        animation: layout .8s ease-out 0s;
        @include for-tablet-up {
          margin-top: 100px;
        }
        @keyframes layout {
            0% {
                opacity:0;
                transform:  translate(0px,-25px)  ;
              }
              100% {
                opacity:1;
                transform:  translate(0px,0px)  ;
              }
    }
    .markup-render{
        background-color: #f8f8f8;
      }
    }
    .header-div {
        margin: 11% 5% 1% 5%;
    }
    .scrollable-section{
        // min-height: 190px;
        // background-color:var(--color-white);
        border-bottom:2px solid var(--list-bg-color);
    }
}



.highlighter {
  border: solid var(--ce-border-color, #ededed);
  border-width: 0px;
  box-sizing: border-box;

  transition: border 300ms;



  &.active {
    border-width: 8px;
    height: 100vh;
    overflow: auto;
  }
}

.label {
  padding: 8px;
}
//  common indication icon so kept here  
.con-status-icon{
  display: flex;
  justify-content:flex-end;
  align-items: center;
  .check-circle-icon{
    color:var(--primary-color-1);
  }
}

/*** Global Styling  ****/

//Fonts 
// @mixin font-family($value) {
//   *{ font-family: $value !important; }
// }
// .WorkSansRegular{ @include font-family('WorkSansRegular');}
// .WorkSansSemiBold {@include font-family('WorkSansSemiBold');}
// .WorkSansLight{@include font-family('font-work-sans-light');}
// .Roboto {@include font-family(var(--font-roboto-regular));}
// .times-new-roman{@include font-family('Times New Roman');}


// margin and padding
@for $i from 1 through 10 {
  .smt-#{$i} {
    .scrollable-section {
      margin-top: $i + rem !important;
    }
  }
  .smb-#{$i} {
    .scrollable-section {
      margin-bottom: $i + rem !important;
    }
  }
  .spr-#{$i} {
    .scrollable-section {
      padding-right: $i + rem !important;
    }
  }
  .spl-#{$i} {
    .scrollable-section {
      padding-left: $i + rem !important;
    }
  }
  .gfs-#{$i}{
    .scrollable-section {
      font-size: $i + px !important;
    }
   }
}


// // global font size
// @for $fs from 10 through 30{
//   $medium: $fs - 6;
//   $small: $fs - 10;
//  .gfs-#{$fs}{
//   .scrollable-section {
//   p,ul,li,.download-note,.text-content,.description,.text-content .MuiFormControlLabel-label,.question,.response-text,p span,li span{
//       font-size: $fs + px !important;
//       line-height: 1.2;
//     }
//   }
//  }
// }
.gfs{
  .scrollable-section {
  p,ul,li,.download-note,.text-content,.description,.text-content .MuiFormControlLabel-label,.question,.response-text,p span,li span{
      font-size: var(--global-font-size) !important;
    }
  }
 }

.hide-instruction{
  .instruction-text{
    .instruct-panel{
      visibility: hidden;
      // display: none !important;
    }
  }
}

/*** Global Styling  ****/