@import "../../theme/mixins.scss";

.flipcard-main-container {
  padding: 15px;
  margin: 0 auto;
  .instruction-text {
    display: none;
  }

  // css added for image-gallery
  &.image-gallery-modal-carousel {
    .arrow-icons{
      display: none;
    }
    .slide_info-bottom {
      background-color: transparent;
      span,
      p {
        font-family: var(--global-font-family);
        font-size: var(--global-font-size);
      }
    }
    .text-content {
      font-family: var(--global-font-family);
      font-size: var(--global-font-size);
      // font-size: 16px !important;
      padding: 8px 8px;
    }
    // grid for desktop and laptop view
    .gallery-grid {
      display: grid;
      gap: 16px;

      &.g-col-1 {
        grid-template-columns: 1fr;
      }
      &.g-col-2 {
        grid-template-columns: repeat(2, 1fr);
      }
      &.g-col-3 {
        grid-template-columns: repeat(3, 1fr);
      }
      &.g-col-4 {
        grid-template-columns: repeat(4, 1fr);
      }
      &.g-col-5 {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    img {
      object-fit: contain !important;
    }
    // .row {
    //   justify-content: flex-start !important;
    // }
  }
  @media (min-width: 800px) {
    margin: 0;
  }
  .row {
    justify-content: space-evenly;
  }

  .react-card-flipper {
    max-height: 250px;
    min-height: 250px;
  }
  .react-card-flip {
    min-width: 30%;
    max-width: 350px;
    padding: 10px 0;
    cursor: pointer;
    @include for-tablet-up {
      max-width: 100%;
      padding: 10px 10px;
    }
  }
  .react-card-front,
  .react-card-back {
    box-shadow: var(--box-shadow-2);
    background-color: var(--color-white);
    max-height: 250px;
    min-height: 250px;
    border-top-right-radius: 10px;
  }
  .flip-img-tag {
    width: 100%;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 250px;
    height: 300px;
    overflow: hidden;
    img {
      border-top-right-radius: 10px;
      max-height: 250px;
      width: 100%;
    }
  }
  .flip-title-section {
    margin: 15px;
    .border-line {
      width: 20%;
      border-top: 2px solid var(--primary-color-1);
    }
    .flip-title {
      font-weight: bolder;
    }
  }
  .more-btn,
  .backSideBtn {
    text-align: center;
    margin: 10px;
    padding: 10px;
    // border-top: 1.5px solid #cccc;
    button {
      color: var(--primary-color-1);
      background: none;
      border: none;
    }
  }

  .flip-next {
    position: absolute;
    bottom: 7px;
    // right: 16px;
    @include switch-direction-rtl(right,16px);
    border: 0;
    background: none;
    color: #ffff;
    &:hover {
      color: #000000;
    }
  }
  .flip-back-text {
    .text {
      padding: 20px 19px;
      height: 250px;
      overflow: auto;
      font-weight: 200;
      font-size: var(--global-font-size);
      line-height: var(--global-line-height);
    }
  }

  .back {
    background: #fafafa;
    .backside-flip-container {
      min-height: 200px;
      .row {
        padding-top: 10px;
        .topics {
          font-size: 12px;
          margin-bottom: 7px;
          @include for-tablet-up {
            margin-bottom: 0;
          }
          .field {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    .field {
      color: #878181cc;
    }
    .title {
      font-weight: bold;
      @include for-tablet-up {
        margin-bottom: 0.7rem;
      }
    }
  }
}

// image-gallery-grid for mobile view
._mobile {
  .flipcard-main-container {
    &.image-gallery-modal-carousel {
      .gallery-grid {
        gap: 16px;

        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

// image-gallery-grid for tablet view
._tablet {
  .flipcard-main-container {
    &.image-gallery-modal-carousel {
      .gallery-grid {
        gap: 16px;

        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

.transparent-model{
  .common-modal-header{
    background-color: transparent !important;
  }
}

// image-gallery-grid for laptop view
._labtop {
  .flipcard-main-container {
    &.image-gallery-modal-carousel {
      .gallery-grid {
        gap: 16px;
        &.g-col-1 {
          grid-template-columns: 1fr;
        }
        &.g-col-2 {
          grid-template-columns: repeat(2, 1fr);
        }
        &.g-col-3 {
          grid-template-columns: repeat(3, 1fr);
        }
        &.g-col-4 {
          grid-template-columns: repeat(4, 1fr);
        }
        &.g-col-5 {
          grid-template-columns: repeat(5, 1fr);
        }
      }
    }
  }
}
