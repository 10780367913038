@import '../../../theme/mixins.scss';
.overlay-render {
    width: 100%;
    height: 94%;
    position: absolute;
    // left: 0;
    @include switch-direction-rtl(left,0);
    top: 0;
    background: rgba(0, 0, 0, 0.7);
}
.add-on-overlay{
    position: absolute;
    bottom: 80px;
    min-height:400px;
    width:100%;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    padding: 30px;
    padding-bottom:0;
    opacity: 0.95;
    // display: none;
    z-index: 101;
    @media (max-width: 900px) {min-height: auto !important; }
    @media (max-width: 600px) {bottom: 33px !important;}
    .ce-logo {
        margin-bottom: 30px;
        img {
            width: 200px;
        }
    }
    .head-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .close-btn{
            cursor: pointer;
        }
       .close-btn {
            position: absolute;
            top: 20px;
            // right: 15px;
            @include switch-direction-rtl(right,15px);
            svg{font-size: 30px;}
        }
        .overlay-title {
            font-weight: 600;
            margin: 0;
            font-size: var(--global-font-size);
            line-height:var(--global-line-height);
        }
    }
    .content-item{
        height:360px;
        overflow-x: hidden;
        overflow-y:auto;
        padding: 10px 12px 0px 0px;
        font-size: var(--global-font-size);
        line-height:var(--global-line-height);
        @media (max-width: 900px) { height:150px; }
        @media (max-width: 600px) {height:100px; }
        &::-webkit-scrollbar {
            width: 3px;
          }
          
        /* Track */
        &::-webkit-scrollbar-track {
        background: transparent ; 
        }
           
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background:var(--scrollbar-primary-1); 
        }
    }
}