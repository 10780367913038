.quiz-config-container {
  .clear-quiz{
    display: flex;
    justify-content: end;
    margin-top: -25px;
    padding: 6px 0;
    span{
      background: var(--bs-gray-300);
      padding: 3px 12px;
      border-radius: 23px;
      cursor: pointer;
    }
  }
    .quiz-upper-container {
      padding: 16px 16px 16px 0;
      display: flex;
      column-gap: 12px;
      padding-bottom: 8px;
      flex-wrap: wrap;
       .attempt-option{
        width: 50%;
       }
      .question-text {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px !important;
      }
      .ck-checkbox-root{
        margin-right:10px !important;
      }
      .max-questions {
        margin: 0 12px;
  
        .MuiFilledInput-input {
          padding: 9px 8px 9px !important;
          min-width: 60px;
          background: #fff !important;
        }
      }
    }
  
    .inner-btng {
      margin: 10px 0;
    }
  
    .attempt-options {
      padding: 8px 0;
      width: 100%;
      justify-content: space-between;
      align-items: baseline;
      display: flex;
      flex-wrap: wrap;
  
      .attempt-option {
        width: 50%;
  
        label {
          min-width: 35%;
        }
      }
    }
  
    .failure-at {
      display: flex;
      width: 100%;
      padding: 6px 10px;
      flex-wrap: wrap;
  
      .ck-check-box {
        min-width: 40%;
      }
    }
  
    .crt-at {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: baseline;
  
      label {
        width: 35%;
      }
  
      input {
        width: 90%;
        margin: 8px;
      }
    }
  }
  