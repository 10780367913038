@import '../../../theme/mixins.scss';
.answer-select-div {
    padding:25px 35px;
    background: #ffff;
    border-radius: 5px;
    box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.05);
    @include for-tablet-up {
        margin: 0
    }
    .title {
        font-weight: bolder;
        color: var(--primary-color-1);
        font-family: var(--global-font-family-medium);
        display: flex;
        font-size: var(--global-heading-font-size);
    }
    .MuiTypography-root,.question {
        font-size: var(--global-font-size);
        line-height:var(--global-line-height);
    }
    .question {
        margin-top: 10px;
    }
    .response-main {
        background: #f8f8f8;
        padding: 10px;
        margin-top: 15px;
        border-radius: 10px;
        p:first-child {
            font-family: var(--global-font-family-medium);
        }
        p {
            font-size: var(--global-font-size);
            line-height:var(--global-line-height);
        }
        div{font-size: 18px;}
        svg {
            fill: var(--primary-color-1)
        }
    }

    .MuiRadio-colorSecondary.Mui-checked {
        color:var(--primary-color-1)
    }
}

@include for-tablet-landscape-up {
    section[name=AnswerSelect], section[name=RadioSelect] {
        margin:25px 4%;
    }
}
section[name=AnswerSelect], section[name=RadioSelect] {
    margin:25px 3.5%;
}