.badge-container{
    height: 100px;
    margin:20px 0 10px 0;
    border-radius: 10px;
    .header-icon {
        margin-bottom: 15px;
    }
    .text-badge {
        font-weight: 600;
    }
}
