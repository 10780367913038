.topic-edit-panel{
    .mt10{
        margin-top:20px;
    }
    .tp-action-info{
        margin-top: 10px;
        text-align: right;
        .cancel-btn{
            margin-right: 10px;
        }
        .disabled-btn{
            background-color:grey;
            color:#000;
        }
        .submit-btn{
            background-color: var(--primary-color-1);
            color:#fff;
        }
    }
}