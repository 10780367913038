@import '../../../theme/mixins.scss';
section[name=HalfImageText] {
    .instruction-text {
        svg {
            bottom: 2px;
        }
    }
}
        .image-card-div {
            height: 100%;
            @include for-tablet-up {
                margin: 3% 3%;
            }
            .row-reverse {
                flex-direction: row-reverse;
                .img-section {
                    padding-left: 0;
                }
            }
            .layout.image-text {
                box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.05);
                box-sizing: border-box;
                overflow: hidden;
            }
            .no-shadow {
                box-shadow: none !important;
            }
            .text-section {
                // padding: 3% 3%;
                // position: relative;
                // background: #ffffff;
                a {
                    cursor: pointer;
                }
                .title-head {
                    margin-top: 15px;
                    font-size: var(--global-heading-font-size);
                    font-weight: bold;
                    font-family:var(--global-font-family-medium);
                    @include for-tablet-up {
                        margin:10px 10px;
                    }
                }
            }
            .half-section {
                padding: 0 3%;
                // background: none !important;
            }
            .img-text {
                margin: 0 10%;
            }
            .half {
                top: 95%;
            }
            .img-section {
                // padding-right: 0;
                // padding-left: 0;
            }
            .row {
                height: inherit;
            }
            .image-main {
                height: 100%;
            }
        }

        .row-reverse{
            display: flex;
            flex-direction: row-reverse;
        }

        .custom-text-position{
            display: flex;
            padding: 10px;
            .text-content{
                font-size: var(--global-font-size);
                line-height:var(--global-line-height);
            }
        }

//center align
.center-img{
    .img-section {
        padding-right: 0 !important;
        padding-left: 0 !important;
        display: flex;
        justify-content: center;
        .image-main {
            width: 60% !important;
        }
    }
}