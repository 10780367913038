@import "../../../theme/mixins.scss";
div.MuiCircularProgress-colorPrimary {
    color:var(--completion-color);
    height: 34px !important;
    width: 34px !important;
    border: 4.5px solid #eeeeee;
    border-radius: 50%;
    svg {
        height: 34px;
        position: relative;
        top: -5px;
        // right: 4px;
        @include switch-direction-rtl(right,4px);
    }
}

.complete-text {
    display: block;
}