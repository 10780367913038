@import '../../../theme/mixins.scss';
.edit-drawer-component {
    .overlay-container {
        #overlay {
            width: 100%;
            height: 100vh;
            position: fixed;
            background: transparent;
            // left: 0;
            @include switch-direction-rtl(left,0);
            top: 0;
            display: none;
            transition: display 4s ease-in-out 0s;
            z-index: 9;
            &.open {
              display: block;
              transform: translateX(0)
            }
          }
          #edit-drawer-menu {
            width: 30%;
            overflow: hidden auto;
            box-shadow: var(--box-shadow-2);
            height: 94%;
            background:#F5F5F5;
            position: fixed;
            // right: 0;
            @include switch-direction-rtl(right,0);
            top: 68px;
            z-index: 99999999;
            transform: translateX(100%);
            transition: all 0.3s ease 0s;
            &::-webkit-scrollbar {
              width: 2px;
            }
            
          /* Track */
          &::-webkit-scrollbar-track {
          background: transparent ; 
          }
             
          /* Handle */
          &::-webkit-scrollbar-thumb {
          background: var(--scrollbar-primary-1); 
          }
              &.open {
                transform: translateX(0);
              }
              @include for-laptop-up {
                width: 100%;
              }
          }
          .ce-drawer-header {
                padding: 30px 20px 10px 20px;
                // box-shadow: var(--box-shadow-2);
                font-family:var(--global-font-family-medium);
                // background-color: #FFF;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .ce-drawer-text-header {
                    text-transform:uppercase;
                    font-weight: bold;
                    font-size: 1rem;
                }
                .prop-id{
                  font-weight: bold;
                  margin-bottom: 7px;
                  .prop-hash-id{
                      font-weight: normal;
                  }
                }
            }
            .ce-drawer-main-ui {
              // padding: 25px 25px;
              // padding-right:7px;
            }
            .ce-drawer-close-icon {
              background-color: transparent;
              border: none;
              margin-top: -8px;
                svg {
                    font-size: 30px;
                    cursor: pointer;
                }
            }
    }
}
