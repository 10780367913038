@import "../../../theme/mixins.scss";
.search-list-container {
    position: absolute;
    max-height: 435px;
    overflow: hidden auto;
    top: 108px;
    // right: 0;
    @include switch-direction-rtl(right,0);
    width: 100%;
    background: transparent;
    z-index: 9999;
    &.search-list {
        background: #ffff;
    }
    &.no-query {
        background: transparent;
    }
    .search-ul-list {
      margin-bottom: 0;
    }
    .search-content {
        margin: 8px;
        padding: 20px 27px;
        animation: expand .4s ease-in-out;
        box-shadow: 0 0 2px 0px #dfdfdfcc;
        color: #787878;
        @keyframes expand {
            from {
              transform: scale(0);
              opacity: 0;
              background: #f8f8f8;
            }
          }
        &:hover {
            cursor: pointer;
            background: #f8f8f8;
            color: #000000;
        }
        span {
            padding-right: 10px;
        }
    }

    .result-not-found {
        margin-top: 1rem;
        text-align: center;
        padding: 10px;
        color: #000000;
        display: none;
        &.show {
            display: block;
        }
    }
}

.search-list-container::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  .search-list-container::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .search-list-container::-webkit-scrollbar-thumb {
    background: #c5c5c5; 
    border-radius: 10px;
  }