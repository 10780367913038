.map-play-container {
    padding: 25px 35px;
    background: #ffff;
    border-radius: 5px;
    box-shadow: 0 2px 16px 4px RGB(40 44 63/5%);
    .worldmap__figure-container {
        width: 50%;
        margin: 0 auto;
    }
    .video-container {
        width: 50%;
    }
}