.course-panel{
    // display: flex;
    // justify-content: space-between;
    // background-color: #ddd;
    justify-content: space-between;
    .collection,.jurisdiction{
        padding: 5px;
        border: none;
        width: 30%;
        outline: none;
        margin: 5px;
        box-shadow: 1px 1px #ddd;
        border-radius: 5px;
    }
    .courselist,.topiclist{
        background-color: #fff;
        padding: 5px;
        margin: 5px;
        height: 300px;
        max-height: 300px;
        overflow-y: auto;
        box-shadow: 1px 1px #ddd;
        border-radius: 5px;
        list-style: none;
    }
    .course-li-active,.topic-li-active{
        background-color: #ddd;
        padding: 5px;
        margin: 5px;
        list-style: none;
        cursor: pointer;
    }
    .btn-cancel{
        border: none;
        font-weight: 600;
    }

    .btn-add{
        background-color: #000;
        color: #fff;
        border: none;
        padding: 5px;
        border-radius: 3px;
    }
    .mb10{
        margin-bottom: 10px!important;
    }
    .mr5{
        margin-right: 5px!important;
    }
    .mt10 { margin-top: 10px;}
    
    .title{
        padding:5px;
        margin:5px;
        font-weight: 600;
    }
    .course-li,.topic-li{
        padding: 5px;
        margin: 5px;
        cursor: pointer;
    }
    .selected-tp{
        flex:4;
    }
    .path{
        flex:4;
    }
    .archive-video{
        margin-left: 10px;
        padding: 5px !important;
    }
    
    .selectable-tp{
        flex:1;
        overflow: hidden auto;
        height: calc(80vh - 25px);
        ul{
            margin-bottom: 0;
            border:1px solid #ddd;
            li{
                border-bottom: 1px solid #ddd;
                padding: 10px 5px;
                cursor: pointer;
            }
            .topic-element{
                .item-name{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                .btn-list{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                }
            }
            li:last-child {
                border-bottom: none;
            }
            .active{
                background-color:#dcf0e4;
            }
        }
        &::-webkit-scrollbar {
            width: 8px;
          }
          
        /* Track */
        &::-webkit-scrollbar-track {
        background: #f1f1f1; 
        }
           
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: #c5c5c5; 
        }
    }
}