@import '../../../theme/mixins.scss';
.tab-list{
    // height: 500px;
    box-shadow: var(--box-shadow-1);
    background-color: var(--color-white);
    font-family: var( --font-work-sans-bold);
    @include for-tablet-up {
        margin:0
    }

    .tab-video {
        .topic-complete{
            display: none;
        }
        p.instruct-panel {
            display: none;
        }
    }

    .description {
        font-size: var(--global-font-size);
        line-height:var(--global-line-height);
        padding: 10px 15px;
        font-family: var(--global-font-family);
        min-height:100px;
    }

    .MuiAppBar-colorPrimary {
        color: initial !important;
        background: initial !important;
    }
        
    .MuiTabs-flexContainer {
        width: 100%;
    }
    .MuiTab-root {
        max-width: unset;
    }
    .image {
        width: 100%;
        padding: 15px 15px;
        // height: 350px;
        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .MuiTabs-indicator {
        height: 5px;
        background: var(--primary-color-1);
        font-family: var(--global-font-family-medium);

    }

    .MuiTab-textColorInherit {
        font-size: 18px;
        font-family: var(--global-font-family-medium);
    }

    .MuiButtonBase-root {
        @include for-tablet-up {
            font-size: 12px;
        }
    }
}