@import '../../../theme/mixins.scss';
.search-over-lay{
    position: absolute;
    // right:0;
    @include switch-direction-rtl(right,0);
    width: 35%;
    padding:15px;
    top: 70px;
    background-color: #dcf0e4;
    animation: expand .4s ease-in-out;
    @include for-laptop-medium-up {
        width: 95%;
        z-index: 1;
      }
    @keyframes expand {
        0% {
          transform: scale(0);
          opacity: 0;
        }
        100% {
            transform: scale(1);
            opacity: 1;
          }
      }
    .s-head {
        display: flex;
        justify-content: end;
        padding-bottom: 10px;
        svg {
            color: red;
            background: white;
            border-radius: 50%;
            cursor: pointer;
            padding: 2px;
        }
    }
    .matched-query-length {
        color: #000000;
    }
    .search-area{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .txt-box{
            width:100%;
            height:50px;
            border-radius: 40px;
            margin:auto;
            outline: none;
            border:none;
            padding-left: 20px;
        }
    }
}
.search-icon {
    @include for-laptop-medium-up {
        margin-left:12px;
      }
    animation: more .2s ease-in;
    @keyframes more {
        0% {
          transform: scale(0);
          opacity: 0;
        }
        100% {
            transform: scale(1);
            opacity: 1;
          }
      }
}