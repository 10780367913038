@import "../../../../theme/mixins.scss";

.modal-header {
    width: 100%;
    padding: 0;

    .header-text {
        font-size: 20px;
        font-weight: bold;
        padding: 8px 16px;
        margin: 0;
        cursor: pointer;
    }

    .close-icon {
        padding-right: 16px;
        cursor: pointer;
        svg {font-size: 28px !important;}
    }
}
.assets-tab{
    .MuiTabs-scroller {
        padding:0 16px;
    }
}
.outer-row {
    margin: 0;
    padding: 0px 30px 30px 30px;
    overflow-y: scroll;
    scroll-behavior: smooth !important;
    box-sizing: content-box;
    @include for-desktop-up {
        max-height: 52vh;
    }

    .assets-search {
        position: sticky;
        top: 0;
        z-index: 999;
        background-color: #fff;
        box-shadow: var();
        .image-gif{
            width: 100%;
        }

        .row {
            padding-top: 15px;
        }
    }

    .loading-area {
        margin-top: 0;
        position: absolute;
        background-color: #fff;
        opacity: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56vh;
        width: 100%;
        color: #000000;
        z-index: 9;

        h4 {
            z-index: 999;
        }
    }

    .toolbar-searchbar {
        display: flex;
        border-radius: 4px;
        border: 1px solid var(--bs-gray);
        margin-bottom: 10px;
        box-shadow: var(--box-shadow-1);
        margin-bottom: 16px;
        height: 40px;
        .assets-search-box{
         padding-left: 20px;
         &.optional-search-crs-name{
            width: 79%;
            .dropdown-menu{
                &.show{
                    overflow-x: hidden !important;
                }
            }
            .dropdown-item {
                min-height: 37px !important;
                &:hover, &:focus{
                    color: #1e2125;
                    background-color: #e9ecef;
                }
            }
            .rbt-menu-pagination-option,.dropdown-divider{
                display: none;
            }
            input{
                border: none;
                box-shadow: none;
            }
         }
        }
        .assets-ar-video{
            // width: 20%;
            .MuiOutlinedInput-root{
               height: 100%;
            }
        }
        .search-btn{
            padding: 6px;
            svg{font-size: 25px;}
            .text-box-loader{
                width: 20px !important;
                height: 20px !important;
                color: #000;
            }
        }
    }

    .col-lg-24 {
        .wishlists-box {
            text-align: right;

            .wishlist-btn {
                padding: 8px 55px;
                background: var(--primary-color-1);
                color: var(--bs-body-bg);
                border-radius: 5px;
            }
        }
    }
}

.asset-close {
    position: absolute;
    // right: 0;
    @include switch-direction-rtl(right,0);
    top: 5px;
    cursor: pointer;

    .MuiSvgIcon-root {
        font-size: 20px;
        font-size: 30px;
    }
}

.MuiDrawer-modal {
    .MuiPaper-elevation {
        height: 80vh !important;
        top: 10vh !important;
        // right: 10% !important;
        @include switch-direction-rtl(right,10% !important);
        overflow-x: auto !important;
    }
}

.modal-bottom {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    width: 100%;
    background-color: #fff;
    justify-content: end;
    padding: 10px 25px;

    .model-cancel {
        border: 1px solid var(--bs-gray);
        color: #000000;
        padding: 5px 20px;
    }
}

/** Assets Player Style  */

.selected-container {
    padding: 0 20px 0 30px;
    height: 80vh;

    .image-details-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .migrated-msg{ margin-top:16px; padding-left:16px; color: #dc3545; }
        .dis-head{padding: 10px 16px 0 16px;}
        .dis{overflow-y: auto; padding:16px;}
        .header-div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .close-icon {
                cursor: pointer;
                color: #212529;
                svg{
                    font-size: 28px !important;
                    font-weight: bolder;
                }
            }
        }
        .detail-heading {
            padding: 16px 0px 0 16px;
            margin: 0;
        }

        .player {
            width: 100%;
            min-height: 60%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: var(--bs-gray-100);
            .add-video-sub{display: none !important;}
            .vjs-video-text-version{display: none;}
            .video-js .vjs-big-play-button{
                top: 43% !important;
                width: 2em !important;
                height:2em !important ;
                span::before{
                    font-size: 46px !important;
                    top: 7px !important;
                }
            }
            .forward,.backward{top: 40% !important; }

            .img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
                object-fit: cover;
            }

            .instruction-text {
                display: none;
            }

            .audio-section-container {
                width: 37vw;

                .audio-container {
                    margin: 0 10px 0 -15px !important;

                    img {
                        width: 33px;
                        height: 30px;
                    }
                }
            }

            .video-container {
                width: 35vw !important;
                height: 40vh !important;
                margin-bottom: 0px !important;

                .vjs-subs-caps-button {
                    display: none;
                }

                .vjs-playback-rate {
                    display: none;
                }
            }
        }
        /* quiz Bank */
        .selected-question{
            min-height: 550px;
            .question-label{
                font-size: 16px;
                font-weight:bold;
            }
            .options{
                display: flex;
                padding:6px 0;
                .check-icon{
                    width:5%;
                    padding-right:5px;
                    .correct-option{
                        fill:var(--primary-color-1);
                    }
                    .wrong-option{
                        fill:#dc3545;
                    }
                }
            }
        }
    }

    .details {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: sticky;
        padding: 16px 0 16px 24px;

        .detail-heading {
            font-weight: 400;
            margin-bottom: 20px;
        }

        .link-button {
            display: flex;
            flex-direction: row;
            column-gap: 16px;

            .btn {
                border-color: var(--bs-gray);
                font-weight: 600;
                font-size: 14px;
                padding: 5px 20px;
                height: 35px;
                border-radius: 4px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                color: #000000;
                white-space: nowrap;
                margin: 0;

                span {
                    padding-right: 3px;
                }

                &:hover {
                    background-color: var(--primary-color-1);
                    color: #ffffff;
                }
            }
        }
    }
}

/** Wishlist Style */

.list-container {
    overflow-x: hidden !important;
    padding: 16px;

    .row {
        padding: 0 16px 0 11px;

        .col-lg-2 {
            width: 14.2% !important;

            @include for-mobile-large() {
                width: 100%;
            }
        }
    }

    .wishlist-card {
        overflow-y: auto;
    }

    .folder-name {
        overflow-y: auto;
        padding: 0;
        height: 100%;
        @include for-desktop-up  {
            padding-bottom: 24px !important;
        }
        .list-box {
            list-style: none;
            padding: 0 !important;
            margin: 0 !important;
            min-height: 72vh;
            background: var(--bs-gray-100);

            .active-list {
                background-color: var(--primary-color-1) !important;
                color: #fff !important;
            }

            .list-item {
                padding: 10px 3px 10px 16px;
                border-bottom: 1px solid var(--border-color);
                cursor: pointer;

                &:hover {
                    background-color: var(--bs-gray-400);
                }
            }
        }
    }
}


/** Assets Caed Style */

.inner-row {
    height: 53vh;
    .show-more-box{
        display: flex;
        justify-content: center;
        Button{
            background-color: var(--bs-body-color);
            padding: 10px;
            color: var(--bs-body-bg);
            font-weight: bold;
            max-height: 40px;
            border: 1px solid var(--bs-body-color);
            text-transform: none !important;
            &:hover{
            background-color:var(--bs-body-bg) ;
            color:var(--bs-body-color);
            font-weight: bolder;
            }
        }
    }

    .col-lg-2 {
        width: 12.5%;

        @include for-mobile-large() {
            width: 50%;
        }
        @include for-desktop-medium(){
            width: 24%;
        }
        @include for-laptop-up(){
            width: 33%;
        }
        @include for-mobile-small-devices (){
            width: 100%;
        }
    }

    .listing-card-container {
        border: 0;
        border-radius: 4px 4px 0 0;
        box-shadow: var(--box-shadow-1);
        cursor: pointer;
        margin-bottom: 24px;
        padding: 5px;

        .card-body {
            padding: 3px;

            .card-source {
                height: 150px;
                box-sizing: content-box;
                overflow: hidden;
                background-color: var(--bs-gray);
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .asset-fit {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }

                .audio-icon {
                    color: #fff;
                    font-size: 30px;
                }

                .play-icon {
                    position: absolute;
                    font-size: 40px;
                    color: var(--bs-gray-400);
                }
            }

            .d-flex {
                flex-direction: column !important;
                display: flex !important;
                padding-top: 3px;

                .card-title {
                    padding-bottom: 0 !important;
                    font-weight: 600;
                    font-size: 12px;
                    margin: 0 !important;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            .list-info {
                white-space: nowrap;
                font-size: 12px;

                .label {
                    padding: 8px 0;
                }
            }
        }
    }
}

.css-19kzrtu {
    padding: 0 !important;
}

.MuiTabs-flexContainer {
    display: flex;
    background-color: var(--bs-gray-200);

    .tab-name {
        background-color: var(--bs-gray-300);
        font-weight: bold;
        font-size: 1rem;
    }

    .active-tab {
        background-color: #fff;
    }
}
.assets-inner-container{
    height: 100%;
    overflow: hidden;
    [role="tabpanel"]{
        height: 80%;
        overflow: hidden;
        .MuiBox-root,.list-container{
            height: 100%;
            color: #fff;
        }
        .row{
            color: #000 !important;
        }
        .list-container .row{
            height: 100%;

        }
    }
}

/* Topic List */

.topic-video-container{
    .tab-video-list{
        box-shadow: 0px 0px 3px #00000029;
        padding: 0 !important;
        margin-bottom: 10px;
        &:hover { box-shadow: 0px 0.5px 6px 0px #00000052;}
        .listing-card{
            border: 0;
            border-radius: 4px 4px 0 0;
            margin: 0;
            cursor: pointer;
            display: flex;
            position: relative;
            .card-body{
             display: flex;
             align-items: center!important;
             cursor: pointer!important;
             .course-card-img{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 16px;
                color: #198754;
                svg{
                    font-size: 30px;
                    margin: 4px 0;
                }
             }
             .d-flex{
                overflow: hidden;
                text-overflow: ellipsis;
                .card-title{
                    font-weight: 500;
                    font-size: 1rem !important;
                    font-weight: 600;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
                .card-topic{
                    font-weight: 500;
                    margin-bottom: 7px;
                }
                .card-text{
                    max-height: 25px;
                    overflow: hidden;
                    margin: 0;
                    .list-info{
                        font-weight: 400!important;
                        .dis{section{margin: 0 !important;}}
                    }
                }
             }
            }
        }
    }
}

.listing-card-quizzes{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 15px 0px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    // box-shadow: 0px 2.5px 6px 0px #00000052
}
.listing-card-quizzes:hover{
    box-shadow: 0px 2.5px 6px 0px #00000052;
}
