@import "../../../theme/mixins.scss";
.prop-panel{
    width: 100%;
    padding: 5px;
    height: calc(100vh - 250px);
    overflow-x: hidden;
    background-color: #f5f5f5 !important;
    border: none;
    // padding-right: 8px;
    // padding: 10px;
    ul{
        li{
            // padding: 15px 10px;
            label{
                text-transform: capitalize;
            }
        }
    }
    .prop-id {
        font-weight: bold;
        span {
            font-weight: normal;
        }
    }
    .black-slider {
        .MuiSlider-thumb {
            background-color:var(--secondary-color-2); 
            &:hover {
              background-color: #555;
            }
          }
          .MuiSlider-track {
            background-color:var(--secondary-color-2);
            border-color: var(--secondary-color-2);
          }
          .MuiSlider-rail {
            background-color: #ccc;
          }
          .MuiSlider-valueLabel {
            top: 22px;
            left:12px; 
            transform: translateX(-50%); 
            background-color: var(--secondary-color-2); 
            color: #fff; 
            padding: 4px 8px; 
            border-radius: 4px; 
            font-size: 12px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            &.MuiSlider-valueLabelOpen { 
                &::before {
                    transform: translate(-50%, 50%) rotate(135deg);
                    top:-8px;
                    left: 47%;
                }
              }
          }
    }
    .video-hotpot-panel{
        // .summary{
        //   background-color: var(--bs-gray-200);
        // }
        .poster-check{
            .select-ck{
                padding-top: 9px;
                .MuiCheckbox-root{
                    padding:0px !important
                } 
                .MuiTypography-root{
                    @include  for-ipad-pro-devices{
                       white-space: wrap !important;
                    }
                }
            }
        }
        .error-duration{
            color:var(--bs-danger);
            font-weight: bold;
        }
        .poster-check .ck-checkbox-root{
            padding-top:7px !important;
        }
    }
    .row > *{
        padding-right:calc(var(--bs-gutter-x) * .3) !important;
        padding-left: calc(var(--bs-gutter-x) * .3) !important;
    }


    .catch-game-panel{
        .option-container{
            display: flex;
            justify-content: space-between;
            svg{
                &.option-ans{
                    margin-right:6px;
                    &.correct-ans{
                    background: var(--primary-color-1);
                    color: #fff;
                    border-radius: 50%;
                    }
                }
                &.option-delete{
                    margin-left:6px;
                }
                font-size: 24px;
                cursor: pointer;
            }
        }
    }

    /* property component styles */

    select{
        min-width:130px;
        min-height:33px;
        padding: 8px;
        cursor: pointer;
    }

    .MuiOutlinedInput-inputMultiline {
        padding: 5px 0 75px 0;
        height: 48px;
    }
    .label.MuiFormLabel-root {
        background: #f8f8f8;
    }
    .topic-head-prop-container, .topic-desc-prop-container, .audio-prop-container, .video-prop-container {
        .topic-head-prop-description {
            margin-top: 25px !important;
        }
      .que-point-edit-area{
        margin: 8px 0;
        max-width: 140px;
        .MuiInputBase-root {color: #000 !important;}
        svg{
            font-size: 18px;
            cursor: pointer;
            color: #000;
        }
      }

        .delete-edit-icon {
            color:var(--color-gray-3)
        }
        & > div {
                margin: 30px 0;
            }
            .cue-point-list{
                .cue-header{
                        width: 80%;
                }
            }
        }
        .poster-check{
            padding: 0 16px;
        }
    #outlined-basic {
        font-family: var(--global-font-family-medium);
    }
    /* card flip  */

    .card-prop-container, .half-image-panel-container, .card-stack-panel-container, .carousels-panel-container {
        .delete-prop-card {
            position: absolute;
            // left: 95%;
            @include switch-direction-rtl(left,95%);
            color:var(--color-gray-3);
            cursor: pointer;
        }
        .card-prop-image-position{
            padding: 16px 0;
        }
        .carousel-global-property{
            padding: 20px 20px 0 20px;
            .carousels-textposition{ padding-left:16px; } 
            .dynamic-height-box{
                padding: 5px 16px;
                display: flex;
                select{
                    margin-left:16px;
                }
            }
           .MuiSelect-select{
    border: 1px solid var(--bs-gray-500) !important;
}
        }
        .card-prop-main-ui{
            .align-text-radio{
                margin-left:17px;
            }
        }

        // .add-card {
        //     position: relative;
        //     left: 96%;
        //     bottom: 20px;
        //     font-size: 36px;
        //     background: white;
        //     border-radius: 50%;
        //     cursor: pointer;
        // }
    
        // .card-prop-file {
        //     margin: 0 0 0px 0;
        // }
        // .card-prop-title, .card-prop-description {
        //     margin: 28px 0;
        // }
    }
    /* ck check box */
    .ck-check-box{
        .MuiButtonBase-root{
            svg{
                font-size: 24px;
                path{color: #000;}
            }
        }
        .MuiFormControlLabel-label{
            padding-left: 5px;
            font-weight:bold;
        }
    }

    /* halfimagetext */

    .half-image-panel-container {

    }
  /* Radio Select Panel */
    .radio-prop-panel{
        margin-top: 10px;
        .question-header .MuiOutlinedInput-root {
            font-weight:bold ;
        }

    }

        /* Image ImageMapper */

        .imageHighlight-prop-container {
            .radio_select {
                margin-top: 15px;
            }
            // .edit-prop-accordion,
            // .save-prop-accordion {
            //     position: absolute;
            //     left: 91%;
            //     color: var(--color-gray-3);
            // }
            .error_msg {
                font-size: 10px;
                padding-top: 6px;
                color: var(--bs-danger);
            }
            // .delete-prop-accordion {
            //     position: absolute;
            //     left: 95%;
            //     color: var(--color-gray-3);
            // }
            // .edit-area-prop-accordion{
            //     position: absolute;
            //     left: 75%;
            //     color: var(--color-gray-3);
            //     &:hover{
            //         color:#000;
            //     }
            // }
            // .MuiAccordionSummary-content{
            //     .active {
            //         color: var(--color-green-4);
            //         font-weight: 600;
            //     }
            // }
            // .save-prop-accordion {
            //     font-size: 25px;
            // }
            .shape-select {
                width: 100%;
                cursor: pointer;
            }
            // .MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
            //     cursor: initial;
            // }
            .tool_container {
                display: flex;
                column-gap: 22px;
                .tool_icon {
                    font-size: 48px;
                    box-sizing:content-box;
                    &:hover {
                        background-color: #b9b8b8;
                    }
                }
                .active {
                    border-style: dashed solid;
                    border-color: var(--color-gray-4);
                }
            }
            .color_container {
                display: flex;
                column-gap: 15px;
                .MuiInputBase-input {
                    width: 35px;
                    height: 35px;
                }
            }
            .mt-15 {
                padding: 0 5px;
            }
        }

    .topic-head-prop-container, .topic-desc-prop-container {
        label.MuiFormLabel-root {
            background: #f8f8f8;
        }
        .MuiFormLabel-root.Mui-focused,
        .MuiFormLabel-root.Mui-focused {
            color: inherit !important;
            border: inherit !important;
            background: #f8f8f8 !important;
        }
    }

    /* tabs */
    // .imagmap-prop-container,.tabs-prop-container {
        // .edit-prop-tabs, .save-prop-tabs {
        //     position: absolute;
        //     left: 91%;
        //     color:var(--color-gray-3)
        // }
        // .delete-prop-tabs {
        //     position: absolute;
        //     left: 95%;
        //     color:var(--color-gray-3)
        // }
        // .save-prop-tabs {
        //     font-size: 25px;
        // }
        // .MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
        //     cursor: initial;
        // }
        // .add-tabs {
        //     position: relative;
        //     left: 97%;
        //     bottom: 20px;
        //     font-size: 36px;
        //     background: white;
        //     border-radius: 50%;
        //     cursor: pointer;
        // }
    
        // .mt-15 {
        //     margin-top: 15px;
        // }
    // }
    /* inner-accordion-continuer */
    .inner-accordion-continuer{
        .MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
            cursor: initial;
            margin: 1px 0 !important;
            .inner-summary{
               background:var(--bs-gray-700) !important;
            }
        }
        .add-new-slide{
            bottom:2px !important;
            margin-bottom: 10px;
        }
    }

    /* Accordion */
    .accordion-prop-container,.video-prop-container,.card-prop-main-ui,.tabs-prop-container,.imagmap-prop-container,.inner-accordion-continuer,.scenario-block-prop-container {
        .edit-prop-accordion, .save-prop-accordion,.edit-prop-tabs {
            position: absolute;
            // left: 86%;
            @include switch-direction-rtl(left,86%);
            // color:var(--color-gray-3)
            color: #fff;
            [data-tooltip]{
                min-width: 180px !important;
                &::after {
                   min-width: 180px !important;
                }
            }
        }
        .acccordion-item-switch{
            display: flex;
            align-items: end;
            justify-content: end;
            padding: 3px;
        }
        .delete-prop-accordion,.delete-prop-tabs,.delete-prop-card {
            position: absolute;
            // left: 93%;
            @include switch-direction-rtl(left,93% );
            // color:var(--color-gray-3)
            color: #fff;
        }
        .edit-area-prop-accordion{
            position: absolute;
            // left: 62%;
            @include switch-direction-rtl(left,62%);
            color:#fff;
            @media (max-width: 1460px) {
                //  left:40% !important;
                 @include switch-direction-rtl(left,40% !important);
                }
        }
        .save-prop-accordion {
            font-size: 25px;
        }
        .MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
            cursor: initial;
            margin: 1px 0 !important;
            .MuiAccordionDetails-root{
               color: #000;
            }
            .MuiAccordionSummary-root{
                .MuiAccordionSummary-content{
                .MuiTypography-root{ 
                    width: 76%;
                    @media (max-width: 1200px){
                        width: 60% !important;
                    }
                    *{font-size: 1rem !important;}
                    &.acc-title{ 
                        white-space: pre-wrap;
                    }}
                }
                .list-title{
                    width: 80%;
                    background-color: #f5f5f5;
                    border-radius: 3px;
                    &.acc-title{
                        min-height:35px;
                        overflow: hidden;
                        white-space: pre-wrap;
                    }
                    .MuiInput-root{
                        width: 100%; 
                        input{ padding: 10px;}
                    }
                }
                font-family: var(---global-font-family-bold);
                background:#000;
                color: #fff;
                // min-height:40px !important;
            }
            .MuiCollapse-entered{
                background:#f5f5f5;
                color: #000;
            }
        }
        .card-prop-title{
            padding: 10px 0;
        }

        .add-new-slide{
            font-size: 14px;
            font-weight: bold;
            position: relative;
            bottom: -20px;
            // left: 20px;
            @include switch-direction-rtl(left,10px);
            cursor: pointer;
            .add-accordion,.add-card,.add-tabs {
                // left: 97%;
                // bottom: 20px;
                font-size: 32px !important;
                color: #f5f5f5;
                background: #000;
                border-radius: 50%;
            }
        }
    }


    /* Radio Group Style */
    .row-radio-group{
        display: flex;
        align-items: center;
        .radio-chk{
            margin: 0 6px !important;
            cursor: pointer;
            .MuiRadio-colorSecondary.Mui-checked{
                color: #000 !important;
            }
            .MuiSvgIcon-root{
              font-size: 25px !important;
            }
        }
    }
    /* label */
    .ce-slider{
        color: #000;
    }
    
    /* label style */
    .label{
        font-weight: bold;
        margin: 0 !important;
        padding-left: 0 !important;
        white-space: nowrap;
    }
    .label-bold{
        font-weight: bold;
    }
    /* x-align */
    .video-prop-readable,.x-align{
        padding: 0 10px;
    }

    /* image map */
    .imagmap-prop-container {
        .plotting {
            margin: 25px 0
        }
        .col-lg-6{
            @media (max-width: 1460px) { width: 100% !important;}
          }
        .add-mapping {
            position: relative;
            // left: 97%;
            @include switch-direction-rtl(left,97%);
            bottom: 20px;
            font-size: 36px;
            background: white;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    /**
        orderlist-container
    **/

    .orderedlist-edit-container {
        .list-items{
            margin-top: 10px;
        }
        .order-list-main {
            max-height: 350px;
            overflow: auto;
            label.MuiFormLabel-root {
                background:#f6f6f6 !important;
            }
            .MuiFormLabel-root.Mui-focused {
                background: #e6e6e6 !important;
            }
            .MuiFormControl-root.MuiTextField-root {
                width: 100%;
            }
        }
        .edit-order-list {
            margin-right: 10px;
            color: var(--color-gray-3)
        }
        .delete-order-list {
            color: var(--color-gray-3)
        }
        .add-order-list {
            .add-plus {
                position: relative;
                // left: 96%;
                @include switch-direction-rtl(left,96%);
                bottom: 15px;
                font-size: 36px;
                background: white;
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }

    /* cue-list */
    .cue-list, .color-list, .order-list-main{
        box-shadow: var(--box-shadow-2);
    }
    .MuiListItemText-primary {
        font-family: var(---global-font-family-medium);
    }
    .edit-color-cuepoints {
        input {
            border: 1px solid #cccc;
            outline: none;
            padding: 10px;
            border-radius: 3px;
        }
        svg {
            cursor: pointer;
            margin: 0  5px
        }

    }
    .list-fixed {
        max-height: 240px;
        overflow: auto;
    }
    .add-cue-span {
        position: relative;
    }
    .add-cue {
        position: relative;
        // left: 95%;  
        @include switch-direction-rtl(left,95%);
        bottom: -15px;
        cursor: pointer;
        font-size: 30px;
    }
    .editable-cue {
        display: none;
        &.show-edit-cue {
            display: block;
        }
    }
    .input-add-cue {
        margin: 25px 0;
        display: none;
        &.show-add-cue {
            display: block;
        }
        input {
            width: 80%;
            border: 1px solid #cccc;
            outline: none;
            padding: 10px;
            border-radius: 3px;
        }
        svg {
            cursor: pointer;
            margin: 0  5px
        }
    }
    .doc-view-prop-container{
        .public-url-switch-btn{
            display: flex !important;
            // flex-direction: row-reverse;
            .MuiTypography-body1{
                font-weight: 700 !important;
            }
        }
        .txtpos-layout{
            width: 100%;
            .layout-select{
                width: 40%;
                max-width: 300px;
                flex: 0 1 40%;
                .custom-select-container{
                    width: 100%;
                }
            }
            .txtpos-radio{
                width: 40%;
                flex: 0 1 40%;
            }
        }

       .custom-select-label{//.custom-select-label
            margin: 10px 0;
         }

        .docview-txt-position-radio-label{
            margin-bottom: 16px;
            font-weight: 600;
        }
        .layout-select{
        }
    }
}
.doc-view-layout-select-option{
    display: flex !important;
}
.property-action-info {
    margin-top: 15px;
    text-align: right;
    // background: #80808014;
    // border: 1px solid black;
    border-bottom: none;
    border-left: none;
    border-right: none;
    // border-top: 1px solid #cecece;
    padding: 10px;
    margin-bottom: -25px;
    button {
        margin: 5px 10px;
    }
    .submit-btn {
        
        color: #ffffff;
        background: #000000;
        &:hover{
            background:#198754; ;
        }
        
    }
    .submit-btn:disabled {
        color: #b9b8b8;
        background: #dbdbdb;
    }
}

/* chrome scrollbar */
::-webkit-scrollbar {
    height:12px;
    width:12px;
    background: #ddd;
}

::-webkit-scrollbar-thumb {
    background: var(--scrollbar-primary-1);
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
    background: var(--scrollbar-primary-1);
}

/* Audio Panel */
.audio-prop-container{
    .audio-section{
        width: 100%; 
        margin:0;
        margin-bottom: 10px;
       .audio-container{
        margin-left: -35px;
       .rhap_progress-filled,.rhap_progress-indicator{background-color: #000 !important;}
       }
       .back-10{
        margin-left: 60px;
        width:30px;
       }
       .front-10{
        width: 30px;
       }
       .backword-icon{
        margin-left: 40px;
        width: 30px;
       }
    }
    .optional-listening{
        .MuiCheckbox-root{
          color: #000;
          margin-left: 10px;
        }
    //    margin-top: 10px;
    }
    
}

/* knowledge-check */
.knowledge-check-prop{
    .answer-chk{
        .MuiOutlinedInput-root{
            color: #fff;
            }
        }
        .correct-answer,.delete-option{
            cursor: pointer;
            color: #fff;
        }
        .correct-chk-answer{
            color: #000;
        }
        .correct-chk{
            color: #fff;
        }
        .wrong-answer{
            color:var(--bs-gray-500)
        }
        .wrong-chk-answer{
            color:var(--bs-gray-700)
        }
    .ck-check-box{padding:8 px;}
    .add-new-question{
        font-size: 14px;
        font-weight: bold;
        position: relative;
        bottom: -20px;
        // left: 20px;
        @include switch-direction-rtl(left,20px);
        cursor: pointer;
        .add-accordion,.add-card,.add-tabs {
            // left: 97%;
            // bottom: 20px;
            font-size: 32px !important;
            color: #f5f5f5;
            background: #000;
            border-radius: 50%;
        }
    }
    .Quiz-Bank{
        padding: 10px 20px;
        color: white;
        border-radius: 5px;
        background-color: black;
        position: relative;
        bottom: 15px;
        // left: 70%;
        @include switch-direction-rtl(left,70%);
        // right: 0px;
        @include switch-direction-rtl(right, 0px);
        cursor: pointer;
    }
    }

    .w-100{
        width: 100% !important;
    }
    .h-100{
        height: 100% !important;
    }
/***  QuizzesPanel ***/
.quizzes-panel-prop,.knowledge-check-prop {
    .quiz-config-container .clear-quiz{
        margin-top: unset;
    }
    &.edit-area{
        border: 2px solid var(--border-color);
        border-radius: 4px;
        padding: 16px !important;
    }
    .quiz-summary{
         &.disable-quiz {
            *{cursor: not-allowed !important;}
          }
          .edit-area{
            display: flex;
            align-items:baseline;
            justify-content: space-between;
          }
        .options-list{
            display: flex;
            flex-direction: column;
            .option-container{
                display: flex;
                padding: 6px 0;
                svg{
                    // margin:0 6px;
                    &.option-ans{
                        margin-right:6px;
                        &.correct-ans{
                        background: var(--primary-color-1);
                        color: #fff;
                        border-radius: 50%;
                        }
                    }
                    &.option-delete{
                        margin-left:6px;
                    }
                    font-size: 24px;
                    cursor: pointer;
                }
            }
        }
        .option-error{
            color: var(--bs-danger);
        }
        .add-quiz-option{
            font-size: 14px;
            font-weight: bold;
            position: relative;
            cursor: pointer;
            .add-accordion{
                font-size: 25px !important;
                color: #f5f5f5;
                background: #000;
                border-radius: 50%;
            }
        }
    }
    .MuiOutlinedInput-inputMultiline{
        padding:0px !important; 
    }
.quiz-actions{
    display: flex;
    justify-content: space-between;
}
// configuration *****************

// ******************


    .radio-chk{
        margin:0 !important;
    }
    .option-edit {
        width: 100%;
        background-color: var(--bs-gray-700) !important;
        color: #fff;

        .MuiInputBase-input {
            color: #fff;
            margin-left: 10px;
        }

        .input-option {
            cursor: pointer;
        }
    }

    .option-list {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .option {
            display: flex;
            color: #fff;

            .option-text {
                margin-left: 10px;
                width: 100% !important;
            }
        }

        .delete-prop-card-answer {
            color: #fff;
            margin-right: 10px;
            // left: 94%;
            @include switch-direction-rtl(left,94%);
            
        }
    }
}

.circle-preview-img-box{
    border-radius: 50%;
    border: 3px solid #fff;
    margin-right:14px;
    height: 41px;
    width: 41px;
    .preview-item-img{
        height: 35px;
        width: 35px;
        border-radius: 50%;
    }
}

/*  dragging start */
.dragging-prop-container, .scenario-block-prop-container{
    .info-box{
        padding:20px 12px;
        .title-label{
            display: flex;
            justify-content: space-between;
            .close-explanation{
                margin-right: 15px;
                font-weight: bold;
                cursor: pointer;
                color: #000;
                padding: 4px 7px;
                font-size: 12px !important;
            }
        }
    }.draggable-global-property{
        padding:5px 16px;
        .draggable-heading{
            width: 100%;
            min-height: 40px;
            padding-top: 8px;
            font-size:18px;
        }
        .label-check{
            display: flex;
            justify-content: space-between;
            .MuiButtonBase-root{
                padding-left: 5px;
                svg{
                    font-size: 24px;
                    path{color: #000;}
                }
            }
            .MuiFormControlLabel-label{
                font-weight:bold;
            }
        }
    }
    .inner-accordion-continuer{
        .MuiAccordionSummary-content{
            overflow: auto;
        }
        .drag-text{
            margin: 0 6px;
            width: 63% !important;
            margin-top:14px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .drag-target{
            min-width:55px !important;
            margin-top: 11px;
            .MuiSelect-select{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 98px;
                // border: 1px solid var(--bs-gray-500) !important;
            }
            .label.MuiFormLabel-root,.Mui-focused,.target-label{
                color: #ffffff !important;
                // margin-top: -8px;
                background: #495057 !important;
            }
            .target-label{
                margin-top: -4px;
            }
            .MuiSvgIcon-root,.MuiSelect-select{
              color: #fff;
            }
            .MuiInputBase-formControl{
                margin-top:0 !important;
            }
            .css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before{
                border-bottom: 1px solid #fff;
            }
        }
    }
    .add-box{
        display: flex;
        justify-content: space-between;
        .explanation{
            font-size: 14px;
            font-weight: bold;
            position: relative;
            bottom: -20px;
            // right: 15px;
            @include switch-direction-rtl(right,15px);
            cursor: pointer;
        }
    }
}
// drag description and color label
.dragging-prop-container{
    .label{
        align-self:end;
    }
  .drag-des-col-label{
    display: flex;
    justify-content: space-between;
    .MuiTextField-root{
        margin-bottom: 8px;
        margin-right: 30px;
        label{
            transform:translate(14px, -4px) scale(0.75) !important;
            font-size:1rem;
            color: #000;
        }
        .MuiOutlinedInput-root{
            width: 100px;
            input{
                padding: 9.5px 14px !important;   
                 cursor: pointer;
                }
        }
    }
  }
}

.select-target-option-list {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 124px;
    display: block !important;
    padding: 8px !important;
}

/* Common Small Uploader */
.small-uploader{
    .upload-container .file-name{
        font-size: 8px !important;
        white-space: nowrap !important;
        text-overflow: ellipsis;
        width: 95% !important;
        overflow: hidden;
        padding-left:5px;
        div{
            font-size: 11px;
        }
        svg{            
            font-size: 20px !important;
          }
    }
    .uploader-panel{
        .upload-summery{
            .drag-drop{
                font-size: 9px !important;
            }
            .drag-drop-summery{
                font-size: 7px !important;
            }
        }
    }

}

/*  dragging end */

/* scenario-block-prop-container */
.v-small-uploader{
    &.character{
        .ck-check-box{
            margin-left:0;
            .MuiFormControlLabel-label {
                padding: 8px;
            }
        }
    }
    .drag-drop{
        font-size: 7px !important;
    }
    .drag-drop-summery{
        font-size: 5px !important;
    }
    .file-name{
        font-size:10px !important;
    }
}

.scenario-block-prop-container{
    .info-text{
      font-weight: 600;
      margin: 0;
      padding: 0 16px;
      color: var(--bs-danger);
    }
   .header-action{
    display: flex;
    flex-wrap: wrap; 
    // justify-content: end;
    padding: 8px 8px 8px 16px;
    gap:18px;
    .item{
        flex: 0 0 49%;
    }
    
    .check-box-container{
        display: flex;
        justify-content: end;
        align-items: baseline;
    }
   }
   .track-ignore{
    padding: 16px;
   }
    .sc-text-bgcolor{
        height: 100%;
    }

    .MuiAccordionSummary-root { P{ padding-top: 8px;} }

    .text-area-property,.v-small-uploader.character{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .text-area-property-bg-holder{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .slide-label{
        display: flex;
        justify-content: space-between;
    }
    .end_scene_editor{ 
        label{margin-left:4px !important;} 
      }
    .char-check .ck-check-box{
        padding: 27px 16px 9px 16px;
        .MuiFormControlLabel-label{
            padding-left: 0px !important;
            padding-right:5px;
         }
    }

    .inner-accordion-continuer{
    .inner-title{
        width:72% !important;
    }
   .d-flex{ 
        .select-label{
            display:block;
            font-weight: bold;
            margin: 0 !important;
            padding-left: 0 !important;
            white-space: nowrap;
        }
        justify-content: space-between;
        padding:4px  0 !important;
    }
    .group-select{
        border-radius: 4px;
        cursor: pointer;
        height: 1.4375em;
        margin: 0px;
        -webkit-tap-highlight-color: transparent;
        min-width: 0px;
        width: 100%;
        padding: 0px 10px;
        min-height: 37px;
        &.disabled{
            background: var(--border-color);
        }
    }
    .response-container{
        .MuiAccordionSummary-root{
            background-color:#000 !important;
         }
    }
    }
}
    .clm-g-10{
        column-gap: 10px;    
    }
    .w-49{
      width: 49%;
    }
.btn-group{
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    .btn-g{
        min-width: 100px;
        color: #000;
        border-color: 1px #000;
        border: none !important;
        margin-right: 1px;
        background-color: #e1e5e8;
        min-height: 40px;
        max-height: 120px;
        border-radius: 3px;
        &.active{
            background-color: #000;
            color: #fff;
            font-weight: bold;
        }
    }
}

