.acccordion-item-list{
  padding: 0 8px !important;

.co-accordion-item {
    transition: transform 0.3s ease;
    position: relative;
    
    &:hover {
      transform: scale(1.025); 
    }
  
    &.dragging {
      background-color: #f0f0f0;
      cursor: move !important;
      z-index: 999999999;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important;
      border-radius: 8px;
  
      .MuiAccordion-root {
        opacity: 1;
        cursor: move !important;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important;
        background-color: #000;
        border-radius: 8px;
      }

    }
  }
}