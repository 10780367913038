.custom-ce-text-box{
    &.MuiTextField-root {
        margin: 5px 5px 5px 0;
    }
    input{
     padding: 15.5px 14px !important;
    }
    .MuiOutlinedInput-root {
        background-color: var(--bs-gray-200) !important;
    }
    label.MuiFormLabel-root{
      background-color:  transparent !important;
    }
}