@import "../../theme/mixins.scss";
.dashboard-container {
    padding: 0 15px;
    max-width: 1366px;
    margin:0 auto;
    .dashboard-main {
       & > h3 {
            margin-top: 20px;
        }
        .badge-container {
            align-items: center;
        }
        .icon-side {
            text-align: center;
            svg {
                font-size: 3.5rem;
            }
        }
    }
    .profile-header {
        position: relative;
        padding: 15px;
        background: #F0F0F0;
        border-radius: 10px;
        .profile-title {
            margin-bottom: 15px;
        }
        .profile-welcome {
            font-weight: 700;
        }
        .close-icon {
            position: absolute;
            top:10px;
            // right:10px;
            @include switch-direction-rtl(right,10px);
            svg {
                font-size: 30px;
            }
        }
    }

    .courses-card {
        .flipcard-main-container {
            width: 95%;
        }
    }
}