@import '../../../theme/mixins.scss';

.toolbar-container {
    padding: 2px 0 0 10px;
    .toolbar-comp-name {
        font-weight: 600;
        margin-top:15px;
        p {
            display: inline-block;
            &.toolbar-comp-title {
                padding-right: 2rem;
                text-transform: uppercase;
            }
        }
        .comp-id-text {
            font-weight: normal;
        }
    }
    .toolbar-status {
        display: flex;
        justify-content: end;
        flex-wrap: wrap;
        @include for-tablet-up {
            justify-content: start;
        }
        @include for-mobile-medium-large {
           display: initial;
        }
        label {
            color: var(--color-gray-3);
            display: block;
            font-size: 12px;
            margin-left: 5px;
        }
        .save-btn{
            margin-right: 25px;
        .submit-btn {
            color: #ffffff;
            background: var(--color-gray-3);
            padding: 4px 6px;
            margin-top: 4px;
            .MuiButton-label{
                align-items: center !important;
                justify-content: center !important;
            }
        }
    }
    }

    .clone-label-btn {
        text-align: right;
        .clone-switch-btn, .clone-save-btn {
            margin-top: 8px;
        }
    }

    .toolbar-dropdown {
        margin-right: 25px;
        select {
            word-wrap: normal;
            border: 0;
            outline: none;
            padding-bottom: 3px;
            cursor: pointer;
        }
    }
    .settings-panel {
        display: flex;
        @include for-mobile-medium-large {
            margin-left: -28px;
            margin-top: 5px;
        }
        .icons {
            margin: 0 15px;
            cursor: pointer;
            .label-text {
                color: var(--color-gray-3);
                font-size: 12px;
            }
            .action-btn {
                color: var(--color-gray-3);
                display: flex;
                justify-content: center;
            }
        }
    }
}

.warning-popup{
//sweet-alert
.sweet-alert{
    h2{
        font-size: 1.4rem !important;
        color: #000 !important;
        margin: 0 !important;
        .sweet-title{
            font-size: 1.4rem !important;
            color: #000 !important;
            margin: 0 !important;
        }
    }
    div:first-child{
        width:36px !important;
        height:36px !important;
        span:first-child{
            height: 14px !important;
            top:6px !important;
        }
        span:nth-child(2){
            bottom:6px !important;
        }
    }
}
}
.warning-popup{
    .btn-warning{
        color: #fff;
        background-color:var(--primary-color-1)!important;
    }
}