@import '../../../theme/mixins.scss';
.overlay-container {
  font-family:var( --global-font-family-medium) ;
    #overlay {
        width: 100%;
        height: 100vh;
        position: fixed;
        // left: 0;
        @include switch-direction-rtl(left,0);
        top: 0;
        background: rgba(0,0,0,.7);
        z-index: 100;
        display: none;
        transition: display 4s ease-in-out 0s;
        z-index: 999999;
        &.open {
          display: block;
          transform: translateX(0)
        }
      }
      #drawer-menu {
        width: 0;
        box-shadow: var(--box-shadow-2);
        height: 100%;
        background: #fff;
        position: fixed;
        // right: 0;
        @include switch-direction-rtl(right,0);
        top: 0;
        z-index: 99999999;
        transform: translateX(100%);
        transition: all 0.3s ease 0s;
          &.open {
            transform: translateX(0);
          }
          @include for-laptop-up {
            width: 100% !important;
          }
      }
      .ce-drawer-header {
        padding: 15px 25px 15px 25px;
        display: flex;
        justify-content: space-between;
            .ce-drawer-text-header {
                text-transform: capitalize;
                font-weight: bold;
            }
        }
        .ce-notes-main-ui.updates {
            overflow: hidden auto;
            height: 90%;
            &::-webkit-scrollbar {
              width: 8px;
            }
            
          /* Track */
          &::-webkit-scrollbar-track {
          background: #f1f1f1; 
          }
             
          /* Handle */
          &::-webkit-scrollbar-thumb {
          background: var(--scrollbar-primary-1); 
          }
        }
        .ce-notes-main-ui.CourseMap, .ce-notes-main-ui.Certificate {
          overflow: hidden auto;
          height: 90%;
        }
        .ce-drawer-close-icon {
          background-color: transparent;
          border: none;
            svg {
                font-size: 30px;
                cursor: pointer;
            }
        }
}
