@import "../../../theme/mixins.scss";
.main-dragging-container{
    padding: 25px 35px;
    background: #ffff;
    border-radius: 5px;
    // box-shadow: 0 2px 16px 4px#282C3F;
    .dragging-title{
        font-size: 24px;
        white-space: pre-wrap;
        font-size: var(--global-heading-font-size);
        display: flex;
        justify-content: start;
        font-weight: bold;
    }
    .dragging-title,.dragging-result{
        font-weight: bolder;
        color: #000;
        text-align: center;
        font-weight: 600;
        &.dragging-result{
            font-size: var(--global-font-size);
            display: flex;
            justify-content: start;
            .right-ans{
                padding: 3px 12px;
                background: var(--color-green-6);
                border-radius: 4px;
                color: var(--primary-color-1);
            }
            .wrong-ans{
                color: var(--bs-danger);
                background: var(--ce-highlighter-color-light);
                padding: 4px 12px;
                border-radius: 3px;
            }
            .wrong-box{
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                align-items: center;
            }
        }
    }
    .drop-audio{
        .required-audio{
            padding: 0;
            color: var(--bs-danger);
            font-weight: bold;
        }
        .rhap_container{
            padding: 0 !important;
        }
        .rhap_stacked{
            display:none!important;
        }
    }

    .item{
        box-shadow: var(--box-shadow-1);
        border-radius: 10px;
        margin: 15px 10px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 200px;
        min-width: 150px;
        min-height: 130px;
        overflow: hidden;
        &.wrong-ans{
            color: var(--bs-danger);
            font-weight: bold;
            border: 2px solid var(--bs-danger);
            box-shadow: none;
            transform: translateY(8px);
            @include shake(); 
        
        }
        .drag-item-text{
            margin-top: 10px;
            font-size: var(--global-font-size);
            line-height:var(--global-line-height);
            max-width: 100%;
            overflow-wrap: break-word;
        }
        .image-box{
            display: flex;
            height: 100px;
            justify-content: center;
            .drag-item-img{
                height: 100%;
                object-fit: contain;
                position: relative;
                width: 160px;
                border-radius: 10px;
                // border:3px solid var(--color-gray-6);
            }
            
        }
       }
    .single-drop{
        .droptarget{
            width: 100% !important;
        }
    }
    .dragging-container{
         .drag-items-container{
            max-height: 400px;
            overflow-y: auto;
           padding: 16px;
           display: flex;
           align-items: start;
           flex-wrap: wrap;
           justify-content: center;
           column-gap: 18px;
           padding-bottom: 0 !important;
           margin-bottom: 16px !important;
         }
         .drop-continuer{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            .droptarget{
                width: 50%; 
                @media (max-width: 991px) {
                    width: 100% !important; 
                  }
                }
            .drop-items-container{
                max-height: 350px;
                overflow-y: auto;
                border-radius: 10px;
                border: 2px dashed #6e6e6e;
                margin: 10px;
                .dropped-items{
                    display: flex;
                    flex-wrap: wrap;
                }
                .drop-box{
                    display: flex;
                    min-height:80px;
                    align-items: center;
                    justify-content: center;
                    padding: 16px;
                    font-size: var(--global-font-size);
                    line-height:var(--global-line-height);
                    &.drop-items{
                        min-height: 20px !important;
                        margin: 10px;
                        font-weight: bold;
                    }
                }
            }
         }
    }
}
._mobile{
    .main-dragging-container{
        .droptarget{
            width: 100% !important;
        }
    }
}
.fade{
    opacity: 0;
    transform: translateY(10px);
    @include fadeIn(); 
}