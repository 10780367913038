// custom theme styles

/*
*
* Skill Theme
*
*/
.skill_theme{
    .crs-menu{
        .tp-text-area,.acc-node .title{
          font-size: 16px !important; 
        }
    }
    //lrn styles
    section[name="Video"],section[name="ArchiveVideo"]{
      .vjs-control-bar{
        background-color: var(--primary-color-1) !important;
        .vjs-play-progress{
          background-color:var(--completion-color) !important;
        }
        .vjs-progress-holder{
          height: 6px !important;
        }
        .add-video-sub{
          background-color: var(--primary-color-1) !important;
        }
      }
      .vjs-big-play-button{
        background:var(--primary-color-1) !important;
      }
    }
}
/* ***************** */