.case-study-main {
    padding: 25px 35px;
    background: #ffff;
    border-radius: 5px;
    box-shadow: 0 2px 16px 4px RGB(40 44 63/5%);
    .continue-btn {
        text-align: right;
    }
    .title {
        color:var(--primary-color-1);
        padding-bottom: 15px;
    }
    .case-study-title {
        color:var(--primary-color-1);
        font-weight: 600;
        padding-bottom: 15px;
    }
    .answer-select-div {
        margin: 0;
    }
}