section[name=ImageText] {
    // margin-left: 0;
    // margin-right: 0;
        .image-div {
            background: RGB(65, 65, 65);
            height: 100%;
            box-sizing: content-box;
            overflow: hidden;
            .text-section {
                position: relative;
                a {
                    cursor: pointer;
                }
            }
            .img-text {
                margin: 0 10%;
            }
            .img-section {
                padding-right: 0;
            }
            .row {
                height: inherit;
                margin:0;
                .img-section {
                    padding: 0;
                }
            }
            .image-main {
                height: 100%;
            }
        }
        // .image-text-inner{margin:0;}
}
.row-reverse{
    display: flex;
    flex-direction: row-reverse;
    // padding-left: 6px;
}
.custom-text-position{
    display: flex;
    padding: 10px;
    .text-content{
        font-size: var(--global-font-size);
        line-height:var(--global-line-height);
    }
}
//center align
.center-img{
    .img-section {
        padding-right: 0 !important;
        padding-left: 0 !important;
        display: flex;
        justify-content: center;
        .image-main {
            width: 60% !important;
        }
    }
     .iyCOGh{
     padding:5% !important;
    }
}