//menu open time content animation 
@keyframes make1_transition {
    from {width:100%; } to {width:70%;}
}
@keyframes make2_transition {
    from {width:70%; } to {width:100%;}
}
@keyframes fadeIn {
    from {opacity: 0;display: none;}
    to {opacity: 1; display: block;}
  }
  
  @keyframes fadeOut {
    from {opacity: 1;display: block;}
    to {opacity: 0;display: none;}
  }

.skill-home-container{
    margin: 0;
    padding: 0;
    background-color: #002873;
    font-family: var(--global-font-family-medium);
    display: flex;
    justify-content: center;
    height: 100vh;
    animation-name: make2_transition;
    animation-duration: 1.8s;
    width: 100%;
    overflow: hidden;
    color: #fff;
    .crs-menu-icon{
        position: absolute;
        right: 16px;
        top: 16px;
        font-size: 50px;
        color: var(--primary-color-2);
        cursor: pointer;
        z-index: 1;
    }
    &.half-resume{
        animation-name: make1_transition;
        animation-duration: 1.8s;
        width:70%;
    }
    .crs-structure{
        position: absolute;
        display: none !important;
        right: 0;
        top: 0;
        height: 100%;
        background-color: #fff;
        animation: fadeOut 1.8s forwards;
    }
    
    .open-structure{
        display: flex !important;
        width: 30%;
        height: 100%;
        transition: width 0.3s ease-out;
        animation: fadeIn 1.8s forwards;
        .crs-menu{
            width: 100%;
            padding: 16px;
            color: #000 !important;
            overflow-y: auto;
            .lesson-progress{
                display: none;
            }
        }
    }
    .skill-container{
        padding: 32px 40px !important;
        max-width: 1320px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        .course-detail{
            width: 50%;
            min-width: 500px;
            .crs-name{
                font-size: 50px;
                font-weight: bolder;

            }
            .crs{
                color: #fff;
                padding: 5px 15px;
                font-size: 18px;
                font-weight: 500;
                border: 2px solid #fff;
                border-radius: 50px;
                display: inline-block;
                font-weight:bold
                
            }
        }
        .image-container{
            width: 50%;
            min-width: 350px;
            img{width: 100%;}
        }
    }
    .splash-select{
        background-color: #fff;
    }
    .nav-container{
        position: absolute;
        width: 100%;
    }
    &.language-splash-container{
        background-color: #fff !important;
        color: #000 !important;
    }
}

