@import "../../../theme/mixins.scss";
.content-list-container {
    z-index: 99;
    pointer-events: initial;
    .content-list-main {
        .content-pop-icon {
            text-align: right;
            img {
                width: 60px;
            }
            .add-cancel-icon{
                img {
                    width:70px;
                    margin-bottom:-10px;
                }
            }
        }
        .add-circle-icon, .add-cancel-icon {
            cursor: pointer;
        }
        .content-component-list {
            text-transform: uppercase;
            width: 220px;
            box-shadow: var(--box-shadow-2);
            border-radius: 5px;
            max-height: 398px;
            overflow: hidden auto;
            animation: expand .2s ease-in-out;
            transition: all 0.4s ease 0s;
            position: absolute;
            top:-363px;
            // right:19px;
            @include switch-direction-rtl(right,19px);
            z-index: -1;
            .MuiListItem-gutters{
               padding-top:7px !important;
               padding-bottom: 7px !important;
            }
            .add-Widgets-title{
                position: sticky;
                color: #00a84f;
                font-weight: bold;
                top: 0px;
                background-color: #fff;
                padding: 2px;
                z-index: 999;
                border: var(--border-style-1);    
            }
            @keyframes expand {
                0% {
                transform: scale(0);
                opacity: 0;
                }
                100% {
                    transform: scale(1);
                    opacity: 1;
                }
            }
            &::-webkit-scrollbar {
                width:8px;
              }
              
            /* Track */
            &::-webkit-scrollbar-track {
            background: transparent ; 
            }
               
            /* Handle */
            &::-webkit-scrollbar-thumb {
            // background: var(--color-gray-3);
            background-color:var(--scrollbar-primary-1); 
            }
            .MuiList-padding {
                padding:0;
                border-radius: 3px;
            }
            .add-comp-list-icon {
                width: 18px;
                margin-right: 10px;
            }
            .list-label-text {
                span {
                    font-size: 12px;
                    color: var(--bs-gray);
                    font-family: var(--font-roboto-regular) !important;
                }
            }
            &.list-hidden {
                transition-delay: 0.5s;
                display: none;
            }
        }
    }
}
.arrows{
    display: flex;
    position: relative;
    bottom: 31px;
    // right: 55px;
    @include switch-direction-rtl(right, 55px);
    color:gray;
    border: 1px solid black;
    border-radius: 25px;
    background-color: white;
    z-index: 1;
    padding: 5px 10px;
    .arrow-upward,.arrow-downward{
        cursor: pointer;
        padding:0 5px;
        &:hover{
            color: black;
        }
    }

}
