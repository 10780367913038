@import '../../../theme/mixins.scss';

.cardflip-main-container {
    // width: 350px;
    padding: 15px;
    margin: 0 auto;
    .row {
        justify-content: space-evenly;
    }

    .react-card-flipper {
        // max-height: 240px;
        min-height: 240px;
    }
    .react-card-flip {
        min-width: 30%;
        // max-width: 250px;
        padding: 10px 0;
        cursor: pointer;
        @include for-tablet-up {
            max-width: 100%;
            padding: 10px 10px;
        }
    }
    .react-card-front, .react-card-back {
        box-shadow: var(--box-shadow-2);
        background-color: var(--color-white);
        border-radius: 5px;
        max-height: 450px;
        min-height: 450px;
        overflow:hidden auto;
    }
    .flip-img-tag {
        width: 100%;
        border-top-right-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 250px;
        overflow: hidden;
        img{
            border-top-right-radius: 10px;
            max-height: 250px;
            width: 100%;
        }
    }
    .flip-title-section {
        margin: 15px;
        .border-line {
            width: 20%;
            border-top: 2px solid var(--primary-color-1)
        }
        .flip-title {
            font-weight: bolder;
        }
    }
    .more-btn, .backSideBtn {
        text-align: center;
        margin: 10px;
        padding: 10px;
        // border-top: 1.5px solid #cccc;
        button {
            color:var(--primary-color-1);
            background: none;
            border: none;
        }
    }
    .back-content {
        padding: 20px 25px;
        overflow-y: auto;
        max-height: 410px;
        font-size: var(--global-font-size);
        line-height:var(--global-line-height);
        .title-heading {
            font-size: 18px;
            font-family:var(--global-font-family-medium);
            margin-bottom: 10px;
        }
    }

    .flip-image {
        .middle-content-title {
            padding: 20px 20px 15px 20px;
            min-height: 155px;
            .main-title {
                font-weight: 600;
                font-size: 20px;
                line-height:var(--global-line-height);
            }
            .para-line {
                border-top: 1px solid var(--primary-color-1);
                width: 15%;
            }
        }
        .flip-next {
            border: 0;
            position: absolute;
            bottom: 0;
            background: #ffffff;
            color: var(--primary-color-1);
            padding: 10px 0;
            width: 90%;
            margin:0 15px;
            border-top: 1px solid #d3d3d3;
            svg {
                margin-right: 5px;
            }
        }
    }

    .back {
        .backside-flip-container {
            min-height: 240px;
            .flip-back-text {
                .row {
                    padding-top: 10px;
                    &.row_flex {
                        height: 372px;
                    }
                    .topics {
                        font-size: 12px;
                        margin-bottom: 7px;
                        text-align: center;
                        @include for-tablet-up {
                            margin-bottom: 0;
                        }
                        .field {
                            margin-bottom: .5rem;
                        }
                    }
                }
            }
        }
        .field {
            color: #878181cc;
        }
        .title {
            font-weight: bold;
            @include for-tablet-up {
                margin-bottom: 0.7rem;
            }
        }

        .flip-back {
            border: 0;
            position: absolute;
            bottom: 0;
            background: #ffffff;
            color:var(--primary-color-1);
            padding: 10px 0;
            width: 90%;
            margin:0 15px;
            border-top: 1px solid #d3d3d3;
            svg {
                margin-right: 5px;
            }
        }
    }
}