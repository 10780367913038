@import '../../../theme/mixins.scss';
section[name='ArchiveVideo'] {
    position: relative;
}
.video-container{
    // margin: auto;
    margin: auto;
    // border:3px solid red;
    @include for-tablet-up {
        // height: 350px;
    }
    .vjs-poster{
        margin: 2px !important;
        height: 99.5% !important;
    }

    @include for-mobile-medium-large {
        // height: 300px;
    }
    .vjs-big-play-button {
        width: 3em !important;
        height: 3em;
        border-radius: 50%;
        @include for-mobile-medium-large {
            width: 2em !important;
            height: 2em;
        }
        span {
            &::before {
                font-size: 55px;
                top: 23px !important;
                @include for-mobile-medium-large {
                    font-size: 30px;
                    top:7px !important;
                }
            }
        }
    }

    div.video-js .vjs-play-progress {
        background: red;
    }
}
.video-js{
    width:100%;
    height:100%;
    .vjs-big-play-button {
        font-size: 3em !important;
        position: absolute;
        top:45%;
        // left:45%;
        @include switch-direction-rtl(left,45%);
        @include for-mobile-medium-large {
            top: 34%;
            // left: 40%;
            @include switch-direction-rtl(left,40%);
        }
    }
    .vjs-control-bar {
        height: 4em;
        .vjs-play-control {
            .vjs-icon-placeholder {
                &:before {
                    font-size: 27px !important;
                }
            }
        }
    }

    .vjs-icon-fullscreen-enter:before, .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
        padding-top:5px !important;
    }

    .vjs-fullscreen-control.vjs-button > .vjs-icon-placeholder:before {
        padding-top: 3px;
        font-size: 22px;
    }
    .vjs-time-control {
        padding-top:6px;
    }

    .vjs-playback-rate > .vjs-menu-button, .vjs-playback-rate .vjs-playback-rate-value {
        padding-top: 5px;
    }
}

.readable-text {
    padding: 0;
    margin: 20px 0;
    font-size: var(--global-font-size);
    line-height:var( --global-line-height);
    .fr-view {
        border-bottom: .5px solid #cccc;
        padding-bottom: 2rem;
    }
    @include for-tablet-up {
        padding: 0;
        margin: 20px 1rem;
    }
}