.print-course-container{
    padding: 10px 0;
    font-family: var(--global-font-family-medium) !important;
    .top-print-row {
        padding-bottom: 30px;
        border-bottom: 1px solid #cccc;
        .logo-container {
            padding-left: 0;
            .comp-logo{
                img{
                    min-height: 25px;
                }
            }
        }
        .text-right {
            text-align: right;
            @media (max-width: 991px) {
                padding-top: 8px;
            }
            .print-btn {
                color: #ffffff;
                background: var(--primary-color-1);
            }
        }
    }
    .course-print-row {
        margin-top: 15px;
        @media (max-width: 991px) {
           font-size: 14px !important; 
           .course-print-information{
            border-right: none !important;
           }
          }
        .course-print-information {
            border-right: 1px solid #cccc;
        }
        .course-print-topic-info {
           margin-bottom: 20px; 
         @media (max-width: 991px) {
            margin-bottom: 0px !important; 
          }
           & > p {
               margin-bottom: 5px;
               span {
                   font-weight: bold;
               }
           }
        }
        .course-print-overview, .course-print-date {
            p {
                span {
                    font-weight: bold;
                }
            }
        }
        .course-print-date {
            & > p {
                margin-bottom: 5px;
            }
        }

    }
    .course-tree-menu-print {
        margin-top: 15px;
        .print-course-list {
            border-radius: 3px;
            box-shadow: var(--box-shadow-2);
            .MuiListItem-button{ cursor: default !important; }
            .MuiListItem-root.col-md-10 {
                max-width: 83.33333%;
            }
            .MuiListItem-root.col-md-2 {
                max-width: 16.33333%;
            }
        }
    }

}

.print-primary-coursemap {
    max-width: 95%;
    @media (max-width: 991px) {
        font-size: 14px !important; 
        span {
            font-size: 14px !important; 
        }
      }
    span {
          font-family: var(--global-font-family);
    }
}
.container{
    padding: 0px !important;
}
@media print {
    .print-course-container {
       *{ font-family: 'Roboto',sans-serif !important;}
        margin: 25px 15px 15px 15px;
        .MuiListItem-button{
            font-size: 9pt !important; 
        }
        .print-primary-coursemap{
            span{
                font-size: 10pt !important; 
                max-width: 95%;
            }
        }
    }
    .container{
        margin: 0px !important;
    }
    .noPrint {
        display: none;
    }
    .course-print-row {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        margin: 15px 12px;
        .course-print-information {
            border-right: 1px solid #cccc;
            flex: 0 0 auto;
            width: 58.33333%;
        }
        .course-print-topic {
            flex: 0 0 auto;
            width: 41.66667%;
        }
        .course-print-topic-info {
           margin-bottom: 20px; 
           & > p {
               margin-bottom: 5px;
               span {
                   font-weight: bold;
               }
           }
        }
        .course-print-overview, .course-print-date {
            p {
                span {
                    font-weight: bold;
                }
            }
        }
        .course-print-date {
            & > p {
                margin-bottom: 5px;
            }
        }

    }

    .course-tree-menu-print {
        margin-top: 15px;
        .print-course-list {
            .MuiListItem-root.col-md-10 {
                max-width: 83.33333%;
            }
            .MuiListItem-root.col-md-2 {
                max-width: 16.33333%;
            }
        }
    }
}