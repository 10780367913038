@import "../../../theme/mixins.scss";

@mixin columdirection(){
   .quiz-box{
        flex-direction: column;
        row-gap: 10px;
       .img{
           max-width:100% !important;
       }
       .question-box{
          max-width: 100% !important;
       }
   }
}

.paper-data {
    // padding: 25px 35px 30px 35px;
    background: #ffff;
    border-radius: 5px;
    width: 100%;
    box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.05);
    @include for-ipad-pro-devices {
         @include columdirection();
      }
    .quiz-box{
        display: flex;
        column-gap: 20px;
        .img{
            max-width:35%;
            align-items: center;
            justify-content: center;
            display: flex;
            padding:10px;
            img{width: 100%;}
        }
        .question-box{
            width: 70%;
        }
    }

    @include for-tablet-up {
        margin: 0;
    }
    .text-content,.MuiTypography-root{
        font-size: var(--global-font-size);
        line-height:var(--global-line-height);
    }
    .title {
        padding: 4px 4px 8px 1px;
        color: var(--color-black-3);
        font-family: var(--global-font-family);
        // text-align: justify;
    }
    .true_false{ text-transform:capitalize !important; }

    .pagination_Numbers {
        float: left;
        margin-left: -10px;
        @include for-laptop-up{
            width: 50%;
            display: flex;
            flex-wrap: wrap;
        }

        .Mui-selected,
        .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root:hover {
            border-radius: 50px;
        }
    }

    .group-selection{
        padding: 11px;
    }
    .bottom{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .buttons {
        justify-content: flex-end;
        display: flex;
        margin-top: 30px;
        padding-left: 11px;
        @mixin btn {
            padding: 5px 16px;
            border-radius: 5px;
            // padding-left: 12px;
        }
       button{
        margin:0 5px;
       }
    }

    .complete_text {
        padding-bottom: 100px;
    }

    .response-main {
        background: #f8f8f8;
        padding: 10px;
        margin-top: 15px;
        border-radius: 10px;
    }

    .radio_multi {
        margin-top: 7px !important;
        padding: 0px !important;
        display: flex;
        align-items: baseline;
    }

    .radio {
        margin-right: 7px !important;
        padding: 0px !important;
    }
    .radio_multi {
        .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
            color: var(--primary-color-1) !important;
        }
    }
    .pagination {
        .Mui-selected {
            background-color: var(--primary-color-1);
        }
    }
    .img_congratualte{
        display: flex;
        justify-content: center;
        padding: 30px;
    }
    .confetti{
        position: relative;
    }
    .Text_congratulate{
        margin-left: 30px;
        margin-top: 5px;
        @media (max-width: 600px) {
            margin-left: 0px;
         }
        .font_congratulate{
            font-size: 28px;
            font-weight: 900;
        }
        .font_congratulate_1{
            font-size: 26px;
            font-weight: 900;
        }
        .font_congratulate_2{
            font-size: 24px;
            font-weight: 400;
        }
        .font_congratulate_3{
            font-size: 20px;
            font-weight: 400;
        }
        .font_congratulate_4{
            font-size: 18px;
            font-weight: 400;
        }
    }
}

._tablet,._mobile{.paper-data  {@include columdirection();}  }