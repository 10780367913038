section[name=Quotes]{
.snippet-div {
    .snippet-div-main,.snippet-description{
         overflow-y: auto !important;
         max-height: 450px !important;
         ::-webkit-scrollbar{
            border-radius: 5px;
         }
    }
    justify-content: center;
    &.bg-custom-text-position{ min-height: 400px !important;}
    .text-content {
        // display: flex;
        // align-items: center;
        max-height: 300px !important;
        font-size: var(--global-font-size);
        line-height:var(--global-line-height);
        // overflow-y: auto !important;
    }

    .image-box {
        display: flex;
        padding: 10px;
        &.col-lg-2{
         width: 12.5% !important;
        }

        &.center {
            justify-content: center;
        }

        &.square {
            .quotes-profile-img {
                height: 100%;
                width: 100%;
                border-radius: 1px !important;
            }
        }

        .quotes-profile-img {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            border: 3px solid var(--primary-color-1);
            overflow: hidden;

            img {
                // margin-left: -10px;
                height: 100%;
                width: 100%;
            }
        }
    }
}
}