div.vertical-timeline::before {
    background: var(--color-gray-3);
}
.log-history{
    padding: 0 16px;
    .MuiTypography-root{font-family: var(--font-roboto-regular);}
    .MuiTypography-body1 li div{font-size: 14px; font-weight: 300;}

    .lrnaid_col{
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;

    }
    .no-data{
        padding:16px;
        font-size:var(--global-font-size);
    }
}

.flex-item{
    display: flex;
    justify-content:space-between;
    align-items: center;
    background-color: #fff;
    padding:15px 10px;
    border-bottom: 1px solid #ddd;
    .author{
        width: 20%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .update-at,.edit-icon{
        width: 15%;
        text-align: end;
    }
}
.tp-top-head{
    background-color: #fff;
    padding: 7px;
    cursor: pointer;
}
.status-list{
    list-style-type: none;
    padding-left: 0;
    background-color: #fff;
    width:25%;
    position: absolute;
    z-index: 2;
    box-shadow: var(--box-shadow-4);
    &.status-change{
       left: 26%;
    }
    li{
        padding:10px 20px;
        cursor: pointer;
        border-bottom: 1px solid #eee;
        display: flex;
        justify-content: space-between;
    }
    .status-selected{
        color:var(--primary-color-1);
    }
}
.tp-edit{
    cursor: pointer;
}
.select-status{
    border:none;
    outline:none;
    font-weight:300;
    width: 10%;
}