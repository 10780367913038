/* styles.css */
.ck-checkbox-root {
    margin-left: 0 !important;
    & .MuiButtonBase-root svg {
        font-size: 24px;
        & path {
            color: #000;
        }
    }
    .MuiCheckbox-root{
        padding-right:3px;
    }
    & .MuiFormControlLabel-label {
        font-weight: bold;
        margin-left: 0 !important;
    }
    .MuiTypography-root {
        white-space: nowrap;
    }
}
