@import '../../../theme/mixins.scss';

@keyframes fadeIn {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

.drag-and-match-lrn{
    padding: 25px 35px;
    background: #ffff;
    border-radius: 5px;
    font-size: var(--global-font-size);
    .heading-result{
        padding: 16px 0;
        animation: fadeIn 9s ease-in-out;
        .dragging-result{
            display: flex;
            justify-content: center;
            animation: fadeIn 9s ease-in-out;
        }
    }

    .inner-container{
        row-gap:16px;
        .row{  @include for-mobile-large{ row-gap:16px;}} 
        .drag-question-box{
            .drag-question{
                padding:12px;
                box-shadow: var(--box-shadow-2);
                background-color:var(--bs-gray-100);
                height: 100%;
                min-height:100px;
                display: flex;
                .drag-result{
                 min-width:30px;
                 opacity: 1;
                 visibility: hidden;
                 transition: opacity 0.5s ease-in-out;
                 opacity: 0;
                 &.visible{
                    opacity: 1;
                    visibility: visible !important;
                 }
                 img{
                    height:25px;
                    width:25px;
                    margin-top:0px;
                    margin-right: 10px;
                 }
                }
             }
        }
        .drop-items-container{
            box-shadow: var(--box-shadow-5);
            background-color: var(--bs-gray-100);
            border-radius: 10px;
            height: 100%;
            min-height:100px;
            .dropped-items{
                padding: 12px;  animation: fadeIn 5s ease-in-out;
                button{
                    border: none;
                    background: none;
                }
            }
            .drop-box-hide{visibility: hidden;}
            animation: fadeIn 5s ease-in-out;
        }
        .ddcontainer,.ddcontainersource{
            width: 100%;
            height: 100%;
        }
        .drag-item-container-text-box{
            box-shadow:var(--box-shadow-5);
            width: 100%;
            height:100%;
            min-height:100px;
            padding: 12px;
            border-radius: 10px;
            background-color:var(--bs-gray-100);
        }
        .ddcontainerghost{
            max-width: 400px;
            width: 100%;
            @include for-ipad-pro-devices{
                max-width: 300px !important;
            }
            @include for-laptop-up {
                max-width: 200px !important;
            }
            @include for-mobile-small-devices{
                max-width: 100%;
            }
            
        }
    }
}


._labtop{
    .drag-and-match-lrn .ddcontainerghost{
        max-width:300px !important;
    }
}
._tablet{
    .drag-and-match-lrn .ddcontainerghost{
        max-width:200px !important;
    }
}
._mobile{
    .drag-area{
        .row{
            row-gap:16px;
            .col-lg-4{
                width: 100% !important;
            }
        }
    }
}