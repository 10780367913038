@import '../../theme/mixins.scss';
.stacked-container {
    .MuiButtonBase-root {
        position: absolute;
        top: 40%;
        z-index: 10;
    }
    .MuiFab-label{
        svg{
            color: var(--primary-color-1);
        }
    }
    
    .arrow-back {
        left: 0;
    }
    
    .arrow-forward {
        right: 0;
    }

    @include for-mobile-landscape-up {
        .react-stacked-center-carousel-slide--2 {
            left: calc(55% - 225px) !important;
            opacity: 1 !important;
        }
        .react-stacked-center-carousel-slide-2 {
            left: calc(45% - 125px) !important;
            opacity: 1 !important;
        }
    
        .react-stacked-center-carousel-slide--1 {
            left: calc(50% - 325px) !important;
            transform: translateX(-193.791px) scale(0.90) !important
        }
        .react-stacked-center-carousel-slide-1 {
            left: calc(50% - 25px) !important;
            transform: translateX(193.791px) scale(0.90) !important
        }
    }
}

section[name=CardStacker], section[name=CardStackCarousel] {
    @include for-tablet-up {
        margin: 25px 0;
    }
}


