.lrnaid-search-panel{
    // background-color: #ddd;
    margin-top:10px;
    padding-left:10px;
    justify-content: space-between;
    .MuiOutlinedInput-input{padding: 8px!important; width: 80%;}
    label.MuiFormLabel-root{background:   none; font-weight: normal;  margin-top: -7px; }
    .MuiFormLabel-root.Mui-focused{  background: none;  margin-top: 0px;  background: none; }
    .error{color:var(--bs-danger)}
    .MuiOutlinedInput-input{font-size: 14px;
    font-weight: 400;
    background: none;
    font-family: var(--font-roboto-regular);}
    button.btn-add:disabled{background-color: #c5c5c5; }
    .MuiTypography-root{font-family: var(--font-roboto-regular);}
    .MuiTypography-body1 li div{font-size: 14px; font-weight: 300;}

    .header-comments{
        width: 100%;
        text-align: end;
        svg{
            font-size: 25px;
            cursor: pointer;
        }

    }

    .collection,.jurisdiction{
        padding: 5px;
        border: none;
        // width: 30%;
        outline: none;
        margin: 5px;
        box-shadow: 1px 1px #ddd;
        border-radius: 5px;
    }
    .courselist,.topiclist{
        background-color: #fff;
        padding: 5px;
        margin: 5px;
        max-height: 64vh;
        overflow-y: auto;
        box-shadow: 1px 1px #ddd;
        border-radius: 5px;
        list-style: none;

    }
    .course-li-active,.topic-li-active{
        background-color: #ddd;
        padding: 5px;
        margin: 5px;
        list-style: none;
        cursor: pointer;
    }

    .topic-header{
        height: 76vh;
        overflow-y: auto;
        overflow-x: hidden;
        .lrn-name{
            text-transform: uppercase;
        }
        &::-webkit-scrollbar {
          width: 8px;
        }
      }

    .preview-container{
        width: 100%;
        border: 1px solid #ddd;
        background-color: #fff;
        padding:8px;
        min-height:200px;
        margin-top:10px;
        border-radius:5px;
        transition: all .2s ease-in-out;
        .instruction-text{
            display: none !important;
        }
        .video-container{
            .add-video-sub{
                display: none;
            }
        }
        .forward-backward-btns{
            display: none !important;
        }
        .preview-head{
            display: flex;
            justify-content: space-between;
            padding: 13px;
            align-items: center;
            .preview-title{
                text-align: center;
                text-transform: uppercase;
                font-weight:700;
                display: flex;
                .cmt-last-update{
                    font-size: 10px;
                    white-space: nowrap;
                    margin: 0;
                    margin-top:2px;
                }
            }
            .close-pre{
                font-size:25px;
                cursor: pointer;
            }
        }
        /* Comments */
        &.cmd-preview{
            margin-top: 4px !important;
        }
        .lrn-cmd-edit{
            margin-right:30px;
            cursor: pointer;
            &:hover{
                font-weight: bold;
            }
        }
        .author-comment-section{
            padding: 10px;
            border-bottom: 1px solid #ddd;
            &:last-of-type{
                border-bottom:none !important
            }
            .comment-row{
                // box-shadow: var(--box-shadow-1);
                .profile-badge,.comt-preview{
                    margin-left:10px;
                    .profile-latter{
                        display: flex;
                        width: 30px;
                        height: 30px;
                        background: var(--bs-gray-400);
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        font-weight: bold;
                    }
                }
                .comt-preview{
                 padding-left:12px;
                }
                .profile-comment{
                    max-height:60px;
                    overflow: hidden;
                }
            }
        }
    }

    .selected-element{
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: row;
        grid-column-gap: 30px;
        column-gap: 30px;
        width: 100%;
        background-color: #fff;
        justify-content: end;
        padding-bottom: 20px!important;
        padding: 16px;
        margin-left: 10px;
    
      }
  
    // .preview-container:hover {
    //     transform: scale(1.05);
    // }

    .btn-items{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .btn-cancel{
        border: none;
        font-weight: 600;
        padding: 7px;
    }

    .btn-add{
        background-color: #000;
        color: #fff;
        border: none;
        padding:7px;
        border-radius: 3px;
        margin-right: 10px !important;
        margin: 8px;
    }
    .pdl20{ padding-left: 20px;}
    .mb10{
        margin-bottom: 10px!important;
    }
    .mr5{
        margin-right: 5px!important;
    }
    .mt10 { margin-top: 10px;}
    
    .title{
        padding:5px;
        margin:5px;
        font-weight: 600;
    }
    .course-li,.topic-li{
        padding: 5px;
        margin: 5px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        /* comments */
        .cmt-tp{
            display: none;
        }
        .active-cmt{
            display: inline !important;
            color: var(--primary-color-1);
            font-size: 18px;
            margin-right:20px;
        }
    }
    .selected-tp{
        flex:4;
    }
    .path{
        flex:4;
    }
    .archive-video{
        margin-left: 10px;
        padding: 5px !important;
    }
    
    .selectable-tp{
        flex:1;
        overflow: hidden auto;
        height: calc(80vh - 25px);
        ul{
            margin-bottom: 0;
            border:1px solid #ddd;
            li{
                border-bottom: 1px solid #ddd;
                padding: 10px 5px;
                cursor: pointer;
            }
            .topic-element{
                .item-name{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                .btn-list{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                }
            }
            li:last-child {
                border-bottom: none;
            }
            .active{
                background-color:#dcf0e4;
            }
        }
        &::-webkit-scrollbar {
            width: 8px;
          }
          
        /* Track */
        &::-webkit-scrollbar-track {
        background: #f1f1f1; 
        }
           
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: #c5c5c5; 
        }
    }
    .learing-element-list{
        padding-left: 0;
        li{
            cursor: pointer;
            width: 100%;
            padding: 20px;

            .last-cmt-date{
                display: flex;
                flex-direction: column;
                text-align: center;
            }
            .last-cmt-author{
                max-width: 25%;
            }
        }
        .selected-le-item{
            background-color:var(--primary-color-1);
            color:#fff;
        }
        /* Comments */
        .last-cmt{
        white-space: nowrap;
        width: 55%;
        max-width: 55%;
        display: flex;
        align-items: end;
        /* padding-right: 4px; */
        overflow: hidden;
        text-overflow: ellipsis;
        .comp-list-icon{
            min-width: 22px;
            min-height: 22px;
            max-width:22px ;
            margin-right: 10px;
            border-radius: 50%;
            overflow: hidden;
            display: inline-flex;
            border: 1px solid var(--color-gray-3);
            align-items: center;
            justify-content: center;
            img{
                width: 100%;
                height: 100%;
            }
        }
        }
    }
}

