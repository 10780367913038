:root {
--header-height:60px;
 /* $bgImage:url('../assets/img/bg_1.JPG');
--bg-image:#{$bgImage}; */
/* --bg-image:url('../assets/img/bg_1.JPG'); */
--bg-image:url('../assets/img/bg_2.jpeg');
--egadd-bg-img: url('https://assets.contentenablers.com/website/imgs/on_demand_hero_image.jpeg');
--ce-bg-image: url('https://assets.contentenablers.com/website/imgs/home_video_poster_min_jpg_a7bfb35464.jpeg');
    /*ACCORDION */
--acc-height:60px;
--acc-font-size:0.8rem;
--mauto:'auto';
--direction:'ltr'
}