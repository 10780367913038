.document-container {
    padding: 24px;
    .doc-layout-container {
      margin-top: 24px;
      display: flex;
      gap: 24px;
      width: 100%;
      align-items: stretch;
  
      .thumb-image {
        background-color: #e9ecef;
        border-radius: 11px;
        overflow: hidden;
        text-align: center;
        padding: 16px;
        img {
          border-radius: 11px;
          width: 100%;
          // max-width: 460px;
          min-width: 360px;
        }
      }
      &.layout-1 {
        &.txt-pos-2 {
          flex-direction: row-reverse;
        }
        .thumb-image {
          width: 70%;
        }
        .doc-description {
          width: 30%;
        }
      }
      &.layout-2,
      &.layout-3 {
        flex-direction: column;
        &.txt-pos-2 {
          flex-direction: column-reverse;
        }
        .thumb-image {
          // max-width: 520px;
          width: 100%;
          margin: auto;
        }
      }
      &.layout-2 {
      }
      &.layout-3 {
        .thumb-image {
          background-color: none;
        }
      }
    }
    .iframe-container {
      .iframe-download-docs{
        height: 22px;
        width: 75%;
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid #e1e1e1;
        border-bottom: none;
        display: flex;
        align-items: center;
        justify-content: end;
        // gap: 12px;
        font-size: 10px;
        padding-right: 5px;
        button{
          background-color: transparent;
          padding: 2px 6px;
          border: none;
          height: 100%;
          &:hover{
            background-color: #d5e1f2;
          }
          .MuiSvgIcon-root{
            width: 16px;
            height: 16px;
          }
        }
      }
      flex-direction: column;
      display: flex;
      // margin-top: 24px;
      justify-content: center;
      align-items: center;
      // min-height: 500px;
      // width: 70%;
      iframe {
        display: block !important;
        width: 75%;
        height: 35vw;
        // pointer-events: none;
      }
    }
  }
  
  ._tablet, ._mobile{
    .document-container {
      .doc-layout-container {
        &.layout-1 {
          flex-direction: column;
          &.txt-pos-2 {
            flex-direction: column-reverse;
          }
          .thumb-image {
            width: 100%;
          }
          .doc-description {
            width: 100%;
          }
        }
      }
      .iframe-container{
          iframe{
              height: 60vw;
              width: 100%;
          }
      }
    }
  }
  
  ._mobile{
      .document-container{
          .iframe-container{
              iframe{
                  height: 75vw;
              }
          }
      }
  }
  