@import '../../theme/mixins.scss';
// audio style starts
.audio-container {
    text-align: center;
    margin: 0 10%;
   
    #rhap_current-time {
        position: relative;
        // left:100%;
        @include switch-direction-rtl(left,100%);
        width: 50px;
    }

    .fr-btns{
        svg{
            font-size: 30px;
        }
        cursor: pointer;
    }

    .front-10{
        margin-left: 5px;
    }

    .play-icon {
        cursor: pointer;
    }

    div.rhap_progress-container {
        height: 19px;
    }

    .rhap_total-time {
        opacity: 0;
        position: absolute;
    }
    .control-icon {
        position: relative;
        z-index: 9999999;
        flex:6;
        .play-pause-icon {
            position: absolute;
            // left: 1%;
            @include switch-direction-rtl(left,1%);
            top: 13%;
            z-index: 9999999;
            svg{
                font-size: 30px !important;
            }
        }
    }
    .rhap_container  {
        margin: 0 auto;
        box-shadow: none;
        background: none;
    }
    .rhap_progress-bar {
        height: 2.5px;
    }
    .rhap_progress-indicator {
        height: 15px;
        width: 15px;
        top:-6px;
        background: var(--primary-color-1) !important;
      }
      .rhap_progress-bar-show-download, .rhap_download-progress {
          background: #c2c2c2;
      }
      .rhap_progress-filled {
        background: var(--primary-color-1) !important;
      }
      .rhap_controls-section {
          display: none;
      }
}

.play-pause-icon{
    cursor: pointer;
    margin-left: 30px;
}

.audio-section {
    position: relative;
    top: 30%;
}

.readable-text-audio {
    padding: 0;
    margin: 20px 0;
    font-size: var(--global-font-size);
    line-height:var(--global-line-height);
    ul {
        list-style: initial;
        padding-bottom: 2rem;
        li {
            margin: 25px 0;
            &::marker {
                color: var(--primary-color-1);
                font-size: 20px;
                display: flex;
            }
        }
    }
    @include for-tablet-up {
        padding: 0
    }
}

// audio floating button
@keyframes blinking {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}
.audio-play-btn {
    bottom: 100px;
    right: 18px;
    position: fixed;
    color: white;
    border-radius: 50%;
    width: 40px;
    display: flex;
    height: 40px;
    box-shadow: var(--box-shadow-1);
    align-items: center;
    justify-content: center;
    background: var(--primary-color-1);
   animation: blinking 1s infinite;
   animation-duration: 3s;
   animation-play-state: running;
    svg {
        font-size: 30px;
        color: #fff;
    }
}