@import "../../../theme/mixins.scss";
@import "./TopicAccordion.scss";
$dot:".";
.crs-linear {
    font-family: var(--global-font-family-medium) !important;
    background: #ffffff;
    border-left: none !important;

    .title-info {
        height: auto;
        padding: 0;
        border-bottom: 1px solid #cccc;
        .title-topics {
            display: flex;
            align-items: center;
            padding: 20px 16px 16px 16px;
            .top-note-icon {
                padding: 0 !important;
            }
            .top-root-topics {
                cursor: pointer;
                font-size: 16px;
                font-weight: 500;
                color: var(--primary-color-1);
                padding-left: 10px;
                font-family: var(--global-font-family-medium);
            }
        }
        .lesson-progress {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            padding: 16px;
            background-color: #f5f5f5;
            margin-bottom: 12px;
            .course-progres {
                display: flex;
                flex-direction: column;
                .MuiCircularProgress-colorPrimary {
                    height: 25px !important;
                    width: 25px !important;
                    margin-top: 3px;
                }
            }
            .slider-cprogress {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 10px;
                color: var(--primary-color-1) !important;
                i {
                    font-size: 14px;
                }
            }
        }
        /* accordion */
        .accordion {
            .acc-node {
                display: flex;
                align-items: center;
                padding: 16px 10px 16px 18px;
                svg {
                    width: 28px;
                    height: 28px;
                    path {
                        color: var(--completion-color) !important;
                    }
                }
                .title {
                    margin: 0 !important;
                    font-weight: 600;
                    padding-left: 10px;
                    font-size: 14px;
                    min-width: 184px;
                    font-family: var(--global-font-family-medium) !important;
                    @include for-laptop-medium-up {
                        font-size: 10px;
                        min-width: 155px;
                        .tp-text-area {
                            font-size: 12px;
                        }
                    }
                    text-transform: none;
                }
            }

            /* cus-tree-list  */

            .cus-tree-list {
                list-style-type: none !important;
                margin: 0;
                padding: 0;
                /* li Topic Active Check  */
                .in-tp-active {
                    background-color: #f3f3f3 !important;
                    font-weight: 400;
                    .dp-tp-active {
                        background-color: #e2e2e2 !important;
                        .dp-tp-active{
                            .dp-tp-active{
                                background-color: var(--color-gray-5) !important;
                            }
                        }
                    }
                    .tp-active {
                        background-color: var(--bs-gray-200);
                        font-weight: 500;
                        font-family: var(--font-roboto-medium) !important;
                    }
                }

                /*sub childern accordion kind of design*/
                .hide-child + .cus-tree-list {
                    display: none;
                }

                .show-child + .cus-tree-list {
                    display: block;
                }

                /*Topic IN Complrted*/
                .completed {
                    svg {
                        height: 22px !important;
                        width: 22px !important;
                        font-weight: bold;
                    }
                }

                .inner-child {
                    cursor: pointer;
                    .tp-info {
                        padding: 14px 10px 14px 21px;
                    }


                    .deeper-child {
                        .tp-info {
                            padding: 20px 10px 20px 22px;
                            display: flex;
                            align-items: center;
                            max-height: 34px;
                            &::before {
                                content: $dot !important;
                                font-size: 63px;
                                margin-top: -35px;
                            }
                            .tp-title {
                                .tp-text-area {
                                    font-size: 12px;
                                    @include for-laptop-medium-up {
                                        font-size: 12px;
                                    }
                                }
                            }
                            .acc-node-icon {
                                display: none;
                            }
                        }
                    }
                    .deeper-most-child,
                    .depth-child {
                        .deeper-most-child{
                            // padding: 8px 10px 8px 24px !important;
                            .tp-info::before {
                                content: $dot;
                                font-size: 38px;
                                margin-bottom: -8px;
                            }
                        }
                        .tp-info {
                            padding: 8px 10px 8px 24px !important;
                            &::before {
                                content: $dot;
                                font-size: 48px;
                                margin-top: -30px;
                            }
                            .acc-node-icon {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    /* point active color check in deeper-child */
    .non-visited {
        .tp-info::before {
            color: var(--non-visited-color) !important;
        }
    }

    .visited {
        .tp-info::before {
            color: var(--visited-color);
        }
    }

    .completed {
        .tp-info {
            &::before {
                color:var(--completion-color) !important;
                font-weight: 500;
            }
        }
    }

    .tp-info {
        display: flex;
        padding: 14px 10px 14px 21px;
        .tp-title {
            display: flex;
            padding-left: 10px;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            .tp-text-area {
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                background: none;
                cursor: pointer;
                border: none;
                width: 100%;
                text-align: left;
                // expender adjust
                max-width: 185px;
                @include for-laptop-medium-up {
                    font-size: 12px;
                }
            }
        }
        .acc-node-icon {
            svg {
                width: 16px;
                height: 16px;
            }
            path {
                color: var(--completion-color) !important;
            }
            .CircularProgressbar .CircularProgressbar-trail {
                stroke: #a3d7b9 !important;
            }
            .CircularProgressbar .CircularProgressbar-path {
                stroke: var(--completion-color) !important;
            }
        }
    }

}

.MuiLinearProgress-determinate {
    .MuiLinearProgress-barColorPrimary {
        background-color: var(--completion-color);
        border-radius: 4px;
    }
}
.expand-menu-icon{
   svg{ font-size: 25px; }
    cursor: pointer;
 }
.hide-child{
    animation: rotateAnimation 1s infinite;
    .expand-menu-icon{
        transform: rotate(180deg);
      }
}