/* LoadingSpinner.css */

.loading-spinner {
    display: flex;
    align-items: center;
    height: 100px; /* Set the desired height */
  }
  
  .dots-container {
    display: flex;
    margin-top: 10px;
  }
  
  .dot1, .dot2, .dot3 {
    width: 6px;
    height: 6px;
    background-color: #333; /* Set the desired color */
    border-radius: 50%;
    margin: 0 5px;
    animation: bounce 0.6s infinite alternate;
  }
  
  .dot2 {
    animation-delay: 0.2s;
  }
  
  .dot3 {
    animation-delay: 0.4s;
  }
  
  @keyframes bounce {
    to {
      transform: translateY(-10px);
    }
  }
  
  .loading-text {
    margin-left: 10px;
    // font-size:var(--global-font-size);
    color: #555;
    font-size: 24px !important;
  }

  .loader-container{
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #fff, $alpha: 0.7);
    width: 100%;
    height: 100%;
    z-index: 999999999999;
    padding: 8px;
  }
  

