.reorder-list {
    font-size: var(--global-font-size);
    background-color: #fff;
    padding:16px;
    .inner-container {
        margin: 16px;
        .heading-result {
            padding: 16px 0;
            font-weight: bold;

            .dragging-result {
                display: flex;
                justify-content: center;
            }
        }

        .ordering {
            overflow: hidden;
            .drag-item {
                padding: 18px;
                box-shadow:0px 0px 3px rgba(0, 0, 0, 0.1);
                background-color: var(--bs-gray-100);
                display: flex;
                column-gap: 16px;
                margin: 16px 0;
                border: 1px solid var(--border-color);
                border-radius: 5px;
                p{margin-bottom: 0 !important;}
                .drag-result {
                    visibility: hidden;
                    transition: opacity 0.8s ease-in-out;
                    opacity: 0;

                    &.visible {
                        opacity: 1;
                        visibility: visible !important;
                    }
                }
            }
        }
        .ddcontainerghost {
            width: 68vw;
        }
        .ddcontainer,
        .ddcontainersource{
            width: 100%;
        }
    }
}


._labtop{
    .reorder-list  .ddcontainerghost{
        width: 43vw;
    }
}
._tablet{
    .reorder-list  .ddcontainerghost{
        width:30vw !important;
    }
}
._mobile{
    .reorder-list  .ddcontainerghost{
        width:18vw !important;
    }
}