@import '../../../theme/mixins.scss';
.cuepoints{
    // display: none;
    position: relative;
    height: 3px;
    width: 81%;
    margin-left: 7%;
    margin-right: 15%;
    z-index: 1;
    // left: 9%;
    bottom: -18px;
    
         @media (max-width: 1600px) {
          width: 80% !important;
          margin-left: 11% !important;

        }
        @media (max-width: 772px) {
          width: 80% !important;
          margin-left: 16% !important;
          bottom: -24px;
        }
        @media (max-width: 450px) {
          width: 45% !important;
          margin-left: 27% !important;
        }
        @media (max-width: 320px) {
          width: 30% !important;
          margin-left: 34% !important;
        }
        
       
        
          

    .cue-item{
        height: 100%;
        bottom: 55px;
        background-color: rgba(91, 91, 91, 0);
        z-index: 2;
        display: inline-block;
        position: absolute;
        background-repeat: no-repeat; 
        .icon-cue {
          cursor: pointer;
          @media (max-width: 772px) {
           img{
            width: 5% !important;
           } 
            
          }
          @media (max-width: 450px) {
            img{
              width: 8% !important;
             } 
          }
          @media (max-width: 320px) {
            img{
              width: 12% !important;
             } 
          }
           svg {
               background: RGB(255, 227, 174);
               font-size: 33px;
               padding:5px;
               border-radius: 50%;
    //            @media (min-width: 450px) {
    //             font-size: 8px;
    // }   
           }
        }
    }
}

.custom-progress-div{
  position: relative;
  img{
    position: absolute;
    z-index: 2;
    margin-top: -12px;
    @include for-laptop-up{
      margin-top: -9px;
      width:20px;
    }
    @include for-mobile-medium-large{
      width: 10px;
      margin-top:-4px;
    }
  }
}

._mobile {
  .custom-progress-div img{ width: 14px;margin-top:-6px;};
  .vjs-big-play-button{ top: 34% !important;
    //  left: 40% !important; 
     @include switch-direction-rtl(left,40%);
    }
}
._tablet{
  .custom-progress-div img{ width: 24px; };
}