@import '../../../theme/mixins.scss';
section[name=TopicHeader] {
    min-height: 50px;
    .topic-div {
        // background: #ffffff;
        border-radius: 10px;
        .topic-container {
            padding: 35px 0;
            // text-align: center;
            border-radius: 5px;
            .topic-heading-tag {
                font-weight: bolder;
                font-family:var(--global-font-family-medium)
            }
            .quote-para {
                // font-style: italic;
                text-align: center;
                font-size: var(--global-font-size);
                line-height:var(--global-line-height);
                margin: 15px 50px;
                ul{
                    li{
                        text-align: initial;
                    }
                }
                @include for-tablet-up {
                    margin: 15px;
                }
            }
        }
    }
}




