@import "../../../theme/mixins.scss";
.select-option-list{
    display: block !important;
    padding: 8px !important;
}
.MuiPaper-elevation {
    height: auto !important;
    // right: auto !important;
    @include switch-direction-rtl(right,auto);
    
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
    font-weight:400;
}
// .MuiButtonBase-root{
//     justify-content: flex-start;
//     padding-left: 15px;
// }
.dropdown{
    min-width: 210px !important;
}