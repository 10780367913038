@import './theme/variables.css';
@import './theme/fonts.css';
@import './theme/colors.css';
@import './theme/sizes.css';
@import './theme/global.css';



.MuiTab-wrapper{
    font-family: 'Work Sans';
} 

.loader-info{
  font-size:3rem;
}