@import '../../../theme/mixins.scss';
.main-drag-drop-container {
    background: #ffffff;
    padding: 25px 35px;
    background: #ffff;
    border-radius: 5px;
    box-shadow: 0 2px 16px 4px RGB(40 44 63/5%);
}

.drag-drop-title {
    font-weight: bolder;
    color:var(--primary-color-1);
    text-align: center;
    font-weight: 600;
}

.drop_text {
    color: #cccc;
    padding: 0 15px;
    @include for-ipad-pro-devices {
        font-size: 12px;
    }
}
.Drag.and.drop {
    .custom-card {
        padding-bottom: 6.5%;
        padding: 20px;
    }
}

.drag-drop-image {
    width: 60px;
    @include for-mobile-medium-large {
        width: 30px;
    }
}

.drag_0 {
    .top-icon {
        svg {
            fill: red
        }
    }
}

.drag_1 {
    .top-icon {
        svg {
            fill: yellow
        }
    }
}

.drag_2 {
    .top-icon {
        svg {
            fill: blue
        }
    }
}

.top-icon {
    text-align: center;
}
.dnd-container{
    margin-top: 25px;
    @include for-tablet-up {
        overflow-x: auto;
        padding: 0;
        flex-wrap: nowrap;
    }
    .MuiSvgIcon-root {
        font-size: 45px !important;
    }
   display: flex;
    justify-content: space-between;
    padding: 15px;
    flex-wrap: wrap;
    section{
        flex:1;
        margin: 25px 0;
        @include for-tablet-up {
            flex: 0 0 100%;
        }
        .container-box{
            min-height: 100px;
            border-radius: 10px;
            border:2px dashed #6e6e6e;
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            li{
                border-bottom: 1px solid #eee;
                padding: 10px;
            }
        }
    }
}


div.MuiTooltip-tooltip {
    opacity: 1;
    color: #ffffff;
    .tooltip-title {
        margin-bottom: 10px;
    }
    .tooltip-snippet {
        opacity: 1;
        font-size: var(--global-font-size);
        line-height:var(--global-line-height);
    }

    span.MuiTooltip-arrow {
        color: #a1a1a1;
    }
}

.draggable-items{
    text-align: center;
    
    span.MuiTooltip-arrow {
        color: #f3f3f3;
    }
    .ddcontainer {
        box-shadow: var(--box-shadow-1);
        border-radius: 10px;
        margin: 15px 10px;
    }
    .draggable-item {
        margin: 15px;
        display: flex;
        align-items: center;
        justify-content:center
    }
    .h-info{
        display: none;
    }
    // .draggable-item:hover{
    //     position: absolute;
    //     display: block;
    //     bottom: -56px;
    //     width: 600px;
    //     left: 0;
    //     height: 50px;
    //     background: #ddd;
    // }

    ul{
        list-style-type: none;
    }
}

@include for-tablet-up {
    .drop_text {
        font-size: 12px;
    }
}


