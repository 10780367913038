.right-to-left {
    direction: rtl !important;

    .scroll-to-top-btn{
        right: unset !important;
        left: 20px;
    }
    .ce-player{
        direction: rtl !important;
    }
// **************** navbar ****************
    header.MuiAppBar-root {
        .img-container {
            padding-right: 25px;
        }
        // .MuiToolbar-gutters{
        //     flex-direction: row-reverse;
        // }
    }
    .edit-tool-menu .language-icon{
         margin-left: 25px !important;
         margin-right:unset !important;
    }
    
    .comp-layout{
        margin-right: 170px !important;
    }

    .search-over-lay{
        direction: rtl;
        left: 0 !important;
        right: unset !important;
    }
// **************** sidebar ****************
    .sidebar {
        right: 0 !important;
        left: unset !important; // resets
        .open{
            right:0 !important
        }
    }
    .home-container div.MuiTabs-flexContainer button:first-child span{
        text-align: right !important;
        padding-right: 16px;
    }
    .title-topics{
        padding-right: 10px;
    }
    .print-content{
        margin: 0 39% !important;
    }
    .tp-info{
        padding-left: unset !important;
    }
    .acc-node{
        padding-right: 10px;
        .expand-menu-icon{
            padding-right: 0 !important;
         }
    } 
    .circular-progress-icon{
        margin-right: 9px;
    }
    .expand-tree-icon {
        right: 20%;
        left: unset !important;
        transform: rotate(180deg);
    }
    .expand-tree-big {
        right: 40%;
        transform: rotate(180deg);
        left: unset !important;
        background-color: #fff !important;
    }


    // **************** layout content ****************

    #layout-scroll{
        direction: rtl !important;
    }
    .MuiCircularProgress-colorPrimary svg{
        left: 4px;
        right: unset !important;
    }

    .print-certificate-course-info_outer div{
        flex-direction: row-reverse !important;
    }

    .tree-structure{
        .title{
            padding: 0 12px !important;
        }
        .cus-tree-list{
            .tp-text-area{
                text-align: right !important;
            }
        }
        .lesson-progress{
            padding: 0 10px;
            i{
                margin-right:66px;
                margin-left: unset !important;
            }
        } 
            .inner-child::before {
                left: unset !important;
                right: -25px !important;
            }
            .deeper-child::before {
                left: unset !important;
                right: -20px !important;
            }
            .deeper-most-child::before,.depth-child::before {
                left: unset !important;
                right: -16px !important;
            }
    }

        .powered-by{
            img{
                left:30px !important;
                right: unset !important;
            }
        }
       .image-gallery-display-btn{
         right: unset !important;
         left: 18px;
       }

    .main-layout-container{
        animation: layout .8s ease-out 0s;
        @keyframes layout {
            0% {
                opacity: 0;
                /* Adjust translate to LTR */
                transform: translate(0px, 25px) !important;
            }
            100% {
                opacity: 1;
                /* Adjust translate to LTR */
                transform: translate(0px, 0px) !important;
            }
        }
    }
    .print-course-list{
         .print-primary-coursemap{
           text-align: right !important;
        }
    }
    .content {
        &.open {
            right: 7% !important;
        }
        left: 0 !important;
    }

    .title-info .title-topics {
        column-gap: 20px;
    }

    .content-list-container {
        .arrows {
            left: 78px;
        }
    }



    /// **************** edit panels ****************
     .overlay-container #drawer-menu{
       left:0 !important;
       transform: translateX(-100%) !important;
       .ce-drawer-text-header,.ce-drawer-close-icon{visibility: hidden;}
       &.open {
        .ce-drawer-text-header,.ce-drawer-close-icon{visibility: visible;}
        transform: translateX(0) !important;
      }
     }

     .print-course-container {
        .course-print-row{
            flex-direction: row-reverse;
        }
     .top-print-row .text-right{
        text-align: left !important;
     }
    }

    .rdw-dropdown-carettoopen {
        left: 10% !important;
        right: unset !important;
    }

    .upload-container {
        .file-name {
            text-align: right;
        }
    }

    .listing-card-quizzes {
        padding: 15px 3px !important;
    }

    .audio-section-container {
        direction: rtl !important;
    }


    // **************** LRN ******************
    .add-on-context-text-overlay,.overlay-render{
        .head-item svg.close-btn{
            right: unset !important;
            left: 5px;
        }
    } 
    .instruction-text{
        padding-left:38px;
        .MuiSvgIcon-root{
            right: 98%;
        }
    }
    section[name=VideoHotspot]{
        .head-item .close-btn{
            right:unset !important;
            left: 0;
        }
       .active-action,.step {
        &::after{
            right:unset !important;
            left:-38px;
        }
       }
    }
    section[name=ImageGallery]{
        .carousel-root{ // we showing thw images as it is ltr view
            direction: ltr !important;
        }
    }
    section[name=DragandMatch]{
        .drag-question .drop-box{
          padding-right: 16px;
        }
    }
    .vjs-control-bar{
        direction: rtl !important;
    }
    .vjs-progress-holder{
        direction: ltr !important;
    }
    .vjs-video-text-version,.arc-text-version{
        left: 86px !important;
        right: unset !important;
    }

    .audio-section {
        .audio-container [aria-label="Audio Player"] {
            direction: ltr !important;
            #rhap_current-time{
                left: 100% !important;
            }
        }
    }

    .quizzes-continuer,
    .buttons {

        .nextbutton,
        .backbutton {
            margin-left: 20px;
        }
    }

    .visible .instruct-panel {
        padding-left: 35px;
    }


.scenario-block {
    .scenario-block-dialogues-top {
        padding-right: calc(20% + 2rem) !important;
        // padding-left: 1.5rem !important;
    }
.scenario-continue-btn {
    &.back-btn{
        left: unset !important;
        right: 10px;
    }
}
    .scenario-block-dialogues-bottom {
        .bottom-inner {
            padding-left: calc(25% + 1rem);
            padding-right: unset !important;
        }
    }
}


//  ******************  global place property  ******************

:root,
.DraftEditor-root {
    // direction: rtl;
    // --direction: rtl;
}

.MuiDrawer-modal {
    .MuiPaper-elevation {
        right: 10% //calc(var(--direction)==rtl, 50%, 10%);
    }
}

.public-DraftStyleDefault-ltr {
    direction: ltr !important; //calc(var(--direction)==rtl, rtl, ltr) !important;
}

// audio preview
.audio-section-container {
    .audio-section {
        direction: rtl !important;
    }
}
}

@media print {
    .rtl-print-course-container {
        direction: rtl !important;
        unicode-bidi: embed;
        .course-print-overview{
          p{
            display: flex;
            flex-wrap: nowrap;
            white-space: nowrap;
          }
          span{
            white-space: nowrap;
          }
        }
        .course-print-row{
            flex-direction: row-reverse;
        }
    }
    .rtl-print-primary-coursemap span{
        width: 100% !important;
        text-align: right !important;
        span{
            text-align: right;
        }
    }
}

.rtl-tour{
    direction: rtl !important;
    .form-check{
        .form-check-input{
            float: right !important;
           margin-right: -1.5em;
           margin-left: unset;
        }
    }
    .reactour__close{
        left: 22px;
        right: unset !important;
    }
}
.rtl-custom-SweetAlert{
    direction: rtl !important;
    p{
        flex-direction: row-reverse;
    }
}