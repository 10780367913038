.social-share-container {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    // bottom: 10%;
    width: 100%;
    z-index: 999;
    text-align: center;
    
    .inner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--primary-color-2);
      border-radius: 0.5rem;
      // min-width: 500px;
      padding: 6px 10px;
  
      .text-content {
        margin: 0;
        font-size: 16px;
  
        .ln-icon {
          color: rgb(25, 118, 210);
          svg{font-size: 24px;}
          padding-right: 5px;
        }
      }
    }
  
    &:hover {
      font-weight: bold;
    }
  }
  
  @media only  print {
    .social-share-container{
        display: none !important;
    }
  }