@import "../../theme/mixins.scss";
.stop_scroll{ overflow: hidden;}

.header-div {
    span {
        color: #ccc;
        font-size: 18px;
        font-style: italic
    }

   .tp-edit{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tp-text{
         flex:3;
         margin-right: 10px;
     }
     .btn{
        padding: 7px;
        margin-right: 7px;
        border: none;
        border-radius: 6px;
        min-width:100px;
     }
     .diabled-btn{background-color: #ddd;}
     .cancel-btn{ background-color: #ddd;}
     .submit-btn{
        background-color:var(--primary-color-1);
        color:#fff;
        border:none
    }
   } 

    .edit-prop-accordion {
        cursor: pointer;
    }
}

// sweet-alert custom styles (same css we are using for the navbar publish info popup)
.application-info{
    .sweet-alert {
        width: 45em !important;
        p:first-of-type{
            justify-content: end !important;
            .btn-success{
                background-color: var(--bs-danger) !important;
                padding: 7px  24px;
                border-color:var(--bs-danger) !important;
                box-shadow: none !important;
            }
        }
    }
    .info-details{
        font-size: 18px;
        text-align: left;
        p{margin: 5px 0 !important;}
        h2{font-weight: 500;margin: 4px 0;}
        .last-update{ padding: 6px 0;}
    }
}

@import "../../components/ui/NavBar/navbar.scss";

.home-container {
    width: 100%;
    font-family:var(--font-roboto-regular) !important;
    .circular-progress {
        display: none;
    }
    .accordion {
        border-bottom: 1px solid #cccc;
        & > p.vertical-border {
            height: 55px;
        }
    }
    // Linear structure  .title-info .lesson-progress
    .crs-linear{
        .tp-text-area{
            max-width:100% !important;
        }
        .lesson-progress{
            display: none !important;
        }
        .tp-info.tp-title .tp-text-area{
                max-width: none !important;
        }
    } 
    // Tree structure
    .crs-tree{
        ul.cus-tree-list {
            li {
                &.list-item {
                    padding-left: 0;
                    // first level
                    &.level-inner-child {
                        .tp-info {
                            padding-left: 47px;
                        }
                    }
                    //second level
                    &.deeper-child {
                        padding-left:0;
                        .tp-info {
                            padding-left:71px;
                        }
                    }
                    // third level
                    &.deeper-most-child {
                        .tp-info {
                            padding-left:85px;
                        }
                    }
                }
            }
        }
    }
    .crs-menu {
        background: #f8f8f8;
        &.tree-structure {
            .title-info {
                .title-topics {
                    display: none;
                }
                // tree structure list
                ul.cus-tree-list {
                    li {
                        &.list-item {
                            .tp-info {
                                border-left: 3px solid #f8f8f8;
                            }
                            // first level
                            &.level-inner-child {
                                &:before {
                                    // left: 18px;
                                    @include switch-direction-rtl(left,18px);
                                }
                            }
                            //second level
                            &.deeper-child {
                                &:before {
                                    // left:57px;
                                    @include switch-direction-rtl(left,57px);
                                    z-index: 9999;
                                }
                                &:after {
                                    // left:46px;
                                    @include switch-direction-rtl(left,46px);
                                }
                                .tp-info {
                                    &:before {
                                        // left: 25px;
                                        @include switch-direction-rtl(left,25px);
                                    }
                                }
                            }
                            // third level
                            &.deeper-most-child {
                                &:before {
                                    // left:70px;
                                    @include switch-direction-rtl(left,70px);
                                    z-index: 9999;
                                }
                                .tp-info {
                                    &:before {
                                        // left:46px;
                                        @include switch-direction-rtl(left,46px);
                                    }
                                }
                                &:after{
                                    // left:46px;
                                    @include switch-direction-rtl(left,46px);
                                }
                            }
                        }
                    }
                }
            }
        }
        .title-info {
            border: 0;
            h3 {
                border-bottom: 4px solid var(--primary-color-1);
                // width: 31%;
                width: 100%;
                @include for-mobile-medium-large {
                    width: 100%;
                }
                padding-bottom: 5px;
            }
            i {
                display: none;
            }
        }
    }

    div.MuiTabs-flexContainer {
        button:first-child {
            span {
                text-align: left;
            }
        }
    }

    .MuiTab-wrapper {
        color: #000000;
        text-transform: capitalize;
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        display: -moz-box;
        -moz-line-clamp: 3;
        -moz-box-orient: vertical;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    @include for-ipad-pro-devices {
        .MuiTabs-scroller {
            overflow-x: auto !important;
        }
    }

    .accordion {
        &> .vertical-border {
            left: unset;
            margin-left: 25px;
            height: 55px
        }
    }

    .CircularProgressbar  {
        width: 25px;
        @include for-tablet-up {
            width:22px;
        }
    }
    .bg-content{
        // height:500px;
        width:100%;
        transition: margin .5s;
        background:var(--ce-bg-image);
        //background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        z-index: 9;
        .hero-overlay{
            height: 100%;
            width: 100%;
            position: absolute;
            background: linear-gradient(1deg, #232461 100%, #313131 100%);
            opacity: 0.5;
        }
        .box-holder{
            position: relative;
        }
        .box-item{
            color: #fff;
            // position: absolute;
            bottom: 50px;
            // left:50px;
            // width:50%;
            min-height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            .crs-name-cont{
                min-height: 110px;
                .average-duration{
                    font-size: 18px;
                    font-weight: 400;
                    color: var(--bs-gray-400);
                }

            }
            .crs-name{
                margin:10px 0;
                font-size:3.55rem;
                font-weight:700;
                // width: 65%;
                @include for-tablet-up {
                    font-size: 2.5rem;
                }
            }
            .powered-by {
                min-height: 50px;
                .powered-img{
                    position: absolute;
                    bottom: 15px;
                    // right: 30px;
                    @include switch-direction-rtl(right,30px);
                }
            }

            .btn-holder{
                display: flex;
                // justify-content: space-between;
                align-items: center;
                width:50%;
                @include for-phone-only {
                   flex-direction: column;
                   width: unset;
                }
                .details{
                    background-color: transparent;
                    border: none;
                    color: #fff;
                    text-align: left;
                    text-transform: uppercase;
                    font-weight: 500;
                    cursor: pointer;
                    font-size: 16px;
                    &:hover{
                        color:var(--primary-color-2);
                    }
                    @include for-mobile-large {
                        margin-top: 15px;
                        padding-bottom: 15px;
                    }
                    &.print-map-btn{
                        margin:0 30px;
                        padding: 0 6px;
                        span{
                            white-space: nowrap;
                        }
                    }
                    &>p {
                        margin-bottom: 0;
                        width: 250px;
                        margin-left: 30px;
                        @include for-mobile-medium-large {
                            margin-left: 0;
                            margin-top: 25px;
                        }
                    }
                }
                .arrow-down{
                    font-size: 40px;
                    margin-left: -7px;
                    margin-top: -7px;
                }
                .resume-btn{
                    min-width: 230px;
                    border-radius: 5px;
                    height:50px;
                    border: none;
                    padding: 10px;
                    cursor: pointer;
                    background-color:var(--primary-color-2);
                    text-transform: uppercase;
                    word-spacing: 3px;
                    font-weight: 600;
                    font-size: 16px;
                    color: #000 !important;
                    &:hover{
                        background-color: #fff;
                        color:var(--primary-color-1) !important;
                    }
                }
            }
        }

        
          
    }
    .crs-content{
        width: 50%;
        margin: 10px auto;
        margin-top: 10px;
        @include for-tablet-up {
            width: 90%;
        }
        p{
            &:first-child{
                color:var(--primary-color-1);
            }
        }
        &.menu {
            width: 100% ;
            margin-top: 30px;
            background: #f8f8f8;
            header {
                background: #f8f8f8;
                box-shadow: none;
            }
            .MuiTabs-indicator {
                height: 5px;
                border-radius: 15px;
                background: var(--primary-color-1);
            }
            &>div {
                margin:0 auto;
                width: 50%;
                // padding: 20px 0;
                background: #f8f8f8;
                @include for-tablet-up {
                    width: 90%;
                }
                div[role=tabpanel] {
                    &> div {
                        padding: 0;
                    }
                    span {
                        font-size: 16px;
                    }
                }
                div[role=tablist] {
                    button {
                        // width: 25%;
                        width: 100%;
                        padding: 0;
                        &.Mui-selected {
                            margin-bottom: 10px;
                            span {
                                color: var(--primary-color-1);
                            }
                        }
                        @include for-tablet-up {
                            width: 50%;
                        }
                    }
                }
            }
        }
        .logo{
            margin: 20px 0;
            width: 250px;
        }
        p{
            font-size:22px;
            margin-bottom: 15px;
            margin-top: 15px;
        }
        .circular-bar {
            margin-right:10px;
        }
        
    }
    
}

.navbar.navbar-light.bg-light {
    background: #ffff !important;

    .navbar-img {
        width: 225px;
        margin: 5px 20px
    }
}
.resume-course{
    animation-name: make_transition;
    animation-duration: 1.8s;
}

@keyframes make_transition {
    from {width:100%; } to {width:20%;}
}

.le-aid-search{
    display: flex;
    justify-content: flex-end;
    margin: 0% 5% 4% 5%;
    button{
    border: none;
    padding: 10px;
    font-weight: 600;
    box-shadow: var(--box-shadow-4)
    }
}

//pre-test-container
.test-out-modal{
    .image-gallery-display-btn{
        display: none;
    }
    .pre-test-container{
        padding: 25px 0;
        .pre-test-heading{
            font-size: var(--global-font-size);
            margin: 0;
            // padding-left: 35px;
            text-align: center;
            font-weight: bold;
        }
        .quizzes-continuer{
             padding: 16px;
        }
    }

}
.attestation-model{
    // .attest-act-text, .attest-check .MuiTypography-root{
    //     font-size: 16px;

    // }
    .attestation-model_inner{
        padding: 16px;
        overflow-x: hidden;
        .header{
            background-color: #fff !important;
            position: absolute;
            top: 0px;
            padding-bottom: 0;
        }
        .attestation-model_content{
            // margin-top: 6% !important;
            font-size: 18px;
        }
    }
    .ck-checkbox-root{
        align-items: flex-start !important;
        .MuiCheckbox-root{
            padding: 0 !important;
        }
        .MuiTypography-root{
            white-space: normal !important;
            font-size: 16px;
        }
    }
}
.lan-sel-model{
    // .header{
    // background-color: #000 !important;
    // }
    .common-mui-btn{
        font-size: 24px;
        svg{
            font-size: 25px;
        }
    }
    .image-gallery-display-btn{
        display: none;
        svg{background-color: transparent !important;}
    }
    &.show-ln-close{
        .image-gallery-display-btn{
            display: block;
        }
    }
.language-container{
    padding-top: 16px;
    .language-box{
        width: 100%;
        cursor: pointer;
        display: inline-block;
        margin: 10px 10px;
        padding: 0.4vw;
        border: 1px solid #e6e6e6;
        .flag-box{
            display: flex;
            align-items: center; 
            .flag{
                display: inline-block;
                border-radius: 50%; /* Makes the image circular */
                overflow: hidden; /* Ensures the image doesn't overflow the border radius */
                width: 24px; /* Adjust size as needed */
                height: 24px; /* Adjust size as needed */
                margin-right: 8px; /* Add some space between the flag and the text */
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover; /* Ensures the entire image fits within the circle */
                  }
            }
        }
        // .land-name{ text-transform: uppercase;}
        &.lang-img-selected {
            background-color: #f0efed;
            border-left: 5px solid var(--primary-color-1);
            font-weight: bold;
        }
        &:hover{
            background-color: #f0efed;
        }
    }
}
}