.crs-structure-container {

    li {
        list-style-type: none;
    }
    [data-tooltip] {
        white-space: nowrap !important;
    }
    .crs-str-header {
        display: flex;
        align-items: end;
        justify-content: end;
        padding: 0 24px 8px 0;
        column-gap: 16px;
        .reorder-ck{
            .MuiTypography-root{
                font-size: 1.1rem;
            }
        }
    }

    // .fade-in {
    //     opacity: 1;
    //     transition: opacity 0.3s ease, height 0.3s ease;
    // }

    // .fade-out {
    //     opacity: 0;
    //     height: 0 !important;
    //     overflow: hidden;
    // }

    .nestable-drag-layer {
        visibility: hidden;
      }
    .crs-structure {
        max-height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 80vh;

        ol:first-child {
            padding-left: 0 !important;
        }

        .nestable-item-10000 {
            background-color: var(--bs-gray);
            padding: 8px;
            margin-right: 10px;
            border-radius: var(--primary-radius-1);
        }
    }

    .is-dragging {
        background-color: var(--bs-gray) !important;
        padding: 8px;
        margin-right: 10px;
        border-radius: var(--primary-radius-1);
        transition: background-color 0.3s ease, transform 0.3s ease;

        &:hover {
            background-color: var(--bs-gray) !important;
            transform: translateY(-0.8px) scale(1.01);
        }
    }

    .crs-topic {
        display: flex;
        justify-content: space-between;
        background-color: var(--border-color);
        margin: 8px 20px 8px 8px;
        border-radius: var(--primary-radius-1);
        align-items: center;

        .drag-handle {
            margin-right: 6px;

            svg {
                color: #fff !important;
                border-radius: var(--primary-radius-1) !important;
            }
        }

        &.child-0 {
            .drag-handle svg {
                background-color: var(--primary-color-1);
            }
        }

        &.child-1 {
            .drag-handle svg {
                background-color: var(--primary-color-2);
            }
        }

        &.child-2 {
            .drag-handle svg {
                background-color: var(--bs-dark);
            }
        }

        &.child-3 {
            .drag-handle svg {
                background-color: var(--bs-gray-500);
            }
        }

        &.child-4 {
            .drag-handle svg {
                background-color: var(--bs-gray-400);
            }
        }

        .icon-area {
            display: flex;
            column-gap: 16px;
            justify-content: space-between;

            button {
                background-color: var(--bs-gray-300) !important;

                svg {
                    // color: #fff !important;
                }
            }
        }

        .nested-item-content {
            display: flex;
            align-items: center;

            p {
                margin: 0 !important;
            }
        }
    }

}