@import "../../../theme/mixins.scss";

.crs-tree {
  font-family:var(--font-roboto-regular);
  background: #ffffff;
  margin-top: -7px;
  .title-info {
    height: auto;
    padding: 15px 0 0 0;
    border-bottom: 1px solid #cccc;
    .title-topics {
      display: flex;
      align-items: center;
      .main-line.vertical-border {
        position: absolute;
        height: 117px;
        top: 186px;
        left: 43px;
        // @include switch-direction-rtl(left,43px);
        border-left: 1px dashed #7b7b7b;
        @include for-laptop-medium-up {
          height: 80px;
        }
        @include for-ipad-pro-devices {
          height: 70px;
        }
      }
      .top-note-icon {
        flex: 0 0 10%;
        padding-left: 32px;
        svg {
          font-size: 27px;
          margin-left: 12px;
          padding: 3px;
          color: #000000;
          border-radius: 7px;
          border: 3px solid;
        }
      }
      .top-root-topics {
        flex: 0 0 79%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        display: -moz-box;
        -moz-line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        @include for-mobile-medium-large {
          flex: 0 0 75%;
          -moz-line-clamp: 2;
          -webkit-line-clamp: 2;
        }
        @include for-ipad-pro-devices {
          font-size: 14px;
        }
      }
    }
    h3,
    i {
      padding-left: 10px;
      margin-left: 5px;
    }
    i {
      display: block;
      margin-bottom: 15px;
      margin-left: 60px;
    }
    // border-left:4px solid var(--primary-color-1);
    .CircularProgressbar-path {
      stroke:var(--completion-color);
    }

    .check-circle-icon {
      color: var(--completion-color);
      width: 30px;
      display: block;
      height: 30px;
      margin-left: -4px;
      position: relative;
      background: #ffffff;
      border-radius: 50%;
      padding: 0;
    }
    h3 {
      color: var(--primary-color-1);
      font-size: 1.3rem;
      font-weight: 700;
      margin: 7px 0;
      margin-left: 5px;
    }
    i {
      color: rgb(204, 204, 204);
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    li {
      border-bottom: 1px solid var(--color-white);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .c_flex {
        flex: 5;
        z-index: 9999;
      }
      .note-icon {
        margin-right: 7px;
      }

      a {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 15px 0;
        color: var(--color-black);
        text-decoration: none;
        span {
          font-size: var(--acc-font-size);
          margin-left: 12px;
        }
      }
      .active {
        border-left: 4px solid var(--completion-color);
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
    }
    .active-item {
    }
  }

  .lesson-progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .circular-progress {
      margin-right: 15px;
    }
    @media (max-width: 1200px) {
      display: none;
    }
  }


  .accordion {
    .acc-node {
      padding-left: 14px;
      background-color: var(--completion-color);
      .acc-node-icon {
        z-index: 9999;
        svg.circular-progress-icon {
          background-color: transparent;
        }
        .CircularProgressbar {
          //  background: #ffffff;
          background: #000;
          border-radius: 50%;
        }
      }
      svg {
        width: 30px;
      }
      .title {
        margin-left: 12px;
        font-weight: 500;
        margin: auto;
        padding-left: 12px;
        font-size: 18px;
        color: #fff;
        @include for-desktop-up {
          font-size: 14px;
        }
        text-transform: none;
      }
    }
    .expand-menu-icon {
      padding-right: 10px;
      min-width: 25px;
      transform: rotate(180deg);
    }
    .circle-icon {
      color: var(--completion-color);
      margin-right: 2px;
    }
  }

}

.list-item {
  // padding-left: 27px;
  // padding-top: 15px;
  // padding-bottom: 15px;
  .text-node {
    margin-left: 12px;
    margin-right: 8px;
  }
  &.active-item {
    // border-left: 6px solid var(--primary-color-1);
    padding-top: 10px;
    padding-bottom: 10px;
    background: #eeeeee;
    padding-left: 23px;
    border-left: 4px solid var(--completion-color);
    .text-node {
      font-weight: 400;
      color: var(--completion-color);
    }
  }
}

.acc-node {
  .title-tab {
    border-bottom: 4px solid var(--completion-color);
    width: 34%;
    padding-bottom: 10px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 55px;
  cursor: pointer;
  text-transform: uppercase;
  .title {
    flex: 6;
    margin-left: 6px;
  }
  .expand-menu-icon {
    //   padding-right: 12px;
  }
  .expand-icon {
    display: none;
  }
}

.crs-content {
  .circular-bar {
    margin-right: 5px;
    flex: 0 1;
  }
  img {
  }
}

.circular-bar {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  .CircularProgressbar {
    width: 22px;
    .CircularProgressbar-path {
      stroke:var(--completion-color);
    }
    .CircularProgressbar-text {
      fill: var(--completion-color);
      font-size: 30px;
    }
  }
}
.MuiLinearProgress-determinate {
  .MuiLinearProgress-barColorPrimary {
    background-color:var(--completion-color);
    border-radius: 4px;
  }
}

.crs-tree {
  .cus-tree-list {
    li {
      .tp-info {
        padding-top: 8px;
        padding-bottom: 8px;
        min-height: 30px;
        border-left: 3px solid #fff;
        cursor: pointer;
        // font-size: 18px;
        @media screen and (min-width: 1600px) {
          font-size: 18px;
        }
        display: flex;
        align-items: center;
        height: 100%;
        transition: display 2s linear 1s;
        .tp-title {
          width: 100%;
          // padding-left: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .tp-text-area {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background: none;
            cursor: pointer;
            border: none;
            width: 100%;
            text-align: left;
          }
        }
      }

      .tp-active {
        background-color: #e6e6e6;
        border-left: 2px solid var(--primary-color-1) !important;
        color: var(--color-black);
        font-weight: 500;
      }

      .deeper-child {
        .tp-info {
          padding-left: 75px;
          .tp-title {
            padding-left: 0;
          }
        }
      }
      .deeper-most-child {
        .tp-info {
          padding-left: 85px;
          .tp-title {
            padding-left: 0;
          }
        }
      }
    }

    .non-visited::before {
      background-color: var(--color-gray-1);
    }

    .course-not-started::before {
      background-color: var(--color-gray-1) !important;
    }

    .visited::before {
      background-color: var(--color-orange-0);
    }

    .completed {
      font-family: var(--font-roboto-medium);
      &::before {
        background-color: var(--primary-color-1);
      }
    }
  }
}

.acc-node {
  padding-bottom: 10px;
  padding-top: 10px;
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    display: -moz-box;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.crs-tree {
  .active-acc-item {
    // background-color:#dcf0e4;
    background-color: var(--completion-color);
    color: #fff;
    .acc-node-icon {
      .CircularProgressbar {
        background-color: transparent;
      }
    }
  }
  ul {
    // background-color:#dcf0e4;
    background-color: #cdebd924;
    padding-left: 0;
    border-left: 1px solid #ddcfcf;
    li {
      display: block;
      border-bottom: none;
      .circle-icon {
        z-index: 99999;
      }
    }
  }
}

.inner-child {
  .tp-info {
    padding-left: 45px;
  }
}

.depth-child {
  .tp-info {
    padding-left: 88px;
  }
}

.crs-tree {
  // circle css
  .inner-child::before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    left: 25px;
    // @include switch-direction-rtl(left,25px);
    top: 15px;
    background-color: var(--color-gray-1);
    border-radius: 50%;
    z-index: 10;
    color: #7b7b7b;
  }

  .deeper-child::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    left: 50px;
    // @include switch-direction-rtl(left,50px);
    top: 18px;
    background-color: var(--color-gray-1);
    border-radius: 50%;
    z-index: 10;
    color: #7b7b7b;
  }

  .deeper-most-child::before, .depth-child::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    left: 75px;
    // @include switch-direction-rtl(left,75px);
    top: 20px;
    background-color: var(--color-gray-1);
    border-radius: 50%;
    z-index: 10;
    color: #7b7b7b;
    // display: none!important;
  }
}

/*sub childern accordion kind of design*/
.hide-child + .cus-tree-list {
  display: none;
}
// .show-child +.cus-tree-list{
//   display: block;
// }
