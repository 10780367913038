@import "../../../theme/mixins.scss";

@mixin icon-shadow {
    height: 36px !important;
    width: 36px !important;
    margin: 0 15px;
    box-shadow: var(--box-shadow-2);
    text-align: center;
    color: #707070 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.linear-header {
     button:focus {
        border-radius: 5px !important;
      }
    .header-container {
        height: 80px !important;
        .left-menu {
            // padding-bottom: 32px;
            // padding-top: 32px;
            height: 17px !important;
            &:focus{
                min-width: 30px;
                min-height: 30px;
            }
            &:hover {
                background-color: #fff !important;
            }
        }
        .img-container {
            margin-left: 30px !important;
            padding-top: 30px;
            padding-bottom: 31.8px;
            margin-bottom: 0 !important;
        }
        .search-icon {
            padding: 0 30px;
            .search-btn {
                @include icon-shadow;
                border-radius:  0.4rem !important;
                margin: 0 !important;
                @include for-tablet-up{
                    height: 30px !important;
                    width: 30px !important; 
                }
            }
        }
        .top-bar-menu {
            display: flex;
            align-items: center;
        }
        .top-bar-menu,
        .edit-tool-menu {
            button:hover {
                color: var(--primary-color-1) !important;
            }
            .close-course-btn {
                @include icon-shadow;
            }
        }
        .edit-tool-menu {
            display: flex;
            flex-direction: row;
            align-items: center;
            *{
                font-family: var(--global-font-family-medium) !important;
            }
            .header-home,.language-icon {
                margin: 0;
                padding: 0 ;
                &.info-icon{
                    margin-right: 25px !important;
                    path{
                        color: var(--primary-color-2) !important;
                    }
                }
            }
            .header-course-map {
                padding-left: 30px !important;
            }
            .device-icon,
            .template-icon,
            .header-home,.language-icon {
                @include icon-shadow;
            }
            .template-icon,
            .header-home {
                cursor: pointer;
            }
            .template-icon,

            .highlight-profile {
                .user-detail {
                    color: var(--primary-color-1) !important;
                }
                .arrow-dowen {
                    transform: rotate(180deg);
                    color:var(--primary-color-1);
                }
            }

            .user-profile {
                padding: 21px 15px 24px 15px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-weight: bold;
                .profile-pic {
                    img {
                        height: 35px;
                        width: 35px;
                        border-radius: 50%;
                        border: 3px solid var(--primary-color-1);
                    }
                }

                .user-detail {
                    padding-left: 9px;
                    font-family:var(--font-roboto-regular);
                    color: #000;
                    p {
                        margin: 0;
                    }
                    .user-name {
                        font-size: 14px;
                    }
                    .user {
                        font-size: 10px;
                    }
                }
                .arrow-dowen {
                    font-size: 30px !important;
                    margin-top: -14px !important;
                    color: #000;
                }
                .highlight-profile {
                    transform: rotate(180deg);
                    color: var(--primary-color-1);
                }

                svg.arrow-drop-up {
                    top: 51px;
                    &.Device {
                        // right: 244px;
                        @include switch-direction-rtl(right,244px);
                    }
                    &.Template {
                        // right: 312px;
                        @include switch-direction-rtl(right,312px);
                    }
                    &.Profile {
                        // right: 122px;
                        @include switch-direction-rtl(right,122px);
                    }
                }
            }
        }
    }
    .search-over-lay {
        background-color: #dbe2de !important;
        .s-head {
            svg {
                color: #000 !important;
            }
        }
        .search-list-container {
            .search-ul-list {
                list-style: none;
                padding-left: 2px;
                .search-content {
                    margin: 2px;
                    padding: 14px 27px;
                    color: #000 !important;
                    &:hover {
                        color: #000 !important;
                        span svg {
                            color: #000 !important;
                        }
                    }
                    span {
                        svg {
                            color: #7878784d;
                        }
                    }
                }
            }
        }
        ::-webkit-scrollbar-thumb {
            background: var(--scrollbar-primary-1);
        }
    }
}
