.attachment-items{
    padding:16px;
    border-radius: 10px;
    margin: 0;
    .file-list{
       padding: 8px;
       .file-item{
        display: flex;
        width: 100%;
        background-color: var(--bs-gray-200);
        padding: 16px;
        border-radius: 10px;
        .download-note{
            width: 90%;
            padding-right: 10px;
            font-size: var(--global-font-size);
            line-height:var(--global-line-height);
        }
        .icon-box{
            width:10%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{width: 55px; cursor: pointer;}
           }
       }
    }
}