.container {
    position: relative;
    margin: 0 auto;
}

.image-map-comp {
    text-align: center;
    .imag-tag {
        width: 100%;
    }
    div.MuiTooltip-tooltip {
        background-color: #1B934D;
        opacity: 0.8;
        color: #ffff;
        margin-top: 0;
        margin-left: 0;
        border-radius: 3px;
        .tooltip-title {
            margin-bottom: 10px;
        }
        .tooltip-snippet {
            font-size: var(--global-font-size);
            line-height:var(--global-line-height);
            font-family:var(--global-font-family-medium);
        }
        span.MuiTooltip-arrow {
            color: #1B934D;
        }
    }
}

.tooltip {
    position: absolute;
    color: #fff;
    padding: 10px;
    background: rgba(0,0,0,0.8);
    transform: translate3d(-50%, -50%, 0);
    border-radius: 5px;
    pointer-events: none;
    z-index: 1000;
}



.plot-points {
    position: absolute;
    border-radius: 50%;
    font-weight: 600;
    width: 35px;
    height: 35px;
    border: 3px solid #F19D39;
    color: #000000;
    background: #ffff;
    padding: 4px 10px;
    cursor: pointer;
    svg {
        margin-left: -7px;
        margin-top: -7px;
        color: var(--primary-color-1);
    }
}