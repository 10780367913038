@import '../../../theme/mixins.scss';
@mixin min-width-height($width, $height){
    min-width:$width;
    min-height:$height; 
}
:root{
    --map-img-position:unset
}
.images-map-content {
    img {
        width: 100%;
        height: 450px;
    }
    .image-map__content__img{
        object-fit: var(--map-img-position);
    }
    .tooltip-snippet{
        font-size: var(--global-font-size);
        line-height:var(--global-line-height);
    }
    .crop-box {
        display: flex;
        justify-content: center;
        .ReactCrop--active {
            cursor: crosshair !important;
        }
        .ReactCrop {
            display: block;
            cursor: default ;
            .image-map__map{
                cursor: pointer;
            }

            .ReactCrop__image {
                opacity: 1 !important;
            }

            .ReactCrop__crop-selection {
                box-shadow: inset 0 0 0 9999em rgba(0, 0, 0, 0.5) !important;
                z-index: 99;
                overflow: hidden;
                cursor: move !important;
            }
        }
        // .map-box{ width: 100%;}
        .map-box-img {
            position: relative;
            .MuiTooltip-popper{
                .tooltip-snippet{
                    top:10px !important;
                    max-width: 300px;
                    max-height: 250px;
                    overflow: auto;
                    margin-right: -4px;
                    padding: 4px;
                }
                .MuiTooltip-tooltip {
                    @include min-width-height(180px,50px);
                    @include for-phone-only {
                    @include min-width-height(100px,30px);
                    }  
                }
            }
            .crop-item {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                background: none !important;
                cursor: pointer;
                // overflow: hidden;
                .icons-holder{
                    display: none;
                }
                .inner-box{
                    display: flex;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    justify-content:center;
                    align-items: center;
                }
                &:hover{
                    .icons-holder{
                        z-index: 9999;
                        display: block !important;
                        position: absolute;
                        bottom:-25px;
                        // right: 0;
                        @include switch-direction-rtl(right,0);
                        width: 33%;
                        height: 12%;
                        background: transparent;
                        opacity: 1;
                        .shape{display: flex;}
                        svg{
                            color: #000;
                            font-size:1.2rem;
                            margin-right: 3px;
                        }
                        .action-btns{
                            background-color: #fff !important;
                            border-radius:3px !important;
                            padding:3px;
                        }
                    }
                }
                .crop-item-title {
                        background-color: transparent;
                        border: none;
                        color: #fff;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -moz-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        font-weight: bold;
                        font-size: var(--global-font-size);
                        line-height:var(--global-line-height);
                    @include for-phone-only {
                        font-size: 10px;
                    }
                }
            }
        }
    }
    ::-webkit-scrollbar{
        height: 12px !important;
        width: 6px !important;
    }
}
