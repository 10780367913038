@import '../../../theme/mixins.scss';
.multi-select-container {
    padding:25px 35px;
    background: #ffff;
    border-radius: 5px;
    box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.05);
    margin: 5% 0;
}

.chbox-group {
    display: flex;
    // justify-content: space-between;
  }
  
  .chbox-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .chbox-group label {
    position: relative;
    cursor: pointer;
  }
  
  .chbox-group label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #0079bf;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  .title {
    color:var(--primary-color-1);
    margin-bottom: 15px;
    font-weight: 600;
  }

  
  .chbox-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    // left: 9px;
    @include switch-direction-rtl(left,9px);
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .cus-submit{
    padding:10px;
    margin-top: 20px;
    border:none;
    border-radius: 5px;
    color: #fff;
    
  }